import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import SanitizedHtml from '../../utils/SanitizedHtml';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

class DateElement extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      element,
      data,
      update,
      active = true,
    } = this.props;
    console.log("render DateElement");

    const dateValue = data[element.name] ? moment(data[element.name].value) : undefined;

    return (
      <div className="form-group">
        <label className="font-weight-bold" htmlFor={element.id}><SanitizedHtml html={element.label} /> { element.required ? <span className="text-danger">*</span> : null}</label>
        {
          active ? (
            <Datetime
              id={element.id}
              name={element.name}
              aria-describedby={element.help ? `${element.id}-help` : false}
              placeholder={element.placeholder || '...'}
              value={dateValue}
              timeFormat={false}
              onChange={(value) => {
                console.log(value.valueOf(), dateValue);
                update({ target: { name: element.name, value: value.format("YYYY-MM-DDTHH:mm:ssZ"), type: 'date' } });
              }}
            />
          ) : (
            <p className="form-control">{data[element.name] ? moment(data[element.name]).format('YYYY-MM-DD HH:MM:SS') : 'N/A'}</p>
          )
        }
        {
          element.help ? (
            <small id={`${element.id}-help`} className="form-text text-muted"><SanitizedHtml html={element.label} /></small>
          ) : null
        }

      </div>
    );
  }
}

DateElement.defaultProps = {
  active: true
};

DateElement.propTypes = {
  element: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  active: PropTypes.bool
};

export default DateElement;
