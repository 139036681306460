import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SanitizedHtml from '../../utils/SanitizedHtml';

class BooleanElement extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      element,
      data,
      update,
      active = true
    } = this.props;
    const children  = element.children || [];

    return (
      <div className="form-group">
        <label className="font-weight-bold" htmlFor={element.id}><SanitizedHtml html={element.label} /> { element.required ? <span className="text-danger">*</span> : null }</label>
        {
          active ? (
            <select
              className="form-control"
              htmlFor={element.id}
              name={element.name}
              onChange={update}
              multiple
              value={data[element.name] ? data[element.name].value : undefined}
            >
              {
                children.map((option, index) => (
                  <option key={`${element.id}-${index}`} value={option.value}><SanitizedHtml html={option.label} /></option>
                ))
              }
            </select>
          ) : (
            <p className="form-ontrol h-100">{data[element.name] ? data[element.name].value : 'N/A'}}</p>
          )
        }
        {
          element.help ? (
            <small id={`${element.id}-help`} className="form-text text-muted"><SanitizedHtml html={element.help} /></small>
          ) : null
        }
      </div>
    );
  }
}

BooleanElement.defaultProps = {
  active: true
};

BooleanElement.propTypes = {
  element: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  active: PropTypes.bool
};

export default BooleanElement;
