import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class HeaderSort extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc"
    };
    this.handleSort = this.handleSort.bind(this);
  }

  handleSort(event) {
    const { dataKey } = this.props;

    event.preventDefault();
    this.setState(
      { order: this.state.order === "desc" ? "asc" : "desc" },
      () => {
        this.props.onSort({ sort: dataKey, order: this.state.order });
      }
    );
  }

  render() {
    const { label } = this.props;
    const { order } = this.state;

    return (
      <th className="border-top-0">
        <button className="btn btn-link text-dark font-weight-bold p-0" onClick={this.handleSort}>
          {label} <FontAwesomeIcon icon={order === 'asc' ? 'sort-down' : 'sort-up'} />
        </button>
      </th>
    );
  }
}

HeaderSort.propTypes = {
  dataKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default HeaderSort;
