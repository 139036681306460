import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SanitizedHtml from '../../utils/SanitizedHtml';

class InputElement extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      element,
      data,
      update,
      active = true
    } = this.props;

    return (
      <div className="form-group">
        <label className="font-weight-bold" htmlFor={element.id}><SanitizedHtml html={element.label} /> { element.required ? <span className="text-danger">*</span> : null}</label>
        {
          active ? (
            <input
              type="text"
              id={element.id}
              name={element.name}
              className="form-control"
              aria-describedby={element.help ? `${element.id}-help` : false}
              placeholder={element.placeholder || '...'}
              onChange={update}
              value={data[element.name] && data[element.name].value !== null ? data[element.name].value : ''}
            />
          ) : (
            <p className="form-control">{data[element.name] && data[element.name].value !== null ? data[element.name].value : 'N/A'}</p>
          )
        }
        {
          element.help ? (
            <small id={`${element.id}-help`} className="form-text text-muted"><SanitizedHtml html={element.help} /></small>
          ) : null
        }
      </div>
    );
  }
}

InputElement.defaultProps = {
  active: true
};

InputElement.propTypes = {
  element: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  active: PropTypes.bool
};

export default InputElement;
