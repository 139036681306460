import React, { Fragment, useEffect, useState } from "react";

import SanitizedHtml       from '../../utils/SanitizedHtml';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment              from 'moment';

import { isEmpty } from 'lodash';

// Configuration Imports
import config from '../../../config';
import * as log from 'loglevel';

// useHooks later
//import AlertContainer from '../utils/AlertContainer';

function AddProposalWizardFirstPage(props) {
  if (props.currentStep !== 1) { // Prop: The current step
    return null
  }

  const [debug, setDebug] = useState(config.debug);

  useEffect(() => {
    log.debug("========== IN LOADER FOR FIRST PAGE");
    log.debug(props);
  },[]);

  const handleProposalCallSelect = React.useCallback((selectedCall) => {
    log.debug("Selected Call: %j", selectedCall);
    props.updateSelectedProposalCall(selectedCall);
    log.debug("Go to 'details' step");
    props.goToNamedStep("details");
  });

  // const updateSelectedProposalCall = (call) => {
  //   props.updateSelectedProposalCall(call);
  // };

  log.debug("BEFORE RETURN IN PAGE 1");
  log.debug(props);

  if (_.isEmpty(props.wizardState.proposalCalls)) {
    log.debug("Hello?");
    return (<div>LOADING PAGE 1</div>)
  }
  else {
    return (
        <div>
        <h1>Select a Proposal Call</h1>
        {
          (props.wizardState && props.wizardState.proposalCalls) ?
          props.wizardState.proposalCalls.map((call) => (
            <div className="card mb-3" key={call.id}>
              <div className="card-body">
                <h2>{call.title}</h2>
                <p className="text-muted m-0"><em>{moment(call.submissionStartDate).format('MMM D, YYYY')} - {moment(call.submissionEndDate).format('MMM D, YYYY')}</em></p>
                <p>{call.briefDescription}</p>
                <p><SanitizedHtml html={call.description} /></p>
                <button
                  className="btn btn-primary"
                  onClick={() => handleProposalCallSelect(call)}><FontAwesomeIcon icon="plus"/> Create Proposal</button>
              </div>
            </div>
          )) : (<div>No Proposal Calls Found</div>)
        }
        <div className="row">
          <div className="col-6">
            <button className="btn btn-secondary btn-block">
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default AddProposalWizardFirstPage;
