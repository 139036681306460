import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../FormGroup';
import SanitizedHtml from '../../utils/SanitizedHtml';
import RadiolistElement from './RadiolistElement';

class BooleanElement extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      element,
      data,
      update,
      active = true
    } = this.props;
    const schema = element;
    const children  = element.children || [];
    let checked     = data[element.name] ? Boolean(data[element.name].value) : false;

    let template = null;

    if(element.required) {
      // Confirm that this is finding null properly, might be undefined
      if(data[element.name]){
        checked = typeof data[element.name].value === 'null' ? null : Boolean(data[element.name].value);
      }else{
        checked = null;
      }

      template = (
        <div className="form-group">
          <label className="font-weight-bold" htmlFor={element.name}><SanitizedHtml html={element.label} /> { element.required ? <span className="text-danger">*</span> : null}</label>
          {
            element.help ? (
              <small id={`${element.id}-help`} className="form-text text-muted"><SanitizedHtml html={element.help} /></small>
            ) : null
          }
          <ul className="list-unstyled ml-3">
            <li className="form-check">
              <input
                id={`${element.id}-yes`}
                type="radio"
                name={element.name}
                className="form-check-input"
                onChange={active ? (event) => { update({ target: { type: 'radio', name: element.name, value: true, bool: true }}) } : false}
                checked={checked === true}
                value={true}
              />
              <label className="form-check-label" htmlFor={`${element.id}-yes`}>Yes</label>
            </li>
            <li className="form-check">
              <input
                id={`${element.id}-no`}
                type="radio"
                name={element.name}
                className="form-check-input"
                onChange={active ? (event) => { update({ target: { type: 'radio', name: element.name, value: false, bool: true }}) } : false}
                checked={checked === false}
                value={false}
              />
              <label className="form-check-label" htmlFor={`${element.id}-no`}>No</label>
            </li>
          </ul>
          {
            (element.hasOwnProperty('children') && children.length > 0) && checked ? (
              <div className="mt-4">
                <FormGroup {...{schema, data, update, active}} />
              </div>
            ) : null
          }
        </div>
      )
    } else {
      template = (
        <div className="form-group">
          <div key={element.id} className="form-check">
            <input
              type="checkbox"
              id={element.id}
              name={element.name}
              className="form-check-input"
              onChange={active ? update : () => {}}
              checked={checked}
            />
            <label className="form-check-label" htmlFor={element.id}><SanitizedHtml html={element.label} /> { element.required ? <span className="text-danger">*</span> : null}</label>
            {
              element.help ? (
                <small id={`${element.id}-help`} className="form-text text-muted"><SanitizedHtml html={element.help} /></small>
              ) : null
            }
            {
              (element.hasOwnProperty('children') && children.length > 0) && checked ? (
                <div className="mt-4">
                  <FormGroup {...{schema, data, update, active}} />
                </div>
              ) : null
            }
          </div>
        </div>
      );
    }

    return template;
  }
}

BooleanElement.defaultProps = {
  active: true,
};

BooleanElement.propTypes = {
  element: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

export default BooleanElement;
