import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WithWizard } from 'react-albus';
import Modal from 'react-modal';
import FormGroup from './FormGroup';
import FormElement from './FormElement';
import ListElement from './ListElement';
import produce from 'immer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProposalUserAPI from '../../api/ProposalUserAPI';
import UserAPI from '../../api/UserAPI';
import FormAPI from '../../api/FormAPI';
import RoleAPI from '../../api/RoleAPI';
import SanitizedHtml from '../utils/SanitizedHtml';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import AlertContainer from '../utils/AlertContainer';

import config from '../../config';

const NewUserInit = {
  firstName: '',
  lastName: '',
  email: '',
  role: ''/*,
  isForeign: undefined,*/
};

const NewUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required.'),
  lastName: Yup.string()
    .required('Last name is required.'),
  email: Yup.string()
    .required('You must enter the users email.')
    .email('Please enter a valid email.')
    .test(
      'email', // Name
      'This email is already in use.', // Failure message
      (value) => {
        if(config.validation.email.test(value)) {
          return UserAPI.getPublic({ email: value, max: 1 })
            .then(response => !Boolean(response.data));
        }
        return false;
      }
    ),
  role: Yup.string()
    .required('Please select a role.')/*,
  isForeign: Yup.boolean()
    .required('Please enter the users nationality.')*/
});

const ExistingUserSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string(),
  role: Yup.string()
    .required('Please select a role.')/*,
  isForeign: Yup.boolean()*/
});

class FormUsers extends Component {
  constructor(props) {
    super(props);
    this.alertContainer = undefined; // using REF defined in the render function
    this.state = {
      isLoading: false,
      roles: [],
      userOptions: [],
      list: [],
      data: NewUserInit,
      confirmOnDelete: undefined,
      isOpen: 'closed'
    };

    /*
      Types of things!
      input
      textarea
      checklist
      radiolist
      select
      multiselect
    */

    this.getList          = this.getList.bind(this);
    this.add              = this.add.bind(this);
    this.submit           = this.submit.bind(this);
    this.edit             = this.edit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentWillMount() {
    const proposal    = this.props.proposal;
    const proposalId  = proposal.id;

    RoleAPI.get(proposalId)
      .then((result) => {
        this.setState({ roles: result.data.results || [] });
      })
      .catch(e => {
        this.alert('danger', e.message);
      });
    this.getList();
  }

  getList() {
    const proposal    = this.props.proposal;
    const proposalId  = proposal.id;
    const formId      = this.props.schema.id;
    const schema      = this.props.schema;
    const elements    = this.props.schema.elements;

    if (typeof proposal.id !== 'undefined') {
      ProposalUserAPI.get(proposalId)
        .then((response) => {
          this.setState(
            produce(draft => {
              draft.list = response.data.results;
            })
          )
        })
        .catch(e => {
          this.alert('danger', e.message);
        });
    }
  }

  edit(item){
    let user = {
      user: {
        id: item.user.id,
      },
      firstName: item.user.firstName,
      lastName: item.user.lastName,
      email: item.user.email,
      role: item.role,
      isForeign: item.user.isForeign,
    };

    this.setState(
      produce(draft => {
        // Set the data for this form
        draft.groupId = item.groupId;
        draft.isOpen = 'edit';
        draft.data = user;
      })
    );
  }

  submit(user) {
    const proposal    = this.props.proposal;
    const proposalId  = proposal.id;

    ProposalUserAPI.post(proposalId, user)
      .then((response) => {
        this.setState({
          isOpen: 'closed',
          data: {...NewUserInit},
          confirmOnDelete: undefined,
        }, this.getList)
      })
      .catch(e => {
        this.alert('danger', e.message);
      });
  }

  add(user) {
    const proposal    = this.props.proposal;
    const proposalId  = proposal.id;
    const userId      = user.id;

    ProposalUserAPI.post(proposalId, { user: { id: userId}})
      .then((response) => {
        this.setState({
          confirmOnDelete: undefined,
          list: response.data.results
          // list: [...this.state.list, { user, role: '', roleName: '' }]
        })
      })
      .catch(e => {
        this.alert('danger', e.message);
      });
  }

  remove(user) {
    const proposal    = this.props.proposal;
    const proposalId  = proposal.id;
    const userId      = user.user.id;

    ProposalUserAPI.delete(proposalId, { user: { id: userId}})
      .then((response) => {
        this.setState({
          confirmOnDelete: undefined,
          list: this.state.list.filter((item) => item.user.id !== userId)
        })
      })
      .catch(e => {
        this.alert('danger', e.message);
      });
  }

  handleCloseModal() {
    this.setState({
      isOpen: 'closed',
      data: {}
    });
  }

  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  render() {
    const formId    = this.props.schema.id;
    const proposal  = this.props.proposal;
    const form      = this.props.schema;
    const schema    = form.schema;
    const elements  = form.elements;
    const { active = true } = this.props;

    if (active) {
      return (
        <div>
          <h2>{form.title}</h2>
          {
            form.description ? (
              <div className="alert alert-info"><SanitizedHtml html={form.description} /></div>
            ) : null
          }
          <div className="form-group">
            <label className="font-weight-bold" htmlFor="userSearch">Search for an existing user by email</label>
            <AsyncTypeahead
              name="userSearch"
              isLoading={this.state.isLoading}
              placeholder="Search for an existing user..."
              delay={250}
              onChange={selected => {
                if(selected.length === 1){
                  this.add(selected[0]);
                }
              }}
              onSearch={q => {
                this.setState({isLoading: true});
                //TODO: UserAPI.get({ query: { username: q, firstName: q, lastName: q }, max: 10 })
                UserAPI.get({ q, max: 10 })
                  .then((response) => {
                    const { data: { results = []} } = response;
                    // Existing id's
                    const ids           = this.state.list.map(({user}) => user.id);
                    // Filter out everyone already in the list
                    const userOptions   = results.filter((option) => !ids.includes(option.id));

                    this.setState({
                      isLoading: false,
                      userOptions
                    })
                  })
                  .catch(e => {
                    this.alert('danger', e.message);
                  });
              }}
              labelKey={option => `${option.firstName} ${option.lastName} - ${option.email}`}
              options={this.state.userOptions}
              emptyLabel={
                <span>
                  <button
                    className="btn btn-success btn-sm mr-2"
                    onClick={() => {
                      this.setState({ isOpen: 'add' })
                    }}
                  >
                    <FontAwesomeIcon icon="user-plus"/>Add a New User
                  </button>
                </span>
              }
              renderMenuItemChildren={(option, props, idx) => (
                <span>
                  <button className="btn btn-success btn-sm mr-2"><FontAwesomeIcon icon="user-plus"/></button>
                  <Highlighter search={props.text}>{`${option.firstName} ${option.lastName} - ${option.email}`}</Highlighter>
                </span>
              )}
            />
          </div>
  
          <div className="text-muted my-3">
            -or-
          </div>
  
          <button className="btn btn-success" onClick={() => { this.setState({ isOpen: 'add' }) }}><FontAwesomeIcon icon="user-plus"/> Add a New User</button>
  
          <div className="my-4">
            <AlertContainer ref={(container) => { this.alertContainer = container; }} />
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th colSpan="2"></th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    {/*<th>US Citizen</th>*/}
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.list.map((item, index) => (
                      <tr key={`${item.user.id}${item.roleName}`}>
                        <td>
                          <button className="btn btn-success btn-sm" onClick={() => {
                              this.edit(item);
                            }}>
                            <FontAwesomeIcon icon="pencil-alt"/>
                          </button>
                        </td>
                        <td>
                          <button className="btn btn-danger btn-sm" onClick={() => {
                              this.setState({ confirmOnDelete: item });
                            }}>
                            <FontAwesomeIcon icon="trash-alt"/>
                          </button>
                        </td>
                        <td>{item.user.firstName} {item.user.lastName}</td>
                        <td>{item.user.email}</td>
                        <td>{item.roleName}</td>
                        {/*<td><span className={item.user.isForeign ? 'badge badge-danger w-100' : 'badge badge-success w-100'}>{item.user.isForeign ? 'No' : 'Yes'}</span></td>*/}
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          <WithWizard
            render={({ next, previous, push, step, steps }) => (
              <div className="row">
                <div className="col">
                  {steps.indexOf(step) > 0 && (
                    <button className="btn btn-secondary btn-block" onClick={() => push(steps[steps.indexOf(step) - 1].id)}>
                      Back
                    </button>
                  )}
                </div>
                {
                  this.state.list.length > 0 && (
                    <div className="col">
                      <button className="btn btn-primary btn-block" onClick={() => push(steps[steps.indexOf(step) + 1].id)}>
                        Next
                      </button>
                    </div>
                  )
                }
              </div>
            )}
          />
          <Modal
            isOpen={this.state.isOpen !== 'closed'}
            shouldCloseOnOverlayClick={false}
            className={{
              base: 'modal-dialog modal-lg',
              afterOpen: 'after-open',
              beforeClose: 'before-close'
            }}
            overlayClassName={{
              base: 'modal fade',
              afterOpen: 'show',
              beforeClose: ''
            }}
          >
            <Formik
              initialValues={this.state.data}
              validationSchema={this.state.data.user && this.state.data.user.id ? ExistingUserSchema : NewUserSchema}
              enableReinitialize={true}
              onSubmit={this.submit}
              render={({ errors, touched, isSubmitting }) => (
                <div className="modal-content">
                  <Form>
                    <div className="modal-header sticky-top">
                      <button type="button" className="btn btn-warning mr-auto" data-dismiss="modal" onClick={this.handleCloseModal}><span className="fa fa-close" /> Close</button>
                      <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                    <div className="modal-body">
                      {
                        active ? (
                          <p className="text-danger font-weight-bold">(* indicates a required field)</p>
                        ) : null
                      }
                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="firstName">First Name <span className="text-danger">*</span></label>
                        <Field name="firstName" disabled={isSubmitting || this.state.data.user && this.state.data.user.id} required autoComplete="firstName" className={`form-control${errors.firstName && touched.email ? ' is-invalid' : ''}`} />
                        <ErrorMessage name="firstName">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>
  
                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="lastName">Last Name <span className="text-danger">*</span></label>
                        <Field name="lastName" disabled={isSubmitting || this.state.data.user && this.state.data.user.id} required autoComplete="lastName" className={`form-control${errors.lastName && touched.lastName ? ' is-invalid' : ''}`} />
                        <ErrorMessage name="lastName">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>
  
                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="email">Email <span className="text-danger">*</span></label>
                        <Field name="email" disabled={isSubmitting || this.state.data.user && this.state.data.user.id} required autoComplete="email" className={`form-control${errors.email && touched.email ? ' is-invalid' : ''}`} />
                        <ErrorMessage name="email">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>
  
                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="role">Role <span className="text-danger">*</span></label>
                        <Field
                          component="select"
                          name="role"
                          disabled={isSubmitting}
                          required
                          autoComplete="role"
                          className={`form-control${errors.role && touched.role ? ' is-invalid' : ''}`}
                        >
                          <option value=""></option>
                          {
                            this.state.roles.map((role) => (
                              <option value={role.authority}>{role.displayName}</option>
                            ))
                          }
                        </Field>
                        <ErrorMessage name="role">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>

                      {/*isForeign Code existed here. Commit 241f4f52ea26785e22b36c2fdc79030b177abbfd*/}
                      
                    </div>
                  </Form>
                </div>
              )}
            />
          </Modal>
          <Modal
            isOpen={this.state.confirmOnDelete !== undefined}
            shouldCloseOnOverlayClick={false}
            className={{
              base: 'modal-dialog',
              afterOpen: 'after-open',
              beforeClose: 'before-close'
            }}
            overlayClassName={{
              base: 'modal fade',
              afterOpen: 'show',
              beforeClose: ''
            }}
            >
            <div className="modal-content">
              <div className="modal-header text-left">
                <h5 className="modal-title"><FontAwesomeIcon icon="exclamation-triangle" /> Are you sure?</h5>
              </div>
              <div className="modal-body">
                Are you sure you want to remove {this.state.confirmOnDelete && this.state.confirmOnDelete.user.firstName} {this.state.confirmOnDelete && this.state.confirmOnDelete.user.lastName}?
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" onClick={(event) => { this.remove(this.state.confirmOnDelete)}}>Remove</button>
                <button className="btn btn-primary" onClick={(event) => { this.setState({ confirmOnDelete: undefined }) }}>Cancel</button>
              </div>
            </div>
          </Modal>
        </div>
      );
    }

    return (
      <div>
        <h2>{form.title}</h2>
        <p>{form.description}</p>
        <div className="form-group">
          <div className="my-4">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Confirmation</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.list.map((item, index) => (
                      <tr key={`${item.user.id}${item.roleName}`}>
                        <td>{item.user.firstName} {item.user.lastName}</td>
                        <td>{item.user.email}</td>
                        <td>{item.roleName}</td>
                        <td><span className={item.confirmed ? 'badge badge-success w-100' : 'badge badge-danger w-100'}>{item.confirmed ? 'Confirmed' : 'Unconfirmed'}</span></td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );   
  }
}

FormUsers.defaultProps = {
  active: true
}

FormUsers.propTypes = {
  proposal: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  active: PropTypes.bool
};

export default FormUsers;
