import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AlertContainer from '../utils/AlertContainer';
import UserAPI from '../../api/UserAPI';
import Modal from 'react-modal';
import config from '../../config';
import PositionAPI from '../../api/PositionAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

class Account extends Component {
  constructor(props) {
    super(props);
    this.match = props.match;
    this.alertContainer = undefined; // using REF defined in the render function
    this.history = props.history;
    this.debug = config.debug;
    this.state = {
      user: undefined,
      positions: [],
      resetPassword: false,
      updateAccount: false,
      updatePii: false
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleNotificationChange = this.handleNotificationChange.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement('body');
    Promise.all([
      PositionAPI.get(),
      UserAPI.account()
    ]).then(([positionResponse, userResponse]) => {
      if (this.debug) {
        console.log(userResponse.data);
      }
      this.setState({
        positions: positionResponse.data,
        user: userResponse.data
      });
    }).catch(e => {
      this.alert('danger', e.message);
    });
  }

  componentDidUpdate(prevProps) {
    // Update the view if changes are made from a modal form
    if (
      prevProps.location.pathname !== this.props.match.path &&
      this.props.location.pathname === '/lansce/app/account' /*this.props.match.path*/
      // this.props.location.pathname === '/lansce/account' /*this.props.match.path*/
    ) {
      UserAPI.account()
        .then((userResponse) => {
          this.setState({
            user: userResponse.data
          });
        }).catch(e => {
          this.alert('danger', e.message);
        });
    }
  }

  handleCloseModal() {
    this.setState({ isOpen: false });
    this.history.goBack();
  }

  handleNotificationChange(event) {
    let { name, checked } = event.target;
    const user = { ...this.state.user, [name]: checked };
    const { id, emailFromProposal, emailFromUserGroup, emailFromWeeklyUpdate } = user;

    this.setState(
      { user },
      () => {
        UserAPI.updateAccount({
          id,
          emailFromProposal,
          emailFromUserGroup,
          emailFromWeeklyUpdate
        })
        .catch(e => {
          this.alert('danger', e.message);
        });
      }
    );
  }

  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  render() {
    const { user, positions } = this.state;
    // const position = positions.find((position) => {
    //   return position.id === user.position;
    // })

    return (
      <div id="account" className="container">
        <div className="row mb-3 mt-5">
          <div className="col">
            <AlertContainer ref={(container) => { this.alertContainer = container; }} />
            {
              user ? (
                <div>
                  <h2>Account Details: {user.username}</h2>
                  <hr/>
                  <h3>Password Reset</h3>
                  <div className="card mb-4 mx-auto text-center">
                    <div className="card-body">
                      <div className="alert alert-info">
                        <center>
                          <p>
                            <Link to={'/lansce/app/account/reset'} className="btn btn-secondary">Reset Password</Link>
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                  <h3>Account Information</h3>
                  <div className="card mb-4">
                    <div className="card-body">
                      <center>
                        <h2><FontAwesomeIcon icon="user" /> User Information</h2>
                      </center>
                      <dl className="row">
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="firstName">First Name</label></dt>
                        <dd className="col-sm-9 pull-left">{user.firstName}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="middleName">Middle Name</label></dt>
                        <dd className="col-sm-9 pull-left">{user.middleName}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="lastName">Last Name</label></dt>
                        <dd className="col-sm-9 pull-left">{user.lastName}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="zNumber">Z Number</label></dt>
                        <dd className="col-sm-9 pull-left">{user.zNumber}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="gender">Gender</label></dt>
                        <dd className="col-sm-9 pull-left">{user.gender}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="phone">Phone</label></dt>
                        <dd className="col-sm-9 pull-left">{user.phone}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="altEmail">Alternate Email</label></dt>
                        <dd className="col-sm-9 pull-left">{user.altEmail}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="orcid">ORCiD</label></dt>
                        <dd className="col-sm-9 pull-left">{user.orcid}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="isForeign">US Citizen</label></dt>
                        <dd className="col-sm-9 pull-left">{user.isForeign ? (<div>No</div>) : (<div>Yes</div>)}</dd>
                      </dl>

                      <hr className="my-4"/>
                      <center>
                        <h2><FontAwesomeIcon icon="university" /> Work / University Information</h2>
                      </center>

                      <dl className="row">
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="workInstitution">Institution</label></dt>
                        <dd className="col-sm-9 pull-left">{user.workInstitution}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="workDepartment">Department</label></dt>
                        <dd className="col-sm-9 pull-left">{user.workDepartment}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="workAreaOfExpertise">Area of Expertise</label></dt>
                        <dd className="col-sm-9 pull-left">{user.workAreaOfExpertise}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="workPosition">Position</label></dt>
                        <dd className="col-sm-9 pull-left">{user.workPosition && user.workPosition.name}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="workEmail">Email</label></dt>
                        <dd className="col-sm-9 pull-left">{user.workEmail}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="workPhone">Phone</label></dt>
                        <dd className="col-sm-9 pull-left">{user.workPhone}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="workAddress.line1">Address</label></dt>
                        <dd className="col-sm-9 pull-left">{user.workAddress.line1}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="workAddress.line1">Address Cont.</label></dt>
                        <dd className="col-sm-9 pull-left">{user.workAddress.line2}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="workAddress.city">City</label></dt>
                        <dd className="col-sm-9 pull-left">{user.workAddress.city}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="workAddress.stateOrProvince">State / Region</label></dt>
                        <dd className="col-sm-9 pull-left">{user.workAddress.stateOrProvince}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="workAddress.postalCode">Zip Code</label></dt>
                        <dd className="col-sm-9 pull-left">{user.workAddress.postalCode}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="workAddress.country">Country</label></dt>
                        <dd className="col-sm-9 pull-left">{user.workAddress.country}</dd>
                      </dl>

                      {
                        user.isForeign ? (
                          <div>
                            <hr className="my-4"/>
                            <center>
                              <h2><FontAwesomeIcon icon="university" /> Previous Home Country Affiliation</h2>
                            </center>

                            <dl className="row">
                              <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="previousWorkInstitution">Institution</label></dt>
                              <dd className="col-sm-9 pull-left">{user.previousWorkInstitution}</dd>
                              <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="previousWorkDepartment">Department</label></dt>
                              <dd className="col-sm-9 pull-left">{user.previousWorkDepartment}</dd>
                              <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="previousWorkAreaOfExpertise">Area of Expertise</label></dt>
                              <dd className="col-sm-9 pull-left">{user.previousWorkAreaOfExpertise}</dd>
                              <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="previousWorkPosition">Position</label></dt>
                              <dd className="col-sm-9 pull-left">{user.previousWorkPosition && user.previousWorkPosition.name}</dd>
                              <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="previousWorkPhone">Phone</label></dt>
                              <dd className="col-sm-9 pull-left">{user.previousWorkPhone}</dd>
                              <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="previousWorkAddress.line1">Address</label></dt>
                              <dd className="col-sm-9 pull-left">{user.previousWorkAddress.line1}</dd>
                              <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="previousWorkAddress.line1">Address Cont.</label></dt>
                              <dd className="col-sm-9 pull-left">{user.previousWorkAddress.line2}</dd>
                              <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="previousWorkAddress.city">City</label></dt>
                              <dd className="col-sm-9 pull-left">{user.previousWorkAddress.city}</dd>
                              <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="previousWorkAddress.stateOrProvince">State / Region</label></dt>
                              <dd className="col-sm-9 pull-left">{user.previousWorkAddress.stateOrProvince}</dd>
                              <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="previousWorkAddress.postalCode">Zip Code</label></dt>
                              <dd className="col-sm-9 pull-left">{user.previousWorkAddress.postalCode}</dd>
                              <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="previousWorkAddress.country">Country</label></dt>
                              <dd className="col-sm-9 pull-left">{user.previousWorkAddress.country}</dd>
                            </dl>
                          </div>
                        ) : null
                      }

                      <hr className="my-4"/>
                      <center>
                        <h2><FontAwesomeIcon icon="home" /> Home Information</h2>
                      </center>

                      <dl className="row">
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="homeAddress.line1">Address</label></dt>
                        <dd className="col-sm-9 pull-left">{user.homeAddress.line1}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="homeAddress.line1">Address Cont.</label></dt>
                        <dd className="col-sm-9 pull-left">{user.homeAddress.line2}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="homeAddress.city">City</label></dt>
                        <dd className="col-sm-9 pull-left">{user.homeAddress.city}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="homeAddress.stateOrProvince">State / Region</label></dt>
                        <dd className="col-sm-9 pull-left">{user.homeAddress.stateOrProvince}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="homeAddress.postalCode">Zip Code</label></dt>
                        <dd className="col-sm-9 pull-left">{user.homeAddress.postalCode}</dd>
                        <dt className="col-sm-3"><label className="font-weight-bold" htmlFor="homeAddress.country">Country</label></dt>
                        <dd className="col-sm-9 pull-left">{user.homeAddress.country}</dd>
                      </dl>

                      <Link
                        to={{
                          pathname: '/lansce/app/account/details',
                          state: { user, positions }
                        }}
                        className="btn btn-primary"
                      >
                        Update Account Details
                      </Link>
                    </div>
                  </div>

                  {/*
                  <h3>Notifications</h3>
                  <div className="card mb-4">
                    <div className="card-body">
                      <ul className="list-unstyled mb-0">
                        <li className="form-check">
                          <input
                            id="emailFromProposal"
                            name="emailFromProposal"
                            type="checkbox"
                            className="form-check-input"
                            checked={user.emailFromProposal}
                            onChange={this.handleNotificationChange}
                          />
                          <label htmlFor="emailFromProposal" className="form-check-label">Recieve proposal update notifications</label>
                        </li>
                        <li className="form-check">
                          <input
                            id="emailFromUserGroup"
                            name="emailFromUserGroup"
                            type="checkbox"
                            className="form-check-input"
                            checked={user.emailFromUserGroup}
                            onChange={this.handleNotificationChange}
                          />
                          <label htmlFor="emailFromUserGroup" className="form-check-label">Recieve group action notifications</label>
                        </li>
                        <li className="form-check">
                          <input
                            id="emailFromWeeklyUpdate"
                            name="emailFromWeeklyUpdate"
                            type="checkbox"
                            className="form-check-input"
                            checked={user.emailFromWeeklyUpdate}
                            onChange={this.handleNotificationChange}
                          />
                          <label htmlFor="emailFromWeeklyUpdate" className="form-check-label">Recieve weekly updates</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  */}
                  {
                    user.isForeign ? (
                      <div>
                        <h3>PII</h3>
                        <div className="card mb-4">
                          <div className="card-body">
                            <div className="alert alert-danger">
                              <center>
                                <p>
                                  <strong>
                                    Through this form, TRIAD National Security, LLC (TRIAD) is requesting that you provide your Personally Identifiable Information (PII).
                                    TRIAD requests this information in accordance with various federal and state laws to meet routine Laboratory business requirements,
                                    including determining eligibility for access to the Laboratory site and for a Laboratory badge.
                                  </strong>
                                </p>
                                <p>
                                  <strong>
                                    Your Social Security Number is being collected only as required by federal and state law for these purposes.
                                  </strong>
                                </p>
                                <p>
                                  <strong>
                                    This information will be used by and disclosed to TRIAD personnel and subcontractors or other agents who need the information in the performance
                                    of their duties under TRIADS’s Prime Contract with DOE/NNSA.
                                  </strong>
                                </p>
                                <p>
                                  <strong>
                                    Furnishing this information is voluntary; however, failure to furnish this information may impede your ability to access the Los Alamos National Laboratory site or services.
                                  </strong>
                                </p>
                                <p>
                                  <strong>
                                    If you have any questions, contact the LANL Privacy Officer at <a href="mailto:privacy@lanl.gov?subject=LEMS PII Inquiry">privacy@lanl.gov</a>. As the information you provide will be incorporated into Laboratory files for ongoing use,
                                    you must ensure that it is accurate and will provide updates if there are any changes. 
                                  </strong>
                                </p>
                              </center>
                            </div>
                            <p>Foreign Nationals need to submit their date of birth, passport and visa / Permanent Resident Card information for the Foreign Visit Request.</p>
                            <p>If you are a Non-US citizen, please submit the following documents via file transfer (instructions below):</p>
                            <p>
                              <ul>
                                <li>CV / Resume</li>
                                <li>Legible copy of Passport</li>
                                <li>Legible copy of Visa/Permanent Resident Card</li>
                              </ul>
                            </p>
                            <small>Note: Your CV must include all your work experience and education with Month/Date/Year.</small>

                            <div className="alert alert-info">
                              File Transfer Instructions:
                              <p>
                                <ol>
                                  <li>Go to <a href="https://transfer.lanl.gov" target="_blank">https://transfer.lanl.gov</a>.</li>
                                  <li>In the <strong>Email</strong> input box within the <strong>Upload Files to LANL Employees</strong> section enter the following:</li>
                                    <ul>
                                      <li>"vsalazar@lanl.gov”.</li>
                                    </ul>
                                  <li>Ensure that the name put in the <strong>Sender</strong> input box matches the name you supplied on this form.</li>
                                  <li>Give your files meaningful names, such as "Bob_Johnson_Passport.pdf".</li>
                                  <li>Continue following the instructions at transfer.lanl.gov to Upload your files.</li>
                                </ol>
                              </p>
                            </div>
                            <div>
                              <p>In addition to uploading the required files, please also update your PII using the following <strong>Update PII</strong> dialog.</p>
                              <Link
                                to={{
                                  pathname: '/lansce/app/account/pii',
                                  state: { user }
                                }}
                                className="btn btn-primary"
                              >
                                Update PII
                              </Link>
                            </div>
                          </div>
                          <div className="card-footer">
                            <small className="text-muted">You last updated your PII <strong>{moment(user.piiUpdated).format("LLLL")}</strong></small>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h3>PII</h3>
                        <div className="card mb-4">
                          <div className="card-body">
                            <div className="alert alert-danger">
                              <center>
                                <p>
                                  <strong>
                                    Through this form, TRIAD National Security, LLC (TRIAD) is requesting that you provide your Personally Identifiable Information (PII).
                                    TRIAD requests this information in accordance with various federal and state laws to meet routine Laboratory business requirements,
                                    including determining eligibility for access to the Laboratory site and for a Laboratory badge.
                                  </strong>
                                </p>
                                <p>
                                  <strong>
                                    Your Social Security Number is being collected only as required by federal and state law for these purposes.
                                  </strong>
                                </p>
                                <p>
                                  <strong>
                                    This information will be used by and disclosed to TRIAD personnel and subcontractors or other agents who need the information in the performance
                                    of their duties under TRIADS’s Prime Contract with DOE/NNSA.
                                  </strong>
                                </p>
                                <p>
                                  <strong>
                                    Furnishing this information is voluntary; however, failure to furnish this information may impede your ability to access the Los Alamos National Laboratory site or services.
                                  </strong>
                                </p>
                                <p>
                                  <strong>
                                    If you have any questions, contact the LANL Privacy Officer at <a href="mailto:privacy@lanl.gov?subject=LEMS PII Inquiry">privacy@lanl.gov</a>. As the information you provide will be incorporated into Laboratory files for ongoing use,
                                    you must ensure that it is accurate and will provide updates if there are any changes. 
                                  </strong>
                                </p>
                              </center>
                            </div>
                            <center>
                              LANL requires all US Citizen visitors who will come onsite for an experiment to submit their <strong>Date of Birth</strong> and <strong>Social Security Number</strong> for access approval.
                              Please update your PII using the following <strong>Update PII</strong> dialog.
                            </center>
                            <Link
                              to={{
                                pathname: '/lansce/app/account/pii',
                                state: { user }
                              }}
                              className="btn btn-primary"
                            >
                              Update PII
                            </Link>
                          </div>
                          <div className="card-footer">
                            <small className="text-muted">You last updated your PII <strong>{moment(user.piiUpdated).format("LLLL")}</strong></small>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
