import React, { Component } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import moment from "moment";

class EditCollaboratorEventModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      open = true,
      event,
      onClose,
      onDelete,
      onSubmit,
    } = this.props;

    const mode = event && typeof event.id === 'undefined' ? 'add' : 'edit';

    return (
      <Modal
        isOpen={open}
        shouldCloseOnOverlayClick={false}
        className={{
          base: 'modal-dialog',
          afterOpen: 'after-open',
          beforeClose: 'before-close'
        }}
        overlayClassName={{
          base: 'modal fade',
          afterOpen: 'show',
          beforeClose: ''
        }}
        >
          <Formik
            initialValues={event}
            onSubmit={onSubmit}
            render={({ values, setValues }) => (
              <Form>
                <div className="modal-content">
                  <div className="modal-header text-left">
                    <h5 className="modal-title">{ mode === 'add' ? 'Add Visit' : 'Edit Visit' }</h5>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="start">Start Date</label>
                      <div>
                        <DatePicker
                          name="start"
                          className="form-control"
                          selected={moment(values.start).toDate()}
                          onChange={(event) => {
                            const start = moment(event);
                            let end = this.state.selectedEvent.end ? moment(this.state.selectedEvent.end) : start;
                            end = end.isSameOrAfter(start) ? end : start;

                            setValues({
                              ...values,
                              start: moment(start).startOf('day').toDate(),
                              end: moment(end).endOf('day').toDate(),
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="end">End Date</label>
                      <div>
                        <DatePicker
                          name="end"
                          className="form-control"
                          selected={moment(values.end).toDate()}
                          onChange={(event) => {
                            const end = moment(event);
                            let start = values.start ? moment(values.start) : end;
                            start = start.isSameOrBefore(end) ? start : end;

                            setValues({
                              ...values,
                              start: moment(start).startOf('day').toDate(),
                              end: moment(end).endOf('day').toDate(),
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-default" onClick={onClose}>Cancel</button>
                    {
                      mode === 'edit' && (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            onDelete(event);
                          }}
                        >Delete</button>
                      )
                    }
                    
                    <button
                      type="submit"
                      className="btn btn-success"
                    >{ mode === 'add' ? 'Add' : 'Edit' }</button>
                  </div>
                </div>
              </Form>
            )}
          />
      </Modal>
    );
  }
}

export default EditCollaboratorEventModal;
