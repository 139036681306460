import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import UserAPI from '../api/UserAPI';
import config from '../config';

const PasswordSchema = Yup.object().shape({
	password: Yup.string()
		.matches(
			config.validation.password,
			'Passwords must be at least 8 characters and contain 4 character classes (uppercase, lowercase, number, and special characters).'
		)
		.required('You must enter a password.'),
	password2: Yup.string()
		.oneOf([Yup.ref('password'), null], "Passwords don't match")
		.required('You must confirm your password.'),
});

class Signup extends Component {
  constructor(props) {
    super(props);

		this.state = {
			err: '',
			password: '',
			password2: '',
		};

    this.alertContainer = undefined; // using REF defined in the render function
    this.history = props.history;

    this.handleChange  = this.handleChange.bind(this);
  }

  componentWillMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    
    const token = params.get('t');

    if(!token){
      this.history.push(`${this.props.match.url}/no-token`);
    }
  }

  handleChange(values, actions) {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    
    const token = params.get('t');

		UserAPI.changePassword(values, token)
			.then(() => {
				this.history.push(`${this.props.match.url}/success`);
			})
			.catch((e) => {
				this.history.push(`${this.props.match.path}/failure`);
				this.setState({ err: e.message || '' })
			});
	}

  render() {
    // If the token does not exist warn the user and redirect to forgot password
    // If the token, fails and is expired, warn the user and redirect to the forgot password page
    // On successful change attempt, log user in and redirect to the system

    return (
      <div className="container mt-5">
				<div className="row justify-content-center align-items-center vh-100">
					<div className="col-lg-6">
						<Switch>
              <Route path={`${this.props.match.path}/no-token`}>
								<div className="card">
									<div className="card-body">
										<p>There was an issue with this password change request, you are missing a valid token. If you have a valid account in the system you may be able to use the <Link to="/forgot-password">Forgot Password</Link> feature instead.</p>
										<div className="row">
											<div className="col text-center">
												<Link to="/forgot-password" className="btn btn-block btn-default"><FontAwesomeIcon icon="arrow-left" /> Forgot Password</Link>
											</div>
										</div>
									</div>
								</div>
							</Route>
							<Route path={`${this.props.match.path}/success`}>
								<div className="card">
									<div className="card-body">
										<p>Your password was changed, <Link to="/login">click here</Link> to login to the system</p>
										<div className="row">
											<div className="col text-center">
												<Link to="/login" className="btn btn-block btn-default"><FontAwesomeIcon icon="sign-in-alt" /> Login</Link>
											</div>
										</div>
									</div>
								</div>
							</Route>
							<Route path={`${this.props.match.path}/failure`}>
								<div className="card">
									<div className="card-body text-center">
										<p>{this.state.err}</p>
										<p>If you have a valid account in the system you may be able to use the <Link to="/forgot-password">Forgot Password</Link> feature instead.</p>
										<div className="row">
											<div className="col text-center">
												<Link to="/login" className="btn btn-block btn-default"><FontAwesomeIcon icon="arrow-left" /> Back</Link>
											</div>
										</div>
									</div>
								</div>
							</Route>
							<Route path={`${this.props.match.path}`}>
								<div className="card">
		              <div className="card-body">
										<Formik
											initialValues={this.state}
											validationSchema={PasswordSchema}
											onSubmit={this.handleChange}
											render={({ values, actions, errors, touched, isSubmitting }) => (
												<Form>
													<p>Please enter a new password.</p>
													<div className="form-group">
														<label className="font-weight-bold" htmlFor="password">Password <strong className="text-danger">*</strong></label>
														<Field name="password" type="password" required autoComplete="new-password" className={`form-control${errors.password && touched.password ? ' is-invalid' : ''}`} />
														<ErrorMessage name="password">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
													</div>

													<div className="form-group">
														<label className="font-weight-bold" htmlFor="password2">Confirm Password <strong className="text-danger">*</strong></label>
														<Field name="password2" type="password" required autoComplete="new-password" className={`form-control${errors.password2 && touched.password2 ? ' is-invalid' : ''}`} />
														<ErrorMessage name="password2">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
													</div>

													<div className="row mb-3">
														<div className="col">
															<button type="submit" className="btn btn-block btn-primary" disabled={isSubmitting}><FontAwesomeIcon icon="check" /> Submit</button>
														</div>
													</div>
												</Form>
											)}
										/>

										<div className="row">
											<div className="col text-center">
												<Link to="/login" className="btn btn-block btn-default"><FontAwesomeIcon icon="arrow-left" /> Back</Link>
											</div>
										</div>
									</div>
								</div>
							</Route>
						</Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
