import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from './api/history';

import './index.scss';

import * as log from 'loglevel';
import chalk from 'chalk';
import remote from 'loglevel-plugin-remote';
import prefix from 'loglevel-plugin-prefix';
import config from './config';

import registerServiceWorker from './registerServiceWorker';
import { isEmpty } from 'lodash';
import StackTracey from 'stacktracey';

import App from './App';
import AuthRoute from './components/utils/AuthRoute.js'
import Signup from './routes/Signup';
import ForgotPassword from './routes/ForgotPassword';
import ChangePassword from './routes/ChangePassword';
import Login from './routes/Login';
import Err from './routes/Err';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAsterisk,
  faCheck,
  faTimes,
  faTrashAlt,
  faEllipsisH,
  faSignInAlt,
  faSignOutAlt,
  faUserPlus,
  faCheckCircle,
  faQuestionCircle,
  faBan,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faSearch,
  faPlus,
  faPencilAlt,
  faCog,
  faUpload,
  faUnlock,
  faUser,
  faUniversity,
  faClipboard,
  faHome,
  faArrowLeft,
  faSortUp,
  faSortDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(
  faAsterisk,
  faCheck,
  faTimes,
  faTrashAlt,
  faEllipsisH,
  faSignInAlt,
  faSignOutAlt,
  faUserPlus,
  faCheckCircle,
  faQuestionCircle,
  faBan,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faSearch,
  faPlus,
  faPencilAlt,
  faCog,
  faUpload,
  faUnlock,
  faUser,
  faUniversity,
  faClipboard,
  faHome,
  faArrowLeft,
  faSortUp,
  faSortDown
);

// Configuration Setup
log.setLevel('warn');
if (config.debug)         log.setLevel('debug');
if (config.verbose)       log.setLevel('trace');
if (config.remoteLogging) remote.apply(log, config.loglevelOptions);

prefix.reg(log);
prefix.apply(log.getLogger('critical'), {
  format(level, name, timestamp) { return chalk.red.bold(`[${timestamp}] ${level} ${name}:`); }
});
prefix.apply(log, {
  format(level, name, timestamp) {
    const colors     = config.logColors;
    let callingFile  = null;
    let lineNumber   = null;
    let stack        = {};
    let stackSources = {};

    try {
      if (config.verbose || config.debug) {
        if (config.verbose) {
          console.groupCollapsed("Attempting to get stacktrace w/ sources...");
          console.info("let stack = new StackTracey();");
        }
        stack = new StackTracey();

        if (config.verbose) { console.info("let stackSources = stack ? stack.withSources() : {};"); }
        stackSources = stack ? stack.withSources() : {};
        
        if (config.verbose) {
          if (isEmpty(stackSources)) { console.log("_.isEmpty(stackSources) === true"); }
          else                       { console.dir(stackSources); }
          console.log("let callingFile = stackSources.items[2].fileName;");
        }
        callingFile = stackSources.items[2].fileName;
        lineNumber  = stackSources.items[2].line;

        if (config.verbose) { console.log(`callingFile: ${callingFile}`); }
        if (config.verbose) { console.log(`lineNumber : ${lineNumber}`); }
        return `${chalk.gray(`[${timestamp}]`)} ${colors[level.toUpperCase()](level)} ${chalk.green(`${callingFile}`)} [${chalk.yellow(`${lineNumber}`)}] `;
      }
    }
    catch (error) { console.error(error); }
    finally       { if (config.verbose) { console.groupEnd(); } }

    return `${chalk.gray(`[${timestamp}]`)} ${colors[level.toUpperCase()](`${level}`)}: `;
  }
});

if (config.verbose) {
  log.debug('trace');
  log.debug('debug');
  log.getLogger('critical').error('Something significant happened');
  log.log('log');
  log.info('info');
  log.warn('warn');
  log.error('error');
}
// End Configuration



ReactDOM.render(
  <div>
    {/* <header>
      <div className="top-header" />
      <div className="main-header main-header-app">
        <div className="container">
          <div className="row">
            <div className="col-auto align-self-center"><img alt="LANL INSIDE" className="brand-logo" src={logo} /> LANSCE Proposal System</div>
          </div>
        </div>
      </div>
    </header> */}

    <Router history={history}>
      <Switch>
        <Route path='/public/api/getRadiationEffectsTemplate' component={() => {
            window.location.replace('https://lems.lanl.gov/public/api/getRadiationEffectsTemplate')
            return null
          }}/>
        {/* <AuthRoute path="/lansce" component={App} /> */}
        <Route path="/lansce/app" component={App} />
        <Route path="/lansce/error" component={Err} />
        <Route path="/lansce/signup" component={Signup} />
        <Route path="/lansce/forgot-password" component={ForgotPassword} />
        <Route path="/lansce/change-password" component={ChangePassword} />
        <Route path="/lansce/login" component={Login} />
        <Redirect to="/lansce/app/manage" />
      </Switch>
    </Router>

    <footer className="main-footer main-footer-app mb-4">
      <div className="footer-divider" />
      <div className="footer-body">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <small>
                <a href="mailto:lems-help@lanl.gov?subject=LEMS Help">
                  <FontAwesomeIcon icon="question-circle" /> Having issues with the system or need help?
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>,
  document.getElementById('root'));
registerServiceWorker();
