import React, { Component } from 'react';
import * as Yup from 'yup';
import AlertContainer from '../../utils/AlertContainer';
import Modal from 'react-modal';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserAPI from '../../../api/UserAPI';
import config from '../../../config';
import moment from 'moment';

/*
  user_id
  facility_id
  country_of_citizenship
  first_name
  last_name
  middle_name
  dob
  ssn
  city
  country_of_birth
  pra
  ppn
  pp_expiration
  yc_datetime
  yc_client_ip
*/
const SignupSchemaPii = Yup.object().shape({
  isForeign: Yup.boolean(),
  country_of_citizenship:
    Yup.string()
    .when("isForeign", {
      is: 'true',
      then: Yup.string().required(
        'Please enter your country of birth.'
      )
    }),
  country_of_birth:
    Yup.string()
    .when("isForeign", {
      is: 'true',
      then: Yup.string().required(
        'Please enter your country of birth.'
      )
    }),
  region_of_birth:
    Yup.string()
    .when("isForeign", {
      is: 'true',
      then: Yup.string().required(
        'Please enter your region / province of birth.'
      )
    }),
  dob:
    Yup.string()
    .matches(
      config.validation.dob,
      "Must be in 'MM/DD/YYYY' format."
    )
    .when("isForeign", {
      is: 'true',
      then: Yup.string().required("Please enter your birthday.")
    }),
  ssn:
    Yup.string()
    .matches(
      config.validation.ssn,
      'Must be in XXX-XX-XXXX format.'
    ),
  pra:
    Yup.string()
    .matches(
      config.validation.pra,
      'Must be in XXX-XXX-XXX format.'
    ),
  ppn:
    Yup.string()
    .matches(
      config.validation.ppn,
      'Must be in A1234567 format.'
    ),
  pp_expiration:
    Yup.string()
    .matches(
      config.validation.pp_expiration,
      "Must be in 'MM/DD/YYYY' format."
    ),
  visa_type:            Yup.string().nullable(),
  visa_issued_date:     Yup.string().nullable(),
  visa_expiration_date: Yup.string().nullable(),
});

const countries = [
  { id: 1, label: 'United States' },
  { id: 3, label: 'Afghanistan' },
  { id: 4, label: 'Albania' },
  { id: 5, label: 'Algeria' },
  { id: 6, label: 'American Samoa' },
  { id: 7, label: 'Andorra' },
  { id: 8, label: 'Angola' },
  { id: 9, label: 'Anguilla' },
  { id: 10, label: 'Antarctica' },
  { id: 11, label: 'Antigua and/or Barbuda' },
  { id: 12, label: 'Argentina' },
  { id: 13, label: 'Armenia' },
  { id: 14, label: 'Aruba' },
  { id: 15, label: 'Australia' },
  { id: 16, label: 'Austria' },
  { id: 17, label: 'Azerbaijan' },
  { id: 18, label: 'Bahamas' },
  { id: 19, label: 'Bahrain' },
  { id: 20, label: 'Bangladesh' },
  { id: 21, label: 'Barbados' },
  { id: 22, label: 'Belarus' },
  { id: 23, label: 'Belgium' },
  { id: 24, label: 'Belize' },
  { id: 25, label: 'Benin' },
  { id: 26, label: 'Bermuda' },
  { id: 27, label: 'Bhutan' },
  { id: 28, label: 'Bolivia' },
  { id: 29, label: 'Bosnia and Herzegovina' },
  { id: 30, label: 'Botswana' },
  { id: 31, label: 'Bouvet Island' },
  { id: 32, label: 'Brazil' },
  { id: 33, label: 'British lndian Ocean Territory' },
  { id: 34, label: 'Brunei Darussalam' },
  { id: 35, label: 'Bulgaria' },
  { id: 36, label: 'Burkina Faso' },
  { id: 37, label: 'Burundi' },
  { id: 38, label: 'Cambodia' },
  { id: 39, label: 'Cameroon' },
  { id: 2,  label: 'Canada' },
  { id: 40, label: 'Cape Verde' },
  { id: 41, label: 'Cayman Islands' },
  { id: 42, label: 'Central African Republic' },
  { id: 43, label: 'Chad' },
  { id: 44, label: 'Chile' },
  { id: 45, label: 'China' },
  { id: 46, label: 'Christmas Island' },
  { id: 47, label: 'Cocos (Keeling) Islands' },
  { id: 48, label: 'Colombia' },
  { id: 49, label: 'Comoros' },
  { id: 50, label: 'Congo' },
  { id: 51, label: 'Cook Islands' },
  { id: 52, label: 'Costa Rica' },
  { id: 53, label: 'Croatia (Hrvatska)' },
  { id: 54, label: 'Cuba' },
  { id: 55, label: 'Cyprus' },
  { id: 56, label: 'Czech Republic' },
  { id: 57, label: 'Denmark' },
  { id: 58, label: 'Djibouti' },
  { id: 59, label: 'Dominica' },
  { id: 60, label: 'Dominican Republic' },
  { id: 61, label: 'East Timor' },
  { id: 62, label: 'Ecudaor' },
  { id: 63, label: 'Egypt' },
  { id: 64, label: 'El Salvador' },
  { id: 65, label: 'Equatorial Guinea' },
  { id: 66, label: 'Eritrea' },
  { id: 67, label: 'Estonia' },
  { id: 68, label: 'Ethiopia' },
  { id: 69, label: 'Falkland Islands (Malvinas)' },
  { id: 70, label: 'Faroe Islands' },
  { id: 71, label: 'Fiji' },
  { id: 72, label: 'Finland' },
  { id: 73, label: 'France' },
  { id: 74, label: 'France, Metropolitan' },
  { id: 75, label: 'French Guiana' },
  { id: 76, label: 'French Polynesia' },
  { id: 77, label: 'French Southern Territories' },
  { id: 78, label: 'Gabon' },
  { id: 79, label: 'Gambia' },
  { id: 80, label: 'Georgia' },
  { id: 81, label: 'Germany' },
  { id: 82, label: 'Ghana' },
  { id: 83, label: 'Gibraltar' },
  { id: 84, label: 'Greece' },
  { id: 85, label: 'Greenland' },
  { id: 86, label: 'Grenada' },
  { id: 87, label: 'Guadeloupe' },
  { id: 88, label: 'Guam' },
  { id: 89, label: 'Guatemala' },
  { id: 90, label: 'Guinea' },
  { id: 91, label: 'Guinea-Bissau' },
  { id: 92, label: 'Guyana' },
  { id: 93, label: 'Haiti' },
  { id: 94, label: 'Heard and Mc Donald Islands' },
  { id: 95, label: 'Honduras' },
  { id: 96, label: 'Hong Kong' },
  { id: 97, label: 'Hungary' },
  { id: 98, label: 'Iceland' },
  { id: 99, label: 'India' },
  { id: 100, label: 'Indonesia' },
  { id: 101, label: 'Iran (Islamic Republic of)' },
  { id: 102, label: 'Iraq' },
  { id: 103, label: 'Ireland' },
  { id: 104, label: 'Israel' },
  { id: 105, label: 'Italy' },
  { id: 106, label: 'Ivory Coast' },
  { id: 107, label: 'Jamaica' },
  { id: 108, label: 'Japan' },
  { id: 109, label: 'Jordan' },
  { id: 110, label: 'Kazakhstan' },
  { id: 111, label: 'Kenya' },
  { id: 112, label: 'Kiribati' },
  { id: 113, label: 'Korea, Democratic People\'s Republic of' },
  { id: 114, label: 'Korea, Republic of' },
  { id: 115, label: 'Kuwait' },
  { id: 116, label: 'Kyrgyzstan' },
  { id: 117, label: 'Lao People\'s Democratic Republic' },
  { id: 118, label: 'Latvia' },
  { id: 119, label: 'Lebanon' },
  { id: 120, label: 'Lesotho' },
  { id: 121, label: 'Liberia' },
  { id: 122, label: 'Libyan Arab Jamahiriya' },
  { id: 123, label: 'Liechtenstein' },
  { id: 124, label: 'Lithuania' },
  { id: 125, label: 'Luxembourg' },
  { id: 126, label: 'Macau' },
  { id: 127, label: 'Macedonia' },
  { id: 128, label: 'Madagascar' },
  { id: 129, label: 'Malawi' },
  { id: 130, label: 'Malaysia' },
  { id: 131, label: 'Maldives' },
  { id: 132, label: 'Mali' },
  { id: 133, label: 'Malta' },
  { id: 134, label: 'Marshall Islands' },
  { id: 135, label: 'Martinique' },
  { id: 136, label: 'Mauritania' },
  { id: 137, label: 'Mauritius' },
  { id: 138, label: 'Mayotte' },
  { id: 139, label: 'Mexico' },
  { id: 140, label: 'Micronesia, Federated States of' },
  { id: 141, label: 'Moldova, Republic of' },
  { id: 142, label: 'Monaco' },
  { id: 143, label: 'Mongolia' },
  { id: 144, label: 'Montserrat' },
  { id: 145, label: 'Morocco' },
  { id: 146, label: 'Mozambique' },
  { id: 147, label: 'Myanmar' },
  { id: 148, label: 'Namibia' },
  { id: 149, label: 'Nauru' },
  { id: 150, label: 'Nepal' },
  { id: 151, label: 'Netherlands' },
  { id: 152, label: 'Netherlands Antilles' },
  { id: 153, label: 'New Caledonia' },
  { id: 154, label: 'New Zealand' },
  { id: 155, label: 'Nicaragua' },
  { id: 156, label: 'Niger' },
  { id: 157, label: 'Nigeria' },
  { id: 158, label: 'Niue' },
  { id: 159, label: 'Norfork Island' },
  { id: 160, label: 'Northern Mariana Islands' },
  { id: 161, label: 'Norway' },
  { id: 162, label: 'Oman' },
  { id: 163, label: 'Pakistan' },
  { id: 164, label: 'Palau' },
  { id: 165, label: 'Panama' },
  { id: 166, label: 'Papua New Guinea' },
  { id: 167, label: 'Paraguay' },
  { id: 168, label: 'Peru' },
  { id: 169, label: 'Philippines' },
  { id: 170, label: 'Pitcairn' },
  { id: 171, label: 'Poland' },
  { id: 172, label: 'Portugal' },
  { id: 173, label: 'Puerto Rico' },
  { id: 174, label: 'Qatar' },
  { id: 175, label: 'Reunion' },
  { id: 176, label: 'Romania' },
  { id: 177, label: 'Russian Federation' },
  { id: 178, label: 'Rwanda' },
  { id: 179, label: 'Saint Kitts and Nevis' },
  { id: 180, label: 'Saint Lucia' },
  { id: 181, label: 'Saint Vincent and the Grenadines' },
  { id: 182, label: 'Samoa' },
  { id: 183, label: 'San Marino' },
  { id: 184, label: 'Sao Tome and Principe' },
  { id: 185, label: 'Saudi Arabia' },
  { id: 186, label: 'Senegal' },
  { id: 187, label: 'Seychelles' },
  { id: 188, label: 'Sierra Leone' },
  { id: 189, label: 'Singapore' },
  { id: 190, label: 'Slovakia' },
  { id: 191, label: 'Slovenia' },
  { id: 192, label: 'Solomon Islands' },
  { id: 193, label: 'Somalia' },
  { id: 194, label: 'South Africa' },
  { id: 195, label: 'South Georgia South Sandwich Islands' },
  { id: 196, label: 'Spain' },
  { id: 197, label: 'Sri Lanka' },
  { id: 198, label: 'St. Helena' },
  { id: 199, label: 'St. Pierre and Miquelon' },
  { id: 200, label: 'Sudan' },
  { id: 201, label: 'Suriname' },
  { id: 202, label: 'Svalbarn and Jan Mayen Islands' },
  { id: 203, label: 'Swaziland' },
  { id: 204, label: 'Sweden' },
  { id: 205, label: 'Switzerland' },
  { id: 206, label: 'Syrian Arab Republic' },
  { id: 207, label: 'Taiwan' },
  { id: 208, label: 'Tajikistan' },
  { id: 209, label: 'Tanzania, United Republic of' },
  { id: 210, label: 'Thailand' },
  { id: 211, label: 'Togo' },
  { id: 212, label: 'Tokelau' },
  { id: 213, label: 'Tonga' },
  { id: 214, label: 'Trinidad and Tobago' },
  { id: 215, label: 'Tunisia' },
  { id: 216, label: 'Turkey' },
  { id: 217, label: 'Turkmenistan' },
  { id: 218, label: 'Turks and Caicos Islands' },
  { id: 219, label: 'Tuvalu' },
  { id: 220, label: 'Uganda' },
  { id: 221, label: 'Ukraine' },
  { id: 222, label: 'United Arab Emirates' },
  { id: 223, label: 'United Kingdom' },
  { id: 1, 	 label: 'United States' },
  { id: 224, label: 'United States minor outlying islands' },
  { id: 225, label: 'Uruguay' },
  { id: 226, label: 'Uzbekistan' },
  { id: 227, label: 'Vanuatu' },
  { id: 228, label: 'Vatican City State' },
  { id: 229, label: 'Venezuela' },
  { id: 230, label: 'Vietnam' },
  { id: 232, label: 'Virgin Islands (U.S.)' },
  { id: 231, label: 'Virigan Islands (British)' },
  { id: 233, label: 'Wallis and Futuna Islands' },
  { id: 234, label: 'Western Sahara' },
  { id: 235, label: 'Yemen' },
  { id: 236, label: 'Yugoslavia' },
  { id: 237, label: 'Zaire' },
  { id: 238, label: 'Zambia' },
  { id: 239, label: 'Zimbabwe' }
];

class UpdatePiiModal extends Component {
  constructor(props){
    super(props);
    this.alertContainer; //using REF defined in the render function
    this.state = {
      isOpen: true,
      success: false,
      user: undefined,
      ...this.props.location.state //merge state if there is any
    };

    this.back = this.back.bind(this);
  }

  componentDidMount() {
    // Grab the user in case we don't have it from route state
    if (!this.state.user) {
      UserAPI.account()
        .then((userResponse) => {
          this.setState({
            user: userResponse.data
          });
        })
        .catch(e => {
          this.alert('danger', e.message);
        });
    }
  }

  back() {
    this.setState(
      {
        isOpen: false
      },
      this.props.history.goBack
    );
  }

  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  render(){
    const { isOpen } = this.state;
    return (
      <Modal
        isOpen={this.state.isOpen}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal-dialog modal-lg',
          afterOpen: 'after-open',
          beforeClose: 'before-close'
        }}
        overlayClassName={{
          base: 'modal fade',
          afterOpen: 'show',
          beforeClose: ''
        }}
      >
        {
          !this.state.success ? (
            <Formik
              initialValues={{ isForeign: this.state.user ? this.state.user.isForeign : '' }}
              validationSchema={SignupSchemaPii}
              onSubmit={(values) => {
                const userData = {
                  user_id: this.state.user.id,
                  first_name: this.state.user.firstName,
                  last_name: this.state.user.lastName,
                  middle_name: this.state.user.middleName
                };

                const userForeignStatus = {
                	isForeign: values.isForeign === 'true'
                };

                Promise.all([
                  UserAPI.updateAccount({...userForeignStatus, piiUpdated: moment().toDate()}),
                  UserAPI.updatePii({...values, ...userData})
                ])
                	.then(([ updateResult, piiResult ]) => {
                    this.setState({ success: true })
                  })
                  .catch(e => {
                    console.log(e);
                    if(e.status === 400) {
                      this.alert('success',"Thank you for your submission.");
                    }
                    else {
                      this.alert('danger', e.message);
                    }
                  });
              }}
              render={({ values, actions, errors, touched, isSubmitting, setStatus, status }) => (
                <div className="modal-content">
                  <div className="modal-header text-left">
                    <h5 className="modal-title">
                      <FontAwesomeIcon icon="exclamation-triangle" /> PII
                    </h5>
                  </div>
                  <Form>
                    <div className="modal-body">
                      <AlertContainer ref={(container) => { this.alertContainer = container; }} />
                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="isForeign">Are you a US citizen? <strong className="text-danger">*</strong></label>
                        <Field name="isForeign" component="select" required className={`form-control${errors.isForeign ? ' is-invalid' : ''}`}>
                          <option value=""></option>
													<option value="false">Yes</option>
													<option value="true">No</option>
                        </Field>
                        <ErrorMessage name="isForeign">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>
                      {
                      	values.isForeign === 'true' ? (
                      		<div>
			                      <div className="form-group">
			                        <label className="font-weight-bold" htmlFor="country_of_citizenship">Country of Citizenship <strong className="text-danger">*</strong></label>
			                        <Field name="country_of_citizenship" component="select" required className={`form-control${errors.country_of_citizenship ? ' is-invalid' : ''}`}>
			                          <option value=""></option>
			                          {
			                            countries.map((option, index) => (
			                              <option key={`country-${index}`} value={option.id}>{option.label}</option>
			                            ))
			                          }
			                        </Field>
			                        <ErrorMessage name="country_of_citizenship">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
			                      </div>
			                      <div className="form-group">
			                        <label className="font-weight-bold" htmlFor="country_of_birth">Country of Birth <strong className="text-danger">*</strong></label>
			                        <Field name="country_of_birth" component="select" required className={`form-control${errors.country_of_birth ? ' is-invalid' : ''}`}>
			                          <option value=""></option>
			                          {
			                            countries.map((option, index) => (
			                              <option key={`country-${index}`} value={option.id}>{option.label}</option>
			                            ))
			                          }
			                        </Field>
			                        <ErrorMessage name="country_of_birth">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
			                      </div>
			                      <div className="form-group">
			                        <label className="font-weight-bold" htmlFor="region_of_birth">Region / Province of Birth <strong className="text-danger">*</strong></label>
			                        <Field name="region_of_birth" required className={`form-control${errors.region_of_birth ? ' is-invalid' : ''}`} />
			                        <ErrorMessage name="region_of_birth">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
			                      </div>
			                      <div className="form-group">
			                        <label className="font-weight-bold" htmlFor="dob">Date of Birth <strong className="text-danger">*</strong></label>
			                        <Field name="dob" type="date" required preview="MM/DD/YYYY" className={`form-control${errors.dob ? ' is-invalid' : ''}`} />
			                        <ErrorMessage name="dob">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
			                      </div>
			                      <div className="form-group">
			                        <label className="font-weight-bold" htmlFor="pra">Permanent Resident Alien (PRA) Number</label>
			                        <Field name="pra" className={`form-control${errors.pra ? ' is-invalid' : ''}`} />
			                        <ErrorMessage name="pra">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
			                      </div>
			                      <div className="form-group">
			                        <label className="font-weight-bold" htmlFor="ppn">Passport Number <strong className="text-danger">*</strong></label>
			                        <Field name="ppn" required className={`form-control${errors.ppn ? ' is-invalid' : ''}`} />
			                        <ErrorMessage name="ppn">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
			                      </div>
			                      <div className="form-group">
			                        <label className="font-weight-bold" htmlFor="pp_expiration">Passport Expiration <strong className="text-danger">*</strong></label>
			                        <Field name="pp_expiration" required type="date" preview="MM/DD/YYYY" className={`form-control${errors.pp_expiration ? ' is-invalid' : ''}`} />
			                        <ErrorMessage name="pp_expiration">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
			                      </div>
			                      <div className="form-group">
			                        <label className="font-weight-bold" htmlFor="visa_type">Visa Type <strong className="text-danger">*</strong></label>
			                        <Field name="visa_type" required className={`form-control${errors.pp_expiration ? ' is-invalid' : ''}`} />
			                        <ErrorMessage name="visa_type">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
			                      </div>
			                      <div className="form-group">
			                        <label className="font-weight-bold" htmlFor="visa_issued_date">Visa Issued Date <strong className="text-danger">*</strong></label>
			                        <Field name="visa_issued_date" required type="date" preview="MM/DD/YYYY" className={`form-control${errors.visa_issued_date ? ' is-invalid' : ''}`} />
			                        <ErrorMessage name="visa_issued_date">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
			                      </div>
			                      <div className="form-group">
			                        <label className="font-weight-bold" htmlFor="visa_expiration_date">Visa Expiration Date <strong className="text-danger">*</strong></label>
			                        <Field name="visa_expiration_date" required type="date" preview="MM/DD/YYYY" className={`form-control${errors.visa_expiration_date ? ' is-invalid' : ''}`} />
			                        <ErrorMessage name="visa_expiration_date">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
			                      </div>
                      		</div>
                      	) : (
													<div>
														<div className="form-group">
			                        <label className="font-weight-bold" htmlFor="dob">Date of Birth <strong className="text-danger">*</strong></label>
			                        <Field name="dob" type="date" required preview="MM/DD/YYYY" className={`form-control${errors.dob ? ' is-invalid' : ''}`} />
			                        <ErrorMessage name="dob">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
			                      </div>
			                      <div className="form-group">
			                        <label className="font-weight-bold" htmlFor="ssn">Social Security Number <strong className="text-danger">*</strong></label>
			                        <Field name="ssn" required className={`form-control${errors.ssn ? ' is-invalid' : ''}`} />
			                        <ErrorMessage name="ssn">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
			                      </div>
                      		</div>
                      	)
                      }
                    </div>
                    <div className="modal-footer">
                      <button className="btn btn-default" type="submit">Update PII</button>
                      <button className="btn btn-primary" onClick={this.back}>Cancel</button>
                    </div>
                  </Form>
                </div>
              )}
            />
          ) : (
            <div className="modal-content">
              <div className="modal-header text-left">
                <h5 className="modal-title">
                  <FontAwesomeIcon icon="exclamation-triangle" /> PII
                </h5>
              </div>
              <div className="modal-body">
                <p className="text-center mb-0"><strong>Your PII was successfully updated.</strong></p>
              </div>
              <div className="modal-footer">
                <button className="btn btn-success" onClick={this.back}>Ok</button>
              </div>
            </div>
          )
        }
      </Modal>
    );
  }
}

export default UpdatePiiModal;