import axios from 'axios';

import history from './history';
import config from '../config';

import UserAPI from './UserAPI';

const apiPath = config.api.instruments;

function url(...rest) {
  return rest.map(val => `${val}`.replace(/\/$/, '')).join('/');
}

class InstrumentAPI {
  static get() {
    return axios({
      method: 'get',
      url: apiPath,
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }
}

export default InstrumentAPI;
