import React from 'react';
import { Route, Link } from 'react-router-dom';

import UserAPI from '../../api/UserAPI';


class AuthRoute extends React.Component {
  constructor(props) {
    super(props);
    this.hasRoles = props.roles === undefined || UserAPI.hasRoles(props.roles);
  }

  componentWillMount() {
    // get credentials
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (UserAPI.token() !== null && UserAPI.user() !== null) {
            if (this.hasRoles) {
              return <Component {...props} {...rest} />;
            }

            return (
              <Link to={{
                  pathname: '/lansce/error',
                  message: 'Whoops! You do not have rights to view this content.',
                  state: { from: props.location }
                }}
              />
            );
          }

          return (
            <Link to={{
                pathname: '/lansce/login',
                state: { from: props.location }
              }}
            />
          );
        }}
      />
    );
  }
}

export default AuthRoute;
