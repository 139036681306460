import React, { Fragment, useEffect, useState } from "react";

import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';

// Configuration Imports
import config from '../../../config';
import * as log from 'loglevel';

import { isEmpty } from 'lodash';

// useHooks later
//import AlertContainer from '../utils/AlertContainer';

function AddProposalWizardSecondPage(props) {
  if (props.currentStep !== 2) { // Prop: The current step
    return null
  }

  const [debug, setDebug] = useState(config.debug);

  const validate = () => {
    if (confirm('Are you sure you want to go back?')) { // eslint-disable-line
      props.previousStep();
    }
  };

  const handleCancelButton = () => {
    props.previousStep();
  };

  const ProposalInit = {
    title: '',
    briefDescription: '',
    instrument: { id: '' },
    committee: { id: '' },
    proprietary: '',
    proposalCall: {}
  };
  
  const ProposalSchema = Yup.object().shape({
    briefDescription: Yup.string().required('You are required to enter a description.'),
    committeeLength:  Yup.number().default(0),
    proprietary:      Yup.string().required('You must indicate if your proposal is proprietary.'),
    title:            Yup.string().required('You must enter a title for your proposal.'),
    instrument:       Yup.object().shape({
      id: Yup.string().required('You must choose an instrument.')
    }),
    committee:        Yup.object().when('committeeLength', {
      is: (val) => val > 0,
      then: Yup.object({
        id: Yup.string().required('You must choose a committee.')
      })
    }),
  });
  
  log.debug("()()()()()()()()()(&*&*&*&*BEFORE RETURN IN STEP 2");
  log.debug(props);
  if (_.isEmpty(props.wizardState.selectedProposalCall)) {
    log.debug("Hello?");
    return (<div>LOADING PAGE 2</div>)
  }
  else {
    log.debug("IN SECOND PAGE WITH VALID DATA!");
    log.debug(props.wizardState);
    // return (<div>IN SECOND PAGE WITH VALID DATA!</div>);
    // return (
    //   (props.wizardState && props.wizardState.filteredInstruments) ? props.wizardState.filteredInstruments.map((option, index) => (
    //     <div key={`instrument-${index}`}>
    //       { option.abbreviation === option.name ? option.name : `${option.abbreviation} - ${option.name}` }
    //     </div>
    //   )) : (<div>error with thigs</div>)
    // );

    const instrumentList = props.wizardState.selectedProposalCall.instruments;
    const committeesList = props.wizardState.selectedProposalCall.committees;
    log.debug("BEFORE SECOND PAGE return();");
    log.debug("BEFORE SECOND PAGE return();");
    return (
      <Formik
        initialValues={{}}
        validationSchema={ProposalSchema}
        onSubmit={props.submit}
      >
        {({ errors, touched, values, isSubmitting, setFieldValue }) => (
          <Form>
            <div className="row">
              <div className="col">
                <h1>Create a New Proposal</h1>
                <div className="form-group">
                  <label className="font-weight-bold" htmlFor="title">Proposal Title <span className="text-danger">*</span></label>
                  <Field
                    name="title"
                    placeholder="Enter a title for your proposal..."
                    disabled={isSubmitting}
                    required
                    autoComplete="off"
                    className={`form-control${errors.title && touched.title ? ' is-invalid' : ''}`}
                  />
                  <ErrorMessage name="title">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                </div>
                <div className="form-group">
                  <label className="font-weight-bold" htmlFor="briefDescription">Brief Description <span className="text-danger">*</span></label>
                  <Field
                    name="briefDescription"
                    component="textarea"
                    placeholder="Enter a brief description for your proposal..."
                    disabled={isSubmitting}
                    required
                    autoComplete="off"
                    rows="3"
                    className={`form-control${errors.briefDescription && touched.briefDescription ? ' is-invalid' : ''}`}
                  />
                  <ErrorMessage name="briefDescription">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                </div>
                <div className="form-group">
                  <label className="font-weight-bold" htmlFor="instrument.id">Instrument <span className="text-danger">*</span></label>
                  <Field
                    name="instrument.id"
                    component="select"
                    disabled={isSubmitting || instrumentList === 1}
                    required
                    autoComplete="off"
                    className={`form-control${errors.instrument && touched.instrument && errors.instrument.id && touched.instrument.id ? ' is-invalid' : ''}`}
                  >
                    <option value="" label="" />
                    {
                      instrumentList ? instrumentList.map((option, index) => (
                        <option key={`instrument-${index}`} value={option.id}>
                          { option.abbreviation === option.name ? option.name : `${option.abbreviation} - ${option.name}` }
                        </option>
                      )) : (<option></option>)
                    }
                  </Field>
                  <ErrorMessage name="instrument.id">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                </div>
                {
                  _.isEmpty(committeesList) ? (
                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="committee.id">No Committees For Proposal Call </label>
                    </div>
                  ) : (
                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="committee.id">Committee <span className="text-danger">*</span></label>
                      <Field
                        name="committee.id"
                        component="select"
                        disabled={isSubmitting || committeesList.length === 1}
                        required
                        autoComplete="off"
                        className={`form-control${errors.committee && touched.committee && errors.committee.id && touched.committee.id ? ' is-invalid' : ''}`}
                      >
                        <option value="" label="" />
                        {
                          committeesList.map((option, index) => (
                            <option key={`committee-${index}`} value={option.id}>
                              { option.name }
                            </option>
                          ))
                        }
                      </Field>
                      <ErrorMessage name="committee.id">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                  )
                }
                <div className="form-group">
                  <label className="font-weight-bold" htmlFor="proprietary">Is your proposal proprietary? <strong className="text-danger">*</strong></label>
                  <Field name="proprietary" component="select" required className={`form-control${errors.proprietary ? ' is-invalid' : ''}`}>
                    <option value=""></option>
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </Field>
                  <ErrorMessage name="proprietary">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button className="btn btn-secondary btn-block" onClick={() => handleCancelButton()}>
                  Cancel
                </button>
              </div>
              <div className="col">
                <button className="btn btn-success btn-block" type="submit" disabled={isSubmitting}>
                  Create
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );

    log.debug("AFTER SECOND PAGE return();");
  }
};
          
export default AddProposalWizardSecondPage;
