import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Wizard, Steps, Step } from 'react-albus';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import UserAPI from '../api/UserAPI';
import config from '../config';

const SignupSchemaUser = Yup.object().shape({
  email: Yup.string()
    .required('Email is required.')
    .email('Please enter a valid email.')
    .test(
      'email', // Name
      'This email is already in use.', // Failure message
      (value) => {
        if(config.validation.email.test(value)){
          return UserAPI.getPublic({ email: value, max: 1 })
            .then(response => response.data === false);
        }
        return false;
      }
    ),
  password: Yup.string()
    .matches(
      config.validation.password,
      'Passwords must be at least 8 characters and contain 4 character classes (uppercase, lowercase, number, and special characters).'
    )
    .required('You must enter a password.'),
  password2: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .required('You must confirm your password.'),
});

const SignupSchemaContact = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required.'),
  middleName: Yup.string()
    .required('Middle name is required.'),
  lastName: Yup.string()
    .required('Last name is required.'),
  phone: Yup.string()
    .matches(
      config.validation.phone,
      'Ex. 111-222-3333'
    )
    .required('You must enter a valid phone number.'),
  gender: Yup.string()
    .required('Gender is required.')
});

const SignupSchemaHomeAddress = Yup.object().shape({
  homeAddress:
    Yup.object().shape({
      line1: Yup.string()
        .required('Address is required.'),
      city: Yup.string()
        .required('City is required.'),
      stateOrProvince: Yup.string()
        .required('State or Province is required.'),
      country: Yup.string()
        .required('Country is required.'),
      postalCode: Yup.string()
        .required('Zip Code is required.'),
    })
});

const SignupSchemaWorkAddress = Yup.object().shape({
  workInstitution: Yup.string()
        .required('Institution Name is required.'),
  workDepartment: Yup.string()
        .required('Institution Department is required.'),
  workAddress:
    Yup.object().shape({
      line1: Yup.string()
        .required('Address is required.'),
      city: Yup.string()
        .required('City is required.'),
      stateOrProvince: Yup.string()
        .required('State or Province is required.'),
      country: Yup.string()
        .required('Country is required.'),
      postalCode: Yup.string()
        .required('Zip Code is required.'),
    })
});

const SignupSchemaPosition = Yup.object().shape({
  workPosition: Yup.string()
    .required('Position is required.'),
  areaOfExpertise: Yup.string()
    .required('Area of Expertise is required.'),
  altEmail: Yup.string()
    .required('Employer email is required. Can be same as registration email.')
});

const SignupSchemaForeign = Yup.object().shape({
  isForeign: Yup.string(),
  previousWorkInstitution: Yup.string()
    .when("isForeign", {
      is: 'yes',
      then: Yup.string().required(
        'Institution is required.'
      )
    }),
  previousWorkDepartment: Yup.string().nullable(),
  previousWorkAreaOfExpertise: Yup.string()
    .when("isForeign", {
      is: 'yes',
      then: Yup.string().required(
        'Area of Expertise is required.'
      )
    }),
  previousWorkPhone: Yup.string()
    .when("isForeign", {
      is: 'yes',
      then: Yup.string().required(
        'Phone Number is required.'
      )
    }),
  previousWorkEmail: Yup.string().nullable(),
  previousWorkAddress: Yup.object().shape({
    line1: Yup.string()
      .when("isForeign", {
        is: 'yes',
        then: Yup.string().required(
          'Address is required.'
        )
      }),
    city: Yup.string()
      .when("isForeign", {
        is: 'yes',
        then: Yup.string().required(
          'City is required.'
        )
      }),
    stateOrProvince: Yup.string().nullable(),
    country: Yup.string()
      .when("isForeign", {
        is: 'yes',
        then: Yup.string().required(
          'Country is required.'
        )
      }),
    postalCode: Yup.string()
      .when("isForeign", {
        is: 'yes',
        then: Yup.string().required(
          'Zip Code is required.'
        )
      })
  })
});

const positions = [
  { id: 1,  label: 'Staff' },
  { id: 2,  label: 'Undergraduate' },
  { id: 3,  label: 'Graduate Student' },
  { id: 4,  label: 'Post-doc' },
  { id: 5,  label: 'Faculty' },
  { id: 6,  label: 'Retired' },
  { id: 7,  label: 'Other' },
  { id: 8,  label: 'Technician' },
  { id: 9,  label: 'Scientist' },
  { id: 10, label: 'Engineer' }
];

const countries = [
  { id: 1, label: 'United States' },
  { id: 3, label: 'Afghanistan' },
  { id: 4, label: 'Albania' },
  { id: 5, label: 'Algeria' },
  { id: 6, label: 'American Samoa' },
  { id: 7, label: 'Andorra' },
  { id: 8, label: 'Angola' },
  { id: 9, label: 'Anguilla' },
  { id: 10, label: 'Antarctica' },
  { id: 11, label: 'Antigua and/or Barbuda' },
  { id: 12, label: 'Argentina' },
  { id: 13, label: 'Armenia' },
  { id: 14, label: 'Aruba' },
  { id: 15, label: 'Australia' },
  { id: 16, label: 'Austria' },
  { id: 17, label: 'Azerbaijan' },
  { id: 18, label: 'Bahamas' },
  { id: 19, label: 'Bahrain' },
  { id: 20, label: 'Bangladesh' },
  { id: 21, label: 'Barbados' },
  { id: 22, label: 'Belarus' },
  { id: 23, label: 'Belgium' },
  { id: 24, label: 'Belize' },
  { id: 25, label: 'Benin' },
  { id: 26, label: 'Bermuda' },
  { id: 27, label: 'Bhutan' },
  { id: 28, label: 'Bolivia' },
  { id: 29, label: 'Bosnia and Herzegovina' },
  { id: 30, label: 'Botswana' },
  { id: 31, label: 'Bouvet Island' },
  { id: 32, label: 'Brazil' },
  { id: 33, label: 'British lndian Ocean Territory' },
  { id: 34, label: 'Brunei Darussalam' },
  { id: 35, label: 'Bulgaria' },
  { id: 36, label: 'Burkina Faso' },
  { id: 37, label: 'Burundi' },
  { id: 38, label: 'Cambodia' },
  { id: 39, label: 'Cameroon' },
  { id: 2,  label: 'Canada' },
  { id: 40, label: 'Cape Verde' },
  { id: 41, label: 'Cayman Islands' },
  { id: 42, label: 'Central African Republic' },
  { id: 43, label: 'Chad' },
  { id: 44, label: 'Chile' },
  { id: 45, label: 'China' },
  { id: 46, label: 'Christmas Island' },
  { id: 47, label: 'Cocos (Keeling) Islands' },
  { id: 48, label: 'Colombia' },
  { id: 49, label: 'Comoros' },
  { id: 50, label: 'Congo' },
  { id: 51, label: 'Cook Islands' },
  { id: 52, label: 'Costa Rica' },
  { id: 53, label: 'Croatia (Hrvatska)' },
  { id: 54, label: 'Cuba' },
  { id: 55, label: 'Cyprus' },
  { id: 56, label: 'Czech Republic' },
  { id: 57, label: 'Denmark' },
  { id: 58, label: 'Djibouti' },
  { id: 59, label: 'Dominica' },
  { id: 60, label: 'Dominican Republic' },
  { id: 61, label: 'East Timor' },
  { id: 62, label: 'Ecudaor' },
  { id: 63, label: 'Egypt' },
  { id: 64, label: 'El Salvador' },
  { id: 65, label: 'Equatorial Guinea' },
  { id: 66, label: 'Eritrea' },
  { id: 67, label: 'Estonia' },
  { id: 68, label: 'Ethiopia' },
  { id: 69, label: 'Falkland Islands (Malvinas)' },
  { id: 70, label: 'Faroe Islands' },
  { id: 71, label: 'Fiji' },
  { id: 72, label: 'Finland' },
  { id: 73, label: 'France' },
  { id: 74, label: 'France, Metropolitan' },
  { id: 75, label: 'French Guiana' },
  { id: 76, label: 'French Polynesia' },
  { id: 77, label: 'French Southern Territories' },
  { id: 78, label: 'Gabon' },
  { id: 79, label: 'Gambia' },
  { id: 80, label: 'Georgia' },
  { id: 81, label: 'Germany' },
  { id: 82, label: 'Ghana' },
  { id: 83, label: 'Gibraltar' },
  { id: 84, label: 'Greece' },
  { id: 85, label: 'Greenland' },
  { id: 86, label: 'Grenada' },
  { id: 87, label: 'Guadeloupe' },
  { id: 88, label: 'Guam' },
  { id: 89, label: 'Guatemala' },
  { id: 90, label: 'Guinea' },
  { id: 91, label: 'Guinea-Bissau' },
  { id: 92, label: 'Guyana' },
  { id: 93, label: 'Haiti' },
  { id: 94, label: 'Heard and Mc Donald Islands' },
  { id: 95, label: 'Honduras' },
  { id: 96, label: 'Hong Kong' },
  { id: 97, label: 'Hungary' },
  { id: 98, label: 'Iceland' },
  { id: 99, label: 'India' },
  { id: 100, label: 'Indonesia' },
  { id: 101, label: 'Iran (Islamic Republic of)' },
  { id: 102, label: 'Iraq' },
  { id: 103, label: 'Ireland' },
  { id: 104, label: 'Israel' },
  { id: 105, label: 'Italy' },
  { id: 106, label: 'Ivory Coast' },
  { id: 107, label: 'Jamaica' },
  { id: 108, label: 'Japan' },
  { id: 109, label: 'Jordan' },
  { id: 110, label: 'Kazakhstan' },
  { id: 111, label: 'Kenya' },
  { id: 112, label: 'Kiribati' },
  { id: 113, label: 'Korea, Democratic People&#39;s Republic of' },
  { id: 114, label: 'Korea, Republic of' },
  { id: 115, label: 'Kuwait' },
  { id: 116, label: 'Kyrgyzstan' },
  { id: 117, label: 'Lao People&#39;s Democratic Republic' },
  { id: 118, label: 'Latvia' },
  { id: 119, label: 'Lebanon' },
  { id: 120, label: 'Lesotho' },
  { id: 121, label: 'Liberia' },
  { id: 122, label: 'Libyan Arab Jamahiriya' },
  { id: 123, label: 'Liechtenstein' },
  { id: 124, label: 'Lithuania' },
  { id: 125, label: 'Luxembourg' },
  { id: 126, label: 'Macau' },
  { id: 127, label: 'Macedonia' },
  { id: 128, label: 'Madagascar' },
  { id: 129, label: 'Malawi' },
  { id: 130, label: 'Malaysia' },
  { id: 131, label: 'Maldives' },
  { id: 132, label: 'Mali' },
  { id: 133, label: 'Malta' },
  { id: 134, label: 'Marshall Islands' },
  { id: 135, label: 'Martinique' },
  { id: 136, label: 'Mauritania' },
  { id: 137, label: 'Mauritius' },
  { id: 138, label: 'Mayotte' },
  { id: 139, label: 'Mexico' },
  { id: 140, label: 'Micronesia, Federated States of' },
  { id: 141, label: 'Moldova, Republic of' },
  { id: 142, label: 'Monaco' },
  { id: 143, label: 'Mongolia' },
  { id: 144, label: 'Montserrat' },
  { id: 145, label: 'Morocco' },
  { id: 146, label: 'Mozambique' },
  { id: 147, label: 'Myanmar' },
  { id: 148, label: 'Namibia' },
  { id: 149, label: 'Nauru' },
  { id: 150, label: 'Nepal' },
  { id: 151, label: 'Netherlands' },
  { id: 152, label: 'Netherlands Antilles' },
  { id: 153, label: 'New Caledonia' },
  { id: 154, label: 'New Zealand' },
  { id: 155, label: 'Nicaragua' },
  { id: 156, label: 'Niger' },
  { id: 157, label: 'Nigeria' },
  { id: 158, label: 'Niue' },
  { id: 159, label: 'Norfork Island' },
  { id: 160, label: 'Northern Mariana Islands' },
  { id: 161, label: 'Norway' },
  { id: 162, label: 'Oman' },
  { id: 163, label: 'Pakistan' },
  { id: 164, label: 'Palau' },
  { id: 165, label: 'Panama' },
  { id: 166, label: 'Papua New Guinea' },
  { id: 167, label: 'Paraguay' },
  { id: 168, label: 'Peru' },
  { id: 169, label: 'Philippines' },
  { id: 170, label: 'Pitcairn' },
  { id: 171, label: 'Poland' },
  { id: 172, label: 'Portugal' },
  { id: 173, label: 'Puerto Rico' },
  { id: 174, label: 'Qatar' },
  { id: 175, label: 'Reunion' },
  { id: 176, label: 'Romania' },
  { id: 177, label: 'Russian Federation' },
  { id: 178, label: 'Rwanda' },
  { id: 179, label: 'Saint Kitts and Nevis' },
  { id: 180, label: 'Saint Lucia' },
  { id: 181, label: 'Saint Vincent and the Grenadines' },
  { id: 182, label: 'Samoa' },
  { id: 183, label: 'San Marino' },
  { id: 184, label: 'Sao Tome and Principe' },
  { id: 185, label: 'Saudi Arabia' },
  { id: 186, label: 'Senegal' },
  { id: 187, label: 'Seychelles' },
  { id: 188, label: 'Sierra Leone' },
  { id: 189, label: 'Singapore' },
  { id: 190, label: 'Slovakia' },
  { id: 191, label: 'Slovenia' },
  { id: 192, label: 'Solomon Islands' },
  { id: 193, label: 'Somalia' },
  { id: 194, label: 'South Africa' },
  { id: 195, label: 'South Georgia South Sandwich Islands' },
  { id: 196, label: 'Spain' },
  { id: 197, label: 'Sri Lanka' },
  { id: 198, label: 'St. Helena' },
  { id: 199, label: 'St. Pierre and Miquelon' },
  { id: 200, label: 'Sudan' },
  { id: 201, label: 'Suriname' },
  { id: 202, label: 'Svalbarn and Jan Mayen Islands' },
  { id: 203, label: 'Swaziland' },
  { id: 204, label: 'Sweden' },
  { id: 205, label: 'Switzerland' },
  { id: 206, label: 'Syrian Arab Republic' },
  { id: 207, label: 'Taiwan' },
  { id: 208, label: 'Tajikistan' },
  { id: 209, label: 'Tanzania, United Republic of' },
  { id: 210, label: 'Thailand' },
  { id: 211, label: 'Togo' },
  { id: 212, label: 'Tokelau' },
  { id: 213, label: 'Tonga' },
  { id: 214, label: 'Trinidad and Tobago' },
  { id: 215, label: 'Tunisia' },
  { id: 216, label: 'Turkey' },
  { id: 217, label: 'Turkmenistan' },
  { id: 218, label: 'Turks and Caicos Islands' },
  { id: 219, label: 'Tuvalu' },
  { id: 220, label: 'Uganda' },
  { id: 221, label: 'Ukraine' },
  { id: 222, label: 'United Arab Emirates' },
  { id: 223, label: 'United Kingdom' },
  { id: 1,   label: 'United States' },
  { id: 224, label: 'United States minor outlying islands' },
  { id: 225, label: 'Uruguay' },
  { id: 226, label: 'Uzbekistan' },
  { id: 227, label: 'Vanuatu' },
  { id: 228, label: 'Vatican City State' },
  { id: 229, label: 'Venezuela' },
  { id: 230, label: 'Vietnam' },
  { id: 232, label: 'Virgin Islands (U.S.)' },
  { id: 231, label: 'Virigan Islands (British)' },
  { id: 233, label: 'Wallis and Futuna Islands' },
  { id: 234, label: 'Western Sahara' },
  { id: 235, label: 'Yemen' },
  { id: 236, label: 'Yugoslavia' },
  { id: 237, label: 'Zaire' },
  { id: 238, label: 'Zambia' },
  { id: 239, label: 'Zimbabwe' }
];

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previousEmail: '',
      user: {
        email: '',
        password: '',
        password2: '',
      },
      contact: {
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        phone: '',
        orcid: ''
      },
      workPosition: {
        workPosition: {
          id: ''
        },
        areaOfExpertise: '',
        altEmail: ''
      },
      workInstitution: '',
      workDepartment: '',
      homeAddress: {
        stateOrProvince: '',
        country: '',
        postalCode: '',
        line1: '',
        line2: '',
        city: ''
      },
      workAddress: {
        stateOrProvince: '',
        country: '',
        postalCode: '',
        line1: '',
        line2: '',
        city: ''
      },
      foreign: {
        isForeign: '',
        previousWorkInstitution: '',
        previousWorkDepartment: '',
        previousWorkAreaOfExpertise: '',
        previousWorkPhone: '',
        previousWorkPosition: {
          id: '',
        },
        previousWorkAddress: {
          stateOrProvince: '',
          country: '',
          postalCode: '',
          line1: '',
          line2: '',
          city: ''
        },
      },
    };

    this.alertContainer = undefined; // using REF defined in the render function
    this.history = props.history;
  }

  render() {
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col">
            <Wizard
              history={this.history}
              basename={this.props.match.url}
              render={({ step, steps, previous, next, push }) => (
                <div className="card">
                  <div className="card-body">
                    <div className="form-row">
                      <div className="col-md-6 mx-auto">
                        <Steps>
                          <Step id='user'>
                            <Formik
                              initialValues={this.state.user}
                              validationSchema={SignupSchemaUser}
                              onSubmit={(values) => { this.setState({ user: values }, next) }}
                              render={({ values, actions, errors, touched, isSubmitting, setStatus, status }) => (
                                <Form>
                                  <div>
                                    <div className="form-group">
                                      <label className="font-weight-bold" htmlFor="email">Email <strong className="text-danger">*</strong></label>
                                      <Field
                                        name="email"
                                        required
                                        autoComplete="email"
                                        className={`form-control${errors.email && touched.email ? ' is-invalid' : ''}`}
                                        validateOnChange={false}
                                      />

                                      {/*<Field
                                        name="email"
                                        required
                                        autoComplete="email"
                                        className={`form-control${errors.email && touched.email ? ' is-invalid' : ''}`}
                                        validate={(value) => {
                                          let errors = {};

                                          return Promise((resolve, reject) => {
                                            if(status) {
                                              if(config.validation.email.test(value)) {
                                                if(status.emailsInUse[value] === 'undefined') {
                                                  UserAPI.get({ email: value, max: 1 })
                                                    .then(response => {
                                                      const used = response.data.length > 0;
                                                      setStatus({ emailsInUse: {...status.emailsInUse, [value]: response.data.length > 0} });
                                                      errors.email = used ? 'This email is already in use.' : undefined;
                                                      reject(errors);
                                                    });
                                                } else {
                                                  errors.email = status.emailsInUse[value] ? 'This email is already in use.' : undefined;
                                                  reject(errors);
                                                }
                                              } else {
                                                errors.email = 'Please enter a valid email.'
                                                reject(errors);
                                              }
                                            } else {
                                              setStatus({ emailsInUse: {} });
                                            }

                                            resolve();
                                          });

                                          return errors;
                                        }}
                                      />*/}
                                      <ErrorMessage name="email">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                    </div>

                                    <div className="form-group">
                                      <label className="font-weight-bold" htmlFor="password">Password <strong className="text-danger">*</strong></label>
                                      <Field name="password" type="password" required autoComplete="new-password" className={`form-control${errors.password && touched.password ? ' is-invalid' : ''}`} />
                                      <ErrorMessage name="password">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                    </div>

                                    <div className="form-group">
                                      <label className="font-weight-bold" htmlFor="password2">Confirm Password <strong className="text-danger">*</strong></label>
                                      <Field name="password2" type="password" required autoComplete="new-password" className={`form-control${errors.password2 && touched.password2 ? ' is-invalid' : ''}`} />
                                      <ErrorMessage name="password2">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                    </div>
                                  </div>

                                  <div className="row mb-3">
                                    <div className="col">
                                      <button type="submit" className="btn btn-primary btn-block">Next</button>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            />
                          </Step>
                          <Step id='contact'>
                            <Formik
                              initialValues={this.state.contact}
                              validationSchema={SignupSchemaContact}
                              onSubmit={(values) => { this.setState({ contact: values }, next) }}
                              render={({ values, actions, errors, touched, isSubmitting }) => (
                                <Form>
                                  <center>
                                    <h2><FontAwesomeIcon icon="user" /> Contact Information</h2>
                                  </center>
                                  <div className="alert alert-secondary">
                                    <center>
                                      Please enter required fields as they appear on your Passport or Government ID.
                                    </center>
                                  </div>
                                  <hr />
                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="firstName">First Name <strong className="text-danger">*</strong></label>
                                    <Field name="firstName" required autoComplete="given-name" className={`form-control${errors.firstName && touched.firstName ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="firstName">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="middleName">Middle Name <strong className="text-danger">*</strong></label>
                                    <Field name="middleName" autoComplete="middle-name" className={`form-control${errors.middleName && touched.middleName ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="middleName">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="lastName">Last Name <strong className="text-danger">*</strong></label>
                                    <Field name="lastName" required autoComplete="family-name" className={`form-control${errors.lastName && touched.lastName ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="lastName">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="gender">Gender <strong className="text-danger">*</strong></label>
                                    <Field component="select" name="gender" required className={`form-control${errors.gender && touched.gender ? ' is-invalid' : ''}`}>
                                      <option value=""></option>
                                      <option value="Female">Female</option>
                                      <option value="Male">Male</option>
                                    </Field>
                                    <ErrorMessage name="gender">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="phone">Phone Number <strong className="text-danger">*</strong></label>
                                    <Field name="phone" required className={`form-control${errors.phone && touched.phone ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="phone">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="orcid">ORCiD</label>
                                    <Field name="orcid"  className={`form-control${errors.orcid && touched.orcid ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="orcid">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                    <small className="form-text text-muted">Uniquely identifies your publication information.</small>
                                  </div>

                                  <div className="row mb-3">
                                    <div className="col">
                                      <button className="btn btn-secondary btn-block" onClick={previous}>Back</button>
                                    </div>
                                    <div className="col">
                                      <button type="submit" className="btn btn-primary btn-block">Next</button>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            />
                          </Step>
                          <Step id='homeAddress'>
                            <Formik
                              initialValues={this.state.homeAddress}
                              validationSchema={SignupSchemaHomeAddress}
                              onSubmit={(values) => { this.setState({ homeAddress: values }, next) }}
                              render={({ values, actions, errors, touched, isSubmitting }) => (
                                <Form>
                                  <center>
                                    <h2><FontAwesomeIcon icon="home" /> Home Address</h2>
                                  </center>
                                  <hr />
                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="homeAddress.line1">Address Line 1<strong className="text-danger">*</strong></label>
                                    <Field name="homeAddress.line1" required className={`form-control${errors.homeAddress && touched.homeAddress && errors.homeAddress.line1 && touched.homeAddress.line1 ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="homeAddress.line1">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="homeAddress.line2">Address Line 2</label>
                                    <Field name="homeAddress.line2" className={`form-control${errors.homeAddress && touched.homeAddress && errors.homeAddress.line2 && touched.homeAddress.line2 ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="homeAddress.line2">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="homeAddress.city">City <strong className="text-danger">*</strong></label>
                                    <Field name="homeAddress.city" required className={`form-control${errors.homeAddress && touched.homeAddress && errors.homeAddress.city && touched.homeAddress.city ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="homeAddress.city">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>
                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="homeAddress.stateOrProvince">State or Province<strong className="text-danger">*</strong></label>
                                    <Field name="homeAddress.stateOrProvince" required className={`form-control${errors.homeAddress && touched.homeAddress && errors.homeAddress.stateOrProvince && touched.homeAddress.stateOrProvince ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="homeAddress.stateOrProvince">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="homeAddress.postalCode">Zip <strong className="text-danger">*</strong></label>
                                    <Field name="homeAddress.postalCode" required className={`form-control${errors.homeAddress && touched.homeAddress && errors.homeAddress.postalCode && touched.homeAddress.postalCode ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="homeAddress.postalCode">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="homeAddress.country">Country <strong className="text-danger">*</strong></label>
                                    <Field name="homeAddress.country" required className={`form-control${errors.homeAddress && touched.homeAddress && errors.homeAddress.country && touched.homeAddress.country ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="homeAddress.country">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="row mb-3">
                                    <div className="col">
                                      <button className="btn btn-secondary btn-block" onClick={previous}>Back</button>
                                    </div>
                                    <div className="col">
                                      <button type="submit" className="btn btn-primary btn-block">Next</button>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            />
                          </Step>
                          <Step id='workAddress'>
                            <Formik
                              initialValues={this.state.workAddress}
                              validationSchema={SignupSchemaWorkAddress}
                              onSubmit={(values) => { this.setState({ workAddress: values }, next) }}
                              render={({ values, actions, errors, touched, isSubmitting }) => (
                                <Form>
                                  <center>
                                    <h2><FontAwesomeIcon icon="university" /> Work / University Address</h2>
                                  </center>
                                  <hr />
                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="workInstitution">Employer Name<strong className="text-danger">*</strong></label>
                                    <Field name="workInstitution" required className={`form-control${errors.workInstitution && touched.workInstitution ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="workInstitution">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="workDepartment">Employer Department<strong className="text-danger">*</strong></label>
                                    <Field name="workDepartment" required className={`form-control${errors.workDepartment && touched.workDepartment ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="workDepartment">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="workAddress.line1">Employer Address Line 1<strong className="text-danger">*</strong></label>
                                    <Field name="workAddress.line1" required className={`form-control${errors.workAddress && touched.workAddress && errors.workAddress.line1 && touched.workAddress.line1 ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="workAddress.line1">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="workAddress.line2">Employer Address Line 2</label>
                                    <Field name="workAddress.line2" className={`form-control${errors.workAddress && touched.workAddress && errors.workAddress.line2 && touched.workAddress.line2 ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="workAddress.line2">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="workAddress.city">Employer City <strong className="text-danger">*</strong></label>
                                    <Field name="workAddress.city" required className={`form-control${errors.workAddress && touched.workAddress && errors.workAddress.city && touched.workAddress.city ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="workAddress.city">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>
                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="workAddress.stateOrProvince">Employer State or Province<strong className="text-danger">*</strong></label>
                                    <Field name="workAddress.stateOrProvince" required className={`form-control${errors.workAddress && touched.workAddress && errors.workAddress.stateOrProvince && touched.workAddress.stateOrProvince ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="workAddress.stateOrProvince">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="workAddress.postalCode">Employer Zip <strong className="text-danger">*</strong></label>
                                    <Field name="workAddress.postalCode" required className={`form-control${errors.workAddress && touched.workAddress && errors.workAddress.postalCode && touched.workAddress.postalCode ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="workAddress.postalCode">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="workAddress.country">Employer Country <strong className="text-danger">*</strong></label>
                                    <Field name="workAddress.country" required className={`form-control${errors.workAddress && touched.workAddress && errors.workAddress.country && touched.workAddress.country ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="workAddress.country">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="row mb-3">
                                    <div className="col">
                                      <button className="btn btn-secondary btn-block" onClick={previous}>Back</button>
                                    </div>
                                    <div className="col">
                                      <button type="submit" className="btn btn-primary btn-block">Next</button>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            />
                          </Step>
                          <Step id='position'>
                            <Formik
                              initialValues={this.state.workPosition}
                              validationSchema={SignupSchemaPosition}
                              onSubmit={(values) => { this.setState({ workPosition: values }, next) }}
                              render={({ values, actions, errors, touched, isSubmitting }) => (
                                <Form>
                                  <center>
                                    <h2><FontAwesomeIcon icon="clipboard" /> Current Position</h2>
                                  </center>
                                  <hr />
                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="workPosition">Position <strong className="text-danger">*</strong></label>
                                    <Field name="workPosition" component="select" required className={`form-control${errors.workPosition ? ' is-invalid' : ''}`}>
                                      <option value=""></option>
                                      {
                                        positions.map((option, index) => (
                                          <option key={`position-${index}`} value={option.id}>{option.label}</option>
                                        ))
                                      }
                                    </Field>
                                    <ErrorMessage name="position">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>
                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="areaOfExpertise">Area of Expertise<strong className="text-danger">*</strong></label>
                                    <Field name="areaOfExpertise" required className={`form-control${errors.areaOfExpertise && touched.areaOfExpertise ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="areaOfExpertise">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="altEmail">Employer Email<strong className="text-danger">*</strong></label>
                                    <Field name="altEmail" required className={`form-control${errors.altEmail && touched.altEmail ? ' is-invalid' : ''}`} />
                                    <ErrorMessage name="altEmail">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                    <small className="form-text text-muted">Please enter your official Email.</small>
                                  </div>

                                  <div className="row mb-3">
                                    <div className="col">
                                      <button className="btn btn-secondary btn-block" onClick={previous}>Back</button>
                                    </div>
                                    <div className="col">
                                      <button type="submit" className="btn btn-primary btn-block">Next</button>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            />
                          </Step>
                          <Step id='foreign'>
                            <Formik
                              initialValues={this.state.foreign}
                              validationSchema={SignupSchemaForeign}
                              onSubmit={(values, formik) => {
                                this.setState({ foreign: values });
                                const user = Object.assign({}, this.state.user, this.state.contact, this.state.homeAddress, this.state.workAddress, this.state.workPosition, this.state.foreign);
                                UserAPI.signup(user)
                                  .then(() => {
                                    push('success');
                                  })
                                  .catch((e) => {
                                    push('failure');
                                  });
                              }}
                              render={({ values, actions, errors, touched, isSubmitting }) => (
                                <Form>
                                  <div className="form-group">
                                    {/* The question was changed from being negative to positive so yes and no are swapped */}
                                    <label className="font-weight-bold" htmlFor="isForeign">Are you a US Citizen? <strong className="text-danger">*</strong></label>
                                    <Field name="isForeign" component="select" required className={`form-control${errors.isForeign ? ' is-invalid' : ''}`}>
                                      <option value="false">Yes</option>
                                      <option value="true">No</option>
                                    </Field>
                                    <ErrorMessage name="isForeign">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                  </div>

                                  {
                                    values.isForeign && values.isForeign === 'true' ? (
                                      <div>
                                        <hr className="my-4"/>
                                        <center>
                                          <h2><FontAwesomeIcon icon="university" /> Previous Home Country Affiliation</h2>
                                        </center>

                                        <div className="row">
                                          <div className="form-group col-6">
                                            <label className="font-weight-bold" htmlFor="previousWorkInstitution">Institution <strong className="text-danger">*</strong></label>
                                            <Field name="previousWorkInstitution" className={`form-control${errors.previousWorkInstitution && touched.previousWorkInstitution ? ' is-invalid' : ''}`} />
                                            <ErrorMessage name="previousWorkInstitution">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                          </div>
                                          <div className="form-group col-6">
                                            <label className="font-weight-bold" htmlFor="previousWorkDepartment">Department </label>
                                            <Field name="previousWorkDepartment" className={`form-control${errors.previousWorkDepartment && touched.previousWorkDepartment ? ' is-invalid' : ''}`} />
                                            <ErrorMessage name="previousWorkDepartment">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="form-group col-6">
                                            <label className="font-weight-bold" htmlFor="previousWorkPosition">Position <strong className="text-danger">*</strong></label>
                                            <Field name="previousWorkPosition" component="select" className={`form-control${errors.previousWorkPosition && touched.previousWorkPosition ? ' is-invalid' : ''}`} >
                                              <option value=""></option>
                                              {
                                                positions.map((option, index) => (
                                                  <option key={`position-${index}`} value={option.id}>{option.label}</option>
                                                ))
                                              }
                                            </Field>
                                            <ErrorMessage name="position.id">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                          </div>
                                          <div className="form-group col-6">
                                            <label className="font-weight-bold" htmlFor="previousWorkAreaOfExpertise">Area of Expertise <strong className="text-danger">*</strong></label>
                                            <Field name="previousWorkAreaOfExpertise" className={`form-control${errors.previousWorkAreaOfExpertise && touched.previousWorkAreaOfExpertise ? ' is-invalid' : ''}`} />
                                            <ErrorMessage name="previousWorkAreaOfExpertise">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="form-group col-6 mb-0">
                                            <label className="font-weight-bold" htmlFor="previousWorkPhone">Phone (With Country Code) <strong className="text-danger">*</strong></label>
                                            <Field name="previousWorkPhone" className={`form-control${errors.previousWorkPhone && touched.previousWorkPhone ? ' is-invalid' : ''}`} />
                                            <ErrorMessage name="previousWorkPhone">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                          </div>
                                        </div>
                                        <br />
                                        <center>
                                          <h3>Address</h3>
                                        </center>
                                        <div className="row">
                                          <div className="form-group col">
                                            <div className="form-group">
                                              <label className="font-weight-bold" htmlFor="previousWorkAddress.line1">Address Line 1 <strong className="text-danger">*</strong></label>
                                              <Field name="previousWorkAddress.line1" className={`form-control${errors.previousWorkAddress && touched.previousWorkAddress && errors.previousWorkAddress.line1 && touched.previousWorkAddress.line1 ? ' is-invalid' : ''}`} />
                                              <ErrorMessage name="previousWorkAddress.line1">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="form-group col">
                                            <div className="form-group">
                                              <label className="font-weight-bold" htmlFor="previousWorkAddress.line2">Address Line 2</label>
                                              <Field name="previousWorkAddress.line2" className={`form-control${errors.previousWorkAddress && touched.previousWorkAddress && errors.previousWorkAddress.line2 && touched.previousWorkAddress.line2 ? ' is-invalid' : ''}`} />
                                              <ErrorMessage name="previousWorkAddress.line2">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="form-group col-6">
                                            <label className="font-weight-bold" htmlFor="previousWorkAddress.city">City <strong className="text-danger">*</strong></label>
                                            <Field name="previousWorkAddress.city" className={`form-control${errors.previousWorkAddress && touched.previousWorkAddress && errors.previousWorkAddress.city && touched.previousWorkAddress.city ? ' is-invalid' : ''}`} />
                                            <ErrorMessage name="previousWorkAddress.city">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                          </div>
                                          <div className="form-group col-6">
                                            <label className="font-weight-bold" htmlFor="previousWorkAddress.stateOrProvince">State or Province</label>
                                            <Field name="previousWorkAddress.stateOrProvince" className={`form-control${errors.previousWorkAddress && touched.previousWorkAddress && errors.previousWorkAddress.stateOrProvince && touched.previousWorkAddress.stateOrProvince ? ' is-invalid' : ''}`} />
                                            <ErrorMessage name="previousWorkAddress.stateOrProvince">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="form-group col-6 mb-0">
                                            <label className="font-weight-bold" htmlFor="previousWorkAddress.country">Country <strong className="text-danger">*</strong></label>
                                            <Field name="previousWorkAddress.country" className={`form-control${errors.previousWorkAddress && touched.previousWorkAddress && errors.previousWorkAddress.country && touched.previousWorkAddress.country ? ' is-invalid' : ''}`} />
                                            <ErrorMessage name="previousWorkAddress.country">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                          </div>
                                          <div className="form-group col-6">
                                            <label className="font-weight-bold" htmlFor="previousWorkAddress.postalCode">Zip <strong className="text-danger">*</strong></label>
                                            <Field name="previousWorkAddress.postalCode" className={`form-control${errors.previousWorkAddress && touched.previousWorkAddress && errors.previousWorkAddress.postalCode && touched.previousWorkAddress.postalCode ? ' is-invalid' : ''}`} />
                                            <ErrorMessage name="previousWorkAddress.postalCode">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null }

                                  <div className="row mb-3">
                                    <div className="col">
                                      <button className="btn btn-secondary btn-block" onClick={previous}>Back</button>
                                    </div>
                                    <div className="col">
                                      <button type="submit" className="btn btn-primary btn-block">Submit</button>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            />
                          </Step>
                          <Step id='success'>
                            <h1 className="text-center">Thanks for creating an account!</h1>
                            <p className="text-center">Please check your email ({this.state.user.email}) for your confirmation link.</p>
                          </Step>
                          <Step id='failure'>
                            <h1 className="text-center">Error!</h1>
                            <p className="text-center">We ran into an error attempting to create your account, please <a href="lems-admins@lanl.gov">email</a> us for assistance.</p>
                          </Step>
                        </Steps>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col text-center">
                        <Link to="/login" className="btn btn-block btn-default"><FontAwesomeIcon icon="sign-in-alt" /> Have an account?</Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
