import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as log from 'loglevel';

import AlertContainer from '../utils/AlertContainer';
import FormElement from './FormElement';

class FormGroup extends Component {
  constructor(props) {
    super(props);
    this.alertContainer = undefined; // using REF defined in the render function
    this.state = {
      debugOpen: false,
    };
    /*
      Types of things!
      input
      textarea
      checklist
      radiolist
      select
      multiselect
    */
  }

  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  render() {
    const { active = true, schema, data, update, debug } = this.props;

    const { debugOpen } = this.state;
    const elements = schema.hasOwnProperty('elements')
      ? schema.elements
      : schema.children;
    // const sortedElements = elements && elements.sort((a, b) => a.ordering - b.ordering);
    // log.debug('render() sortedElements: %o', sortedElements);
    log.debug('render() elements: %o', elements);

    return (
      <div className="form-group">
        {elements.map((element, index) => {
          return (
            <FormElement
              key={`form-element-${index}`}
              active={active}
              schema={element}
              data={data}
              update={update}
            />
          );
        })}
        {debug && active ? (
          <div className="card card-default my-4">
            <div
              className="card-header"
              onClick={() => {
                this.setState({ debugOpen: !debugOpen });
              }}
            >
              <strong>{debugOpen ? '[-]' : '[+]'}</strong>{' '}
              <strong>DEBUG</strong>
            </div>
            <div
              className="card-body"
              style={debugOpen ? { display: 'block' } : { display: 'none' }}
            >
              <pre className="m-0">{JSON.stringify(data, null, 2)}</pre>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

FormGroup.defaultProps = {
  active: true,
  debug: false,
};

FormGroup.propTypes = {
  active: PropTypes.bool,
  update: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  debug: PropTypes.bool,
};

export default FormGroup;
