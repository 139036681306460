import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Wizard, Steps, Step, WithWizard } from 'react-albus';
import * as log from 'loglevel';

import Form from '../form/Form';
import FormMultiple from '../form/FormMultiple';
import FormUsers from '../form/FormUsers';
import Create from '../form/Create';

import SchemaAPI from '../../api/SchemaAPI';
import history from '../../api/history';
import FormCalendar from '../form/FormCalendar';
import ProposalAPI from '../../api/ProposalAPI';
import UserAPI from '../../api/UserAPI';
import config from '../../config';
import Confirmation from '../form/Confirmation';

class Proposal extends Component {
  constructor(props) {
    super(props);
    this.MODES = {
      edit: 'edit',
      new: 'new',
      add: 'add'
    };

    this.debug = config.debug;

    this.mode = undefined;
    this.state = {
      instruments: [],
      forms: [],
      proposal: {
        id: undefined,
        title: '',
        briefDescription: '',
        progress: 0,
        instrument: {
          id: ''
        },
        committee: {
          id: ''
        },
        forms: []
      }
    }
  }

  componentWillMount() {
    const id = this.props.match.params.id;
    this.mode = typeof id !== 'undefined' ? this.MODES.edit : this.MODES.new;

    // Edit mode!
    if (typeof id !== 'undefined') {
      Promise.all([
        ProposalAPI.get(id),
        SchemaAPI.get(id)
      ])
      .then(([proposalResult, schemaResult]) => {
        const proposal = proposalResult.data;
        let forms = schemaResult.data.forms;

        
        //Remove hidden forms
        var i = forms.length
        while (i--) {
          if (forms[i].hidden === true) {
            forms.splice(i, 1);
          }
        } 

        this.setState({
          proposal: { ...this.state.proposal, ...proposal, id },
          forms: this.strip(forms).sort((a, b) => a.ordering - b.ordering)
        });
      })
      .catch(e => console.log(e))
    }
  }

  //ALICIA function
  /*var todelete = [];

  for (i=0; i< (forms.length); i++){
    if (typeof forms[i]== 'undefined') todelete.push(i);
  }

  todelete.sort(function(a,b) {
    return b-a}
  );

  for (i=0;i<todelete.length; i++){
    forms.splcie(todelete[i],1);
  }*/

  //

  strip(data) {
    const proposalId = this.props.match.params.id;
    // Strip empty
    return data.map((form) => {
      const formId = form.id;

      let elements = (form.elements || []).filter((element) => {
        return element ? element.visible && Object.keys(element).length !== 0 : false;
      });

      // Should probably just send this from the server to prevent recursive bs
      elements = elements.map(function recurse(element) {
        let children;

        if (element.hasOwnProperty('children')) {
          children = element.children.map(recurse);
        }

        return { ...element, children, formId, proposalId }
      });

      return {
        ...form,
        elements
      };
    });
  } 

  render() {
    const proposalId = this.props.match.params.id;
    const {
      forms,
      proposal
    } = this.state;

    // TODO: use real progress
    const progress = 99; //proposal.progress;
    const submitted = proposal.status !== 'Submitted';

    const hasAdminEditRoles = UserAPI.hasAnyRole([ UserAPI.ROLES.manager, UserAPI.ROLES.userOffice, UserAPI.ROLES.admin, UserAPI.ROLES.instrumentScientist, UserAPI.ROLES.instrumentAssistant ]);
    const active = hasAdminEditRoles || submitted; /* Or role user office, admin, or manager */

    log.debug("render() proposal: %o", proposal);
    log.debug("render() history: %o", history);

    return (
      <div className="container">
        <div className="row my-5">
          <div className="col">
            {
              forms.length > 0 ? (
                <Wizard
                  history={history}
                  basename={this.props.match.url}
                  onNext={({push, step, steps}) => {
                    if(step.id){
                      const currentIndex = steps.findIndex(s => s.id === step.id);
                      const nextIndex = currentIndex + 1 < steps.length - 1 ? currentIndex + 1 : currentIndex;
                      if(nextIndex > progress){
                        ProposalAPI.put(proposal.id, { progress });
                      }
                      this.setState({ proposal: { ...proposal, progress: nextIndex + 1 }});
                      push(steps[nextIndex].id);
                    }
                  }}
                  render={({ step, steps }) => (
                    <div className="row">
                      {
                        this.mode === this.MODES.edit ? (
                          <div className="col-3">
                            <div className="list-group">
                              {
                                steps.map((listStep, index) => (
                                  <Link 
                                    key={listStep.id}
                                    to={`${this.props.match.url}/${listStep.id}`}
                                    onClick={e => {
                                      if(!this.debug || index > progress) {
                                        // e.preventDefault();
                                      }
                                    }}
                                    className={`list-group-item list-group-item-action${listStep.id === step.id ? ' active' : ''}${index > progress ? ' disabled' : ''}`}
                                  >
                                    {listStep.title}
                                  </Link>
                                ))
                              }
                            </div>
                          </div>
                        ) : null
                      }
                      <div className="col-9">
                        <h1>Proposal {proposal.id}: {proposal.title}</h1>
                        <div className="card border-danger mb-4">
                          <div className="card-body text-center py-2">
                            <strong className="text-danger">DO NOT SUBMIT CLASSIFIED INFORMATION</strong>
                          </div>
                        </div>
                        
                        <WithWizard
                          render={({ next, previous, step, steps }) => {
                            const width = ((steps.indexOf(step) / (steps.length - 1)) * 100);
                            return (
                              <div className="progress mb-4">
                                <div className="progress-bar" role="progressbar" style={{ width: `${width}%` }} aria-valuenow={width} aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                            );

                          }}
                        />

                        {
                          proposal.status === 'Submitted' ? 
                            (
                              <div className="card border-success mb-4">
                                <div className="card-body text-center py-2">
                                  <strong className="text-success">Proposal was submitted. { hasAdminEditRoles ? '(Editing is allowed due to your elevated role)' : '(Editing is locked)'}</strong>
                                </div>
                              </div>
                            ) : null
                        }

                        <Steps>
                          <Step id='details' title="Proposal Information">
                            <Create
                              proposal={proposal}
                              update={(prop) => {
                                this.setState({ proposal: prop });
                              }}
                              active={active}
                            />
                          </Step>
                          {
                            forms.map((form, formIndex) => (
                              <Step key={`step-${formIndex}`} id={`step-${formIndex}`} title={form.title}>
                                {
                                  form.type === 'single' && (
                                    <Form
                                      key={`step-${form.id}`}
                                      id={`step-${form.id}`}
                                      proposal={proposal}
                                      schema={form}
                                      active={active}
                                    />
                                  )
                                }
                                {
                                  form.type === 'multiple' && (
                                    <FormMultiple
                                      key={`step-${form.id}`}
                                      id={`step-${form.id}`}
                                      proposal={proposal}
                                      schema={form}
                                      active={active}
                                    />
                                  )
                                }
                                {
                                  form.type === 'calendar' && (
                                    <FormCalendar
                                      key={`step-${form.id}`}
                                      id={`step-${form.id}`}
                                      proposal={proposal}
                                      schema={form}
                                      active={active}
                                    />
                                  )
                                }
                                {
                                  form.type === 'user' && (
                                    <FormUsers
                                      key={`step-${form.id}`}
                                      id={`step-${form.id}`}
                                      proposal={proposal}
                                      schema={form}
                                      active={active}
                                    />
                                  )
                                }
                              </Step>
                            ))
                          }
                          <Step id="confirmation" title="Confirmation">
                            <Confirmation
                              proposal={proposal}
                              forms={forms}
                              submitted={submitted}
                              active={false}
                            />
                          </Step>
                        </Steps>
                      </div>
                    </div>
                  )}
                />
              ) : null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Proposal;
