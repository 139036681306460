import React, { Component } from 'react';
import Modal from 'react-modal';
import { NavLink, Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserAPI from '../api/UserAPI';
import config from '../config';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.match = this.props.match;
    this.state = {
      accountMenuOpen: false,
      loggingOut: false,
      url: config.url
    };
    this.debug = config.debug;
    this.firstname = 'User';
    this.history = props.history;
    this.handleLogout = this.handleLogout.bind(this);
    this.gotoAccount = this.gotoAccount.bind(this);
    this.toggleAccountMenu = this.toggleAccountMenu.bind(this);
  }

  toggleAccountMenu() {
    this.setState({ accountMenuOpen: !this.state.accountMenuOpen });
  }

  gotoAccount() {
    this.history.push(`${this.history.location.pathname}account`);
  }

  handleLogout() {
    this.setState({ loggingOut: false }, () => {
      UserAPI.logout();
    });
  }

  render() {
    return (
      <nav className="navbar mb-3 navbar-expand-lg navbar-light bg-light shadow-sm">
        <div className="container">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/lansce/app/manage">My Proposals</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/lansce/app/collaborations">Collaborations</NavLink>
            </li>
            {
              this.debug ? (
                <li className="nav-item">
                  <select className="form-control" value={this.state.url} onChange={(event) => {
                    this.setState(
                      { url: config.updateUrl(event.target.value) },
                      () => {
                        window.location.reload(true);
                      }
                    );
                  }}>
                    {
                      config.urls.map((url) => (
                        <option key={url} value={url}>{url}</option>
                      ))
                    }
                  </select>
                </li>
              ) : null
            }
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a href="mailto:lems-help@lanl.gov?subject=LEMS Help" className="btn btn-outline-info mr-3">
                <FontAwesomeIcon icon="question-circle" /> Need help?
              </a>
            </li>
            <li className="nav-item">
              <Dropdown isOpen={this.state.accountMenuOpen} toggle={this.toggleAccountMenu}>
                <DropdownToggle caret className="btn btn-outline-info btn-block">
                  <span className="fa fa-user" /> My Account
                </DropdownToggle>
                <DropdownMenu>
                  <Link className="dropdown-item"
                    to={{
                      pathname: `${this.props.match.path}/account`
                    }}
                  >
                    <FontAwesomeIcon icon="cog" /> Account Details
                  </Link>
                  <DropdownItem onClick={() => { this.setState({ loggingOut: true }) }} disabled={this.state.loggingOut}>
                    <FontAwesomeIcon icon="sign-out-alt" /> Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>
        </div>
        <Modal
          isOpen={this.state.loggingOut}
          shouldCloseOnOverlayClick={false}
          className={{
            base: 'modal-dialog',
            afterOpen: 'after-open',
            beforeClose: 'before-close'
          }}
          overlayClassName={{
            base: 'modal fade',
            afterOpen: 'show',
            beforeClose: ''
          }}
          >
          <div className="modal-content">
            <div className="modal-header text-left">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="exclamation-triangle" /> Are you sure?
              </h5>
            </div>
            <div className="modal-body">
              Are you sure you want to log out of the system?
            </div>
            <div className="modal-footer">
              <button className="btn btn-default" onClick={this.handleLogout}>Logout</button>
              <button
                className="btn btn-primary"
                onClick={(event) => {
                  this.setState({ loggingOut: false })
                }}
              >Cancel</button>
            </div>
          </div>
        </Modal>
      </nav>
    );
  }
}

export default Navbar;
