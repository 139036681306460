import React, { Component } from "react";
import PropTypes from "prop-types";
import FormGroup from "../FormGroup";
import SanitizedHtml from "../../utils/SanitizedHtml";

import * as log from "loglevel";

class RadiolistElement extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { active = true, element, data, update } = this.props;
    const children = element.children || [];

    log.debug("render() element: %o", element);

    return (
      <div className="form-group">
        <label className="font-weight-bold" htmlFor={element.name}>
          <SanitizedHtml html={element.label} />{" "}
          {element.required ? <span className="text-danger">*</span> : null}
        </label>
        {element.help ? (
          <small id={`${element.id}-help`} className="form-text text-muted">
            {element.help}
          </small>
        ) : null}
        <ul className="list-unstyled ml-3">
          {children.map((schema, index) => {
            const subChildren = schema.children || [];
            return (
              <li key={`${element.id}-${index}`} className="form-check">
                <input
                  type="radio"
                  id={`${element.id}-${index}`}
                  name={element.name}
                  className="form-check-input"
                  onChange={active ? update : false}
                  checked={
                    data[element.name]
                      ? data[element.name].value === schema.name
                      : false
                  }
                  value={schema.name}
                />
                <label
                  className="form-check-label"
                  htmlFor={`${element.id}-${index}`}
                >
                  <SanitizedHtml html={schema.label} />
                </label>
                {schema.help ? (
                  <small
                    id={`${element.id}-help`}
                    className="form-text text-muted"
                  >
                    <SanitizedHtml html={schema.help} />
                  </small>
                ) : null}
                {subChildren.length > 0 &&
                data[element.name] &&
                data[element.name].value === schema.name ? (
                  <div className="mt-4">
                    <FormGroup {...{ schema, data, update, active }} />
                  </div>
                ) : null}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

RadiolistElement.defaultProps = {
  active: true,
};

RadiolistElement.propTypes = {
  element: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

export default RadiolistElement;
