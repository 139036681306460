import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

class SanitizedHtml extends Component {
  constructor(props) {
    super(props);
    this.options = {
      allowedTags: [
        // 'h3',
        // 'h4',
        // 'h5',
        // 'h6',
        // 'blockquote',
        'span',
        'p',
        'a',
        'ul',
        'ol',
        'nl',
        'li',
        'b',
        'i',
        'strong',
        'em',
        'strike',
        'code',
        'hr',
        'br',
        'div',
        'table',
        'thead',
        'caption',
        'tbody',
        'tr',
        'th',
        'td',
        'pre',
        // 'iframe'
      ],
      allowedAttributes: {
        '*': ['style'],
        a: [ 'href', 'name', 'target' ],
        img: [ 'src' ]
      }
    };
  }
  render() {
    const html    = this.props.html;
    const hasHtml = /<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/?>/g.test(html);

    return hasHtml ? <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(html, this.options) }} /> : html;
  }
}

SanitizedHtml.defaultProps = {
  html: ''
};

SanitizedHtml.propTypes = {
  html: PropTypes.string
};

export default SanitizedHtml;
