import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WithWizard } from 'react-albus';
import Modal from 'react-modal';
import FormGroup from './FormGroup';
import FormElement from './FormElement';
import ListElement from './ListElement';
import produce from 'immer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ValuesAPI from '../../api/ValuesAPI';
import FormAPI from '../../api/FormAPI';

import SanitizedHtml from '../utils/SanitizedHtml';
import AlertContainer from '../utils/AlertContainer';
import config from '../../config';

class FormMultiple extends Component {
  constructor(props) {
    super(props);
    this.alertContainer = undefined; // using REF defined in the render function
    this.state = {
      dirty: false,
      id: undefined,
      groupId: undefined,
      title: '',
      description: '',
      schema: [],
      data: {},
      header: [],
      list: [],
      hide: [],
      confirmOnDelete: undefined,
      isOpen: false
    };

    /*
      Types of things!
      input
      textarea
      checklist
      radiolist
      select
      multiselect
    */

    this.onFormChange     = this.onFormChange.bind(this);
    this.isComplete       = this.isComplete.bind(this);
    this.getList          = this.getList.bind(this);
    this.submit           = this.submit.bind(this);
    this.edit             = this.edit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentWillMount() {
    this.getList();
  }

  getSchemaByProperty(schema, key, value) {
    var result;
    schema.elements.some(function iter(element) {
        if (element[key] === value) {
            result = element;
            return true;
        }
        return (Array.isArray(element.children) && element.children.some(iter));
    });
    return result;
  }

  getFlatSchema(schema, key) {
    var result = {};

    schema.forEach(function iter(a) {
        const {
          children,
          ...element
        } = a;

        result[element[key]] = element;

        if(Array.isArray(a.children)) {
          a.children.forEach(iter);
        }
    });

    return result;
  }

  getList() {
    const proposal    = this.props.proposal;
    const proposalId  = proposal.id;
    const formId      = this.props.schema.id;
    const schema      = this.props.schema;
    const elements    = this.props.schema.elements;
    const flatSchema  = this.getFlatSchema(elements, 'id');

    if (typeof proposalId !== 'undefined' && typeof formId !== 'undefined') {
      ValuesAPI.get(proposalId, formId)
        .then((response) => {
          this.setState(
            produce(draft => {
              let header = [];
              let list = response.data.reduce((results, item) => {
                const groupId = item.groupId;
                results[groupId] = results[groupId] || { groupId, fields: {} };
                const element = this.getSchemaByProperty(schema, 'name', item.element.name);
                if (element.tableHeaderVisible) {
                  //Add a new header column if its new
                  if (!header.find((obj) => item.element.id === obj.id)) {
                    header.push(
                      {
                        id: item.element.id,
                        name: item.element.name,
                        ordering: element.tableHeaderOrdering,
                        label: element.tableHeaderLabel || flatSchema[item.element.id].label
                      }
                    );
                  }
                }

                results[groupId].fields[item.element.name] = {
                  id: item.id,
                  ordering: element.tableHeaderOrdering,
                  element,
                  value: item.value,
                };

                return results;
              }, {});

              draft.header = header.sort((a, b) => a.ordering - b.ordering);
              draft.list = Object.keys(list).map(key => list[key]).sort((a, b) => a.ordering - b.ordering);
            })
          )
        })
        .catch(e => {
          this.alert('danger', e.message);
        });
    }
  }

  onFormChange(event) {
    const schema  = this.props.schema;
    const name    = event.target.name;
    let value     = event.target.value;

    this.setState(
      produce(this.state, draft => {
        // If we don't already have data for this FormElement, create it.
        if (!draft.data[name]) {
          draft.data[name] = {
            // Combine the element with the full object schema
            element: this.getSchemaByProperty(schema, 'name', name),
            value: null
          };
        }

        // If this is FormElement is a checkbox, flip the value
        if (event.target.type === 'checkbox') {
          value = !draft.data[name].value;
        }

        // Grab the element schema, contined in element
        const { element } = draft.data[name];

        // If this element has children
        if (Array.isArray(element.children)) {
          // Loop through children if there are any
          element.children.forEach((child) => {
            // If the value was flipped to false or the value is not affermative
            if (element.value === false || child.name !== value) {
              this.nullTree(child, draft);
            }
          });
        }

        // State is dirty now
        draft.dirty = true;
        // Finally set the value
        draft.data[name].value = value;
      }),
      this.isComplete
    );
  }

  // Traverse a child tree and null the values
  nullTree(element, draft) {
    if (draft.data[element.name]) {
      draft.data[element.name].value = null;
    }

    if(Array.isArray(element.children)) {
      element.children.forEach(function iter(child) {
        if (draft.data[child.name]) {
          draft.data[child.name].value = null;
        }
        return (Array.isArray(child.children) && child.children.forEach(iter));
      });
    }
  }

  // Has the user filled in all required fields
  isComplete() {
    const { elements = [] } = this.props.schema;
    const { data } = this.state;

    if (!data) {
      return false;
    }

    const valid = elements.map((element) => {
      if (!element.required || !element.visible) {
        return true;
      }

      const name = element.name || undefined;
      const type = element.type || undefined;

      if (type === 'checklist') {
        return element.options.map((option) => {
          return data[option.name];
        }).includes(true);
      }

      return !(typeof data[name] === 'undefined' || data[name] === '' || data[name] === null)
    });

    return !valid.includes(false);
  }

  edit(form){
    console.log(form);
    this.setState(
      produce(draft => {
        // Set the data for this form
        draft.groupId = form.groupId;
        draft.isOpen = true;
        draft.data = form.fields;
      })
    );
  }

  submit(next) {
    const proposal    = this.props.proposal;
    const proposalId  = proposal.id;
    const schema      = this.props.schema;
    const groupId     = this.state.groupId || null;
    const formId      = schema.id;

    const data = Object.keys(this.state.data).map((key) => {
      const element = this.state.data[key];
      return Object.assign({},
        {
          element: {
            id: element.element.id
          },
          groupId,
          value: element.value
        },
        element.id ? { id: element.id } : undefined
      );
    });

    const newData = data.filter((item) => !item.id);
    const existingData = data.filter((item) => item.id);

    let calls = [];

    if (newData.length > 0) {
      calls.push(ValuesAPI.post(proposalId, formId, groupId, { id: proposalId, formValues: newData }));
    }

    if (existingData.length > 0) {
      calls.push(ValuesAPI.put(proposalId, formId, groupId, { id: proposalId, formValues: existingData }));
    }

    Promise.all(calls)
      .then((response) => {
        next();
      })
      .catch(e => {
        this.alert('danger', e.message);
      });
  }

  remove(groupId) {
    const proposal   = this.props.proposal;
    const proposalId = proposal.id;
    const formId     = this.props.schema.id;

    ValuesAPI.delete(proposalId, formId, groupId)
      .then((response) => {
        this.setState(
          { confirmOnDelete: undefined },
          this.getList
        );
      })
      .catch((e) => {
        this.setState({ confirmOnDelete: undefined });
        this.alert('danger', e.message);
      });
  }

  handleCloseModal() {
    this.setState({
      isOpen: false,
      data: {}
    });
  }

  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  getListValue(item, header) {
    const name    = header.name;
    const fields  = item.fields;

    let value = fields.hasOwnProperty(name) ? fields[name].value : '';

    // If the item is one of our list like types we need to grab the display value from its children
    if(
      fields[name] &&
      ['radiolist', 'select', 'multiselect', 'typeahead'].includes(fields[name].element.type)
    ) {
      const valueContainer = fields[name].element.children.find((child) => child.name === value);
      value = valueContainer ? valueContainer.label : '';
    }

    return value;
  }

  render() {
    const formId    = this.props.schema.id;
    const proposal  = this.props.proposal;
    const form      = this.props.schema;
    const schema    = form.schema;
    const elements  = form.elements;
    const { active = true } = this.props;

    let template = null;

    if (form && active) {
      template = (
        <div>
          <h2>{form.title}</h2>
          {
            form.description ? (
              <div className="alert alert-info"><SanitizedHtml html={form.description} /></div>
            ) : null
          }
          <button className="btn btn-success" onClick={() => { this.setState({ isOpen: true}) }}>Add: {form.title}</button>
          <div className="my-4">
            <AlertContainer ref={(container) => { this.alertContainer = container; }} />
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th colSpan="2"></th>
                    {
                      this.state.header.map((header) => (
                        <th key={header.name}>{header.label}</th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.list.map((item, index) => (
                      <tr key={item.groupId}>
                        <td>
                          <button className="btn btn-success btn-sm" onClick={() => {
                              this.edit(item);
                            }}>
                            <FontAwesomeIcon icon="pencil-alt"/>
                          </button>
                        </td>
                        <td>
                          <button className="btn btn-danger btn-sm" onClick={() => {
                              this.setState({ confirmOnDelete: item.groupId });
                            }}>
                            <FontAwesomeIcon icon="trash-alt"/>
                          </button>
                        </td>
                        {
                          this.state.header.map((header) => (
                            <td key={`${header.id}-${index}`}>{this.getListValue(item, header)}</td>
                          ))
                        }
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          <WithWizard
            render={({ next, previous, push, step, steps }) => (
              <div className="row">
                <div className="col">
                  {steps.indexOf(step) > 0 && (
                    <button className="btn btn-secondary btn-block" onClick={() => push(steps[steps.indexOf(step) - 1].id)}>
                      Back
                    </button>
                  )}
                </div>
                {
                  !form.required || (form.required && this.state.list.length > 0) ? (
                    <div className="col">
                      <button className="btn btn-primary btn-block" onClick={() => push(steps[steps.indexOf(step) + 1].id)}>
                        Next
                      </button>
                    </div>
                  ) : null
                }
              </div>
            )}
          />
          <Modal
            isOpen={this.state.isOpen}
            shouldCloseOnOverlayClick={false}
            className={{
              base: 'modal-dialog modal-lg',
              afterOpen: 'after-open',
              beforeClose: 'before-close'
            }}
            overlayClassName={{
              base: 'modal fade',
              afterOpen: 'show',
              beforeClose: ''
            }}
          >
            <div className="modal-content">
              <div className="modal-header sticky-top">
                <button type="button" className="btn btn-warning mr-auto" data-dismiss="modal" onClick={this.handleCloseModal}><span className="fa fa-close" /> Close</button>
                <button
                  className="btn btn-success"
                  onClick={(event) => {
                    this.submit(() => {
                      this.setState({
                        dirty: false,
                        groupId: undefined,
                        data: {},
                        isOpen: false
                      }, () => {
                        this.getList();
                      });
                    })
                  }}
                  disabled={!this.isComplete()}
                >
                  { this.state.groupId ? 'Update' : 'Submit' }
                </button>
              </div>
              <div className="modal-body">
                {
                  active ? (
                    <p className="text-danger font-weight-bold">(* indicates a required field)</p>
                  ) : null
                }
                <FormGroup debug={config.debug} schema={this.props.schema} data={this.state.data} update={this.onFormChange} />
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={this.state.confirmOnDelete !== undefined}
            shouldCloseOnOverlayClick={false}
            className={{
              base: 'modal-dialog',
              afterOpen: 'after-open',
              beforeClose: 'before-close'
            }}
            overlayClassName={{
              base: 'modal fade',
              afterOpen: 'show',
              beforeClose: ''
            }}
            >
            <div className="modal-content">
              <div className="modal-header text-left">
                <h5 className="modal-title"><FontAwesomeIcon icon="exclamation-triangle" /> Are you sure?</h5>
              </div>
              <div className="modal-body">
                Are you sure you want to delete this item?
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" onClick={(event) => { this.remove(this.state.confirmOnDelete)}}>Delete</button>
                <button className="btn btn-primary" onClick={(event) => { this.setState({ confirmOnDelete: undefined }) }}>Cancel</button>
              </div>
            </div>
          </Modal>
        </div>
      );
    }

    if (form && !active) {
      template =  (
        <div>
          <h2>{form.title}</h2>
          <p>{form.description}</p>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {
                    this.state.header.map((header) => (
                      <th key={header.name}>{header.label}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  this.state.list.map((item, index) => (
                    <tr key={item.groupId}>
                      {
                        this.state.header.map((header) => (
                          <td key={`${header.id}-${index}`}>{this.getListValue(item, header)}</td>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    return template;
  }
}

FormMultiple.defaultProps = {
  active: true
}

FormMultiple.propTypes = {
  proposal: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  active: PropTypes.bool
};

export default FormMultiple;
