import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

class ListElement extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.id = _.uniqueId('list-element-');
  }

  render() {
    const elementId   = this.id;
    const element     = this.props.schema;
    const data        = this.props.data;
    let renderElement = null;

    switch (element.type) {
      /*
      Build date template
      */
      case 'date':
        renderElement = (
          <div>
            <label className="font-weight-bold" htmlFor={elementId}>{element.label}</label>
            <p id={elementId}>{moment(data[element.name]).format('YYYY-MM-DD')}</p>
          </div>
        );
        break;
      /*
      Build typeahead template
      */
      case 'typeahead':
        renderElement = (
          <div className="form-group">
            <label className="font-weight-bold" htmlFor={elementId}>{element.label}</label>
            <ul className="list-unstyled ml-3">
              {
                data[element.name].map((option, index) => {
                  return (
                    <li key={`${elementId}-${index}`}>{option}</li>
                  )
                })
              }
            </ul>
          </div>
        );
        break;
      /*
      Build input template
      */
      case 'input':
        renderElement = (
          <div>
            <label className="font-weight-bold" htmlFor={elementId}>{element.label}</label>
            <p id={elementId}>{data[element.name]}</p>
          </div>
        );
        break;
      /*
      Build textarea template
      */
      case 'textarea':
        renderElement = (
          <div>
            <label className="font-weight-bold" htmlFor={elementId}>{element.label}</label>
            <p id={elementId}>{data[element.name]}</p>
          </div>
        );
        break;
      /*
      Build checklist template
      */
      case 'checklist':
        renderElement = (
          <div>
            <label className="font-weight-bold" htmlFor={elementId}>{element.label}</label>
            <ul className="list-unstyled ml-3">
              {/* {
                element.options.map((option, index) => {
                  return data[option.name] ? (
                      <li key={`${elementId}-${index}`}>{option.label}</li>
                    ) : null
                })
              } */}
            </ul>
          </div>
        );
        break;
      /*
      Build radiolist template
      */
      case 'radiolist':
        renderElement = (
          <div>
            <label className="font-weight-bold" htmlFor={elementId}>{element.label}</label>
            <ul className="list-unstyled ml-3">
              {/* {
                element.options.map((option, index) => {
                  return data[element.name] === option.value ? (
                    <li key={`${elementId}-${index}`}>
                      {option.label}
                    </li>
                  ) : null
                })
              } */}
            </ul>
          </div>
        );
        break;
      /*
      Build select template
      */
      case 'select':
        renderElement = (
          <div>
            <label className="font-weight-bold" htmlFor={elementId}>{element.label}</label>
            <ul className="list-unstyled ml-3">
              {/* {
                element.options.map((option, index) => {
                  return data[option.name] === option.value ? (
                    <li key={`${elementId}-${index}`}>{option.label}</li>
                  ) : null
                })
              } */}
            </ul>
          </div>
        );
        break;
      default:
        renderElement = (<div className="alert alert-danger">ERROR: {JSON.stringify(element, null, '  ')}</div>)
    }

    return renderElement;
  }
}

ListElement.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default ListElement;
