import axios from 'axios';

import history from './history';
import config from '../config';

import UserAPI from './UserAPI';

const apiPath = config.api.proposals;

function url(...rest) {
  return rest.map(val => `${val}`.replace(/\/$/, '')).join('/');
}

//ALICIA here is where info for the form is grabbed from the API
class SchemaAPI {
  static get(proposalId) {
    return axios({
      method: 'get',
      url: url(apiPath, proposalId, 'schema'),
      params: { proposalId },
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static visitSchema(proposalId) {
    return axios({
      method: 'get',
      url: url(apiPath, proposalId, 'visitSchema'),
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }
}

export default SchemaAPI;
