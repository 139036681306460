import axios from 'axios';

import history from './history';
import config from '../config';

import UserAPI from './UserAPI';

const apiPath = config.api.dateEvents;

function url(...rest) {
  return rest.map(val => `${val}`.replace(/\/$/, '')).join('/');
}

class DateEventsAPI {
  static get(dateEventId) {
    return axios({
      method: 'get',
      url: url(apiPath, dateEventId),
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static post(data) {
    return axios({
      method: 'post',
      url: url(apiPath),
      data,
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static put(dateEventId, data) {
    return axios({
      method: 'put',
      url: url(apiPath, dateEventId),
      data,
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static delete(dateEventId) {
    return axios({
      method: 'delete',
      url: url(apiPath, dateEventId),
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }
}

export default DateEventsAPI;
