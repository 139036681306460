import axios from 'axios';

import history from './history';
import config from '../config';

import UserAPI from './UserAPI';

const apiPath = config.api.proposals;

function url(...rest) {
  return rest.map(val => `${val}`.replace(/\/$/, '')).join('/');
}

class ValuesAPI {
  static get(proposalId, formId) {
    return axios({
      method: 'get',
      url: url(apiPath, proposalId, 'files'),
      params: { formId },
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static post(proposalId, formId, files, onUploadProgress = () => {}) {
    let data = new FormData();

    for (var i = 0; i < files.length; i++) {
      data.append('files', files[i]);
    }

    return axios({
      method: 'post',
      url: url(apiPath, proposalId, 'files'),
      params: { formId },
      data,
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      },
      onUploadProgress
    });
  }

  static delete(proposalId, formId, name) {
    return axios({
      method: 'delete',
      url: url(apiPath, proposalId, 'files'),
      params: { formId, name },
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }
}

export default ValuesAPI;
