import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Alert extends Component {
  constructor(props) {
    super(props);
    this.id = this.props.id;
    this.delay = this.props.delay || 1000;
    this.types = {
      success: {
        icon: 'check-circle'
      },
      warning: {
        icon: 'ban'
      },
      danger: {
        icon: 'exclamation-circle'
      },
      info: {
        icon: 'info-circle'
      }
    };
  }

  componentDidMount() {
    this.setTimer();
  }

  setTimer() {
    // clear any existing timer
    if (this.timer !== null) {
      clearTimeout(this.timer);
    } else {
      this.timer = null;
    }

    // hide after `delay` milliseconds
    this.timer = setTimeout(() => {
      this.timer = null;
      this.props.onRemove(this);
    }, this.delay);
  }

  render() {
    return (
      <div className={`alert alert-${this.props.type}`} role="alert">
        <FontAwesomeIcon icon={this.types[this.props.type].icon} /> <strong>{this.props.message}</strong>
      </div>
    );
  }
}

export default Alert;
