import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WithWizard } from 'react-albus';

import AlertContainer from '../utils/AlertContainer';

import ProposalAPI from '../../api/ProposalAPI';
import Form from './Form';
import FormMultiple from './FormMultiple';
import FormUsers from './FormUsers';

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: undefined,
      message: ''
    }
  }

  render() {
    const { active, submitted, proposal, forms } = this.props;
    const proposalId = proposal.id;
    let template = null;

    if(typeof this.state.status === 'undefined') {
      template = (
        <div>
          {
            !submitted ? (
              <div className="alert alert-info" role="alert">
                Please review your proposal before submitting.
              </div>
            ) : null
          }
          {
            forms.map((form, formIndex) => {
              if(form.type === 'single') {
                return <Form
                  key={`step-${form.id}`}
                  id={`step-${form.id}`}
                  proposal={proposal}
                  schema={form}
                  active={active}
                />;
              }
  
              if(form.type === 'multiple') {
                return <FormMultiple
                  key={`step-${form.id}`}
                  id={`step-${form.id}`}
                  proposal={proposal}
                  schema={form}
                  active={active}
                />;
              }
  
              if(form.type === 'user') {
                return <FormUsers
                  key={`step-${form.id}`}
                  id={`step-${form.id}`}
                  proposal={proposal}
                  schema={form}
                  active={active}
                />;
              }
            })
          }
          <div className="row">
            <WithWizard
              render={({previous}) => (
                <div className="col">
                  <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={previous}>Back</button>
                </div>
              )}
            />
            {
              submitted ? (
                <div className="col">
                  <button type="button" className="btn btn-primary btn-block btn-lg" onClick={() => {
                    ProposalAPI.submit(proposalId)
                      .then(() => {
                        this.setState({
                          status: 'success',
                          message: 'Your proposal was submitted.'
                        });
                      })
                      .catch((e) => {
                        this.setState({
                          status: 'danger',
                          message: e.message
                        });
                      })
                  }}>Submit</button>
                </div>
              ) : null
            }
          </div>
        </div>
      );
    } else {
      template = (
        <div>
          <div className="row">
            <div className="col">
              <div className={`card border-${this.state.status} mb-4`}>
                <div className="card-body text-center py-2">
                  <strong className={`text-${this.state.status}`}>{this.state.message}</strong>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <WithWizard
              render={({previous}) => (
                <div className="col">
                  <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={previous}>Back</button>
                </div>
              )}
            />
          </div>
        </div>
        
      )
    }

    return template;
  }
}

Confirmation.defaultProps = {
  active: false,
  submitted: false
};

Confirmation.propTypes = {
  proposal: PropTypes.object.isRequired,
  forms: PropTypes.array.isRequired,
  active: PropTypes.bool,
  submitted: PropTypes.bool
};

export default Confirmation;
