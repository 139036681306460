import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../FormGroup';
import SanitizedHtml from '../../utils/SanitizedHtml';

class ChecklistElement extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      element,
      data,
      update,
      active = true,
    } = this.props;
    const children = element.children || [];

    return (
      <div className="form-group">
        <label className="font-weight-bold" htmlFor={element.id}><SanitizedHtml html={element.label} /> { element.required ? <span className="text-danger">*</span> : null}</label>
        {
          element.help ? (
            <small id={`${element.id}-help`} className="form-text text-muted"><SanitizedHtml html={element.help} /></small>
          ) : null
        }
        <ul className="list-unstyled ml-3">
          {
            children.map((schema, index) => {
              const subChildren = schema.children || [];

              return (
                <li key={`${element.id}-${index}`} className="form-check">
                  <input
                    type="checkbox"
                    id={`${element.id}-${index}`}
                    name={schema.name}
                    className="form-check-input"
                    onChange={active ? update : false}
                    checked={data[schema.name] ? Boolean(data[schema.name].value) : false}
                  />
                  <label className="form-check-label" htmlFor={`${element.id}-${index}`}><SanitizedHtml html={schema.label} /></label>
                  {
                    subChildren.length > 0 && data[schema.name] && data[schema.name].value ? (
                      <div className="mt-4">
                        <FormGroup {...{schema, data, update, active}} />
                      </div>
                    ) : null
                  }
                </li>
              );
            })
          }
        </ul>
      </div>
    )
  }
}

ChecklistElement.defaultProps = {
  active: true
};

ChecklistElement.propTypes = {
  element: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  active: PropTypes.bool
};

export default ChecklistElement;
