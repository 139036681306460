import axios from 'axios';

import history from './history';
import config from '../config';

import UserAPI from './UserAPI';

const apiPath = config.api.proposalCalls;

function url(...rest) {
  return rest.map(val => `${val}`.replace(/\/$/, '')).join('/');
}

class ProposalCallAPI {
  static search(searchParams) {
    return axios({
      method: 'get',
      url: url(apiPath, 'active'),
      params: searchParams,
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static get(proposalCallId) {
    return axios({
      method: 'get',
      url: url(apiPath, proposalCallId.toString()),
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }
}

export default ProposalCallAPI;
