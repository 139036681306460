import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

// Configuration Imports
import * as log from "loglevel";
import config from "./config";

// Component Imports
import Account from "./components/account/Account";
import AddProposalWizard from "./components/proposals/wizards/AddProposalWizard";
import Collaborator from "./components/proposals/Collaborator";
import Navbar from "./components/Navbar";
import Proposal from "./components/proposals/Proposal";
import ResetPasswordModal from "./components/account/modal/ResetPasswordModal";
import SearchableList from "./components/standard/SearchableList";
import UpdateAccountModal from "./components/account/modal/UpdateAccountModal";
import UpdatePiiModal from "./components/account/modal/UpdatePiiModal";

// API Imports
import InstrumentAPI from "./api/InstrumentAPI";
import ProposalAPI from "./api/ProposalAPI";
import UserAPI from "./api/UserAPI";

// CSS Imports
import "./App.scss";

/**
 * Main App for LEMS.
 *
 * @component
 */
class App extends React.Component {
  constructor(props) {
    super(props);
    this.history = props.history;
    this.debug = config.debug;
    this.state = {
      form: [],
      instruments: {},
    };
  }

  componentWillMount() {
    log.debug("componentWillMount() { localStorage: %o", localStorage);
    // This will confirm the users token when they first visit the app
    // If it fails the app will bounce the user to the login page
    UserAPI.verify();
    InstrumentAPI.get()
      .then(({ data }) => {
        const instruments = data.reduce((acc, current, index) => {
          return { ...acc, [current.id]: current };
        }, {});

        this.setState({
          instruments: instruments,
        });
      })
      .catch(console.log);
  }

  render() {
    const user = UserAPI.user() || {};
    const { id = 0 } = user;

    log.debug("App.js: render(): props: %j", this.props);

    return (
      <div className="app-container">
        <Navbar {...this.props} />
        <main className="">
          <Switch key={this.props.location.key}>
            {/* <Route path="/manage" roles={[UserAPI.ROLES.user]} component={Manage} /> */}
            <Route path="/lansce/app/manage" roles={[UserAPI.ROLES.user]}>
              {
                <>
                  <div className="card mx-auto text-center">
                    <div className="card-header">
                      <center>
                        <h3>Please Read!</h3>
                      </center>
                    </div>
                    <div className="card-body">
                      <div className="alert alert-info">
                        <center>
                          <p>
                            To ensure that you have the latest version of this
                            web application, please do one (or both) of the
                            following.
                          </p>
                          <ol>
                            <li>
                              Ensure you have bypassed your local
                              cache.Information{" "}
                              <a href="https://en.wikipedia.org/wiki/Wikipedia:Bypass_your_cache#Bypassing_cache">
                                HERE
                              </a>
                              .
                            </li>
                            <li>
                              Ensure you are in a private browsing
                              window.Information{" "}
                              <a href="https://en.wikipedia.org/wiki/Private_browsing#Support_in_popular_browsers">
                                HERE
                              </a>
                              .
                            </li>
                          </ol>
                        </center>
                      </div>
                    </div>
                    <div className="mb-4 text-center"></div>
                  </div>
                </>
              }
              <SearchableList
                {...this.props}
                properties={[
                  {
                    label: "ID",
                    key: "displayName",
                    type: "string",
                  },
                  {
                    label: "Proposal Name",
                    key: "title",
                    url: (match, item) => `${match.path}/edit/${item.id}`,
                    type: "string",
                  },
                  {
                    label: "Req Days",
                    key: "beamDays",
                    type: "string",
                  },
                  {
                    label: "PI",
                    key: "sortablePrincipalInvestigator",
                    // value: (o) => o.principalInvestigator.firstName ? `${o.principalInvestigator.firstName} ${o.principalInvestigator.lastName}` : 'TBD',
                    type: "string",
                  },
                  {
                    label: "Instrument",
                    key: "instrument.abbreviation",
                    value: (o) => {
                      const { abbreviation = "-" } = this.state.instruments[
                        o.instrument.id
                      ] || { abbreviation: "-" };
                      return abbreviation;
                    },
                    type: "string",
                  },
                  {
                    label: "Status",
                    key: "status",
                    type: "status",
                    badges: {
                      Submitted: "badge badge-light",
                      "in-review": "badge badge-warning",
                      awarded: "badge badge-primary",
                      scheduled: "badge badge-success",
                    },
                  },
                  {
                    label: "Submitted",
                    key: "dateCreated",
                    type: "date",
                  },
                ]}
                addLabel={"Add a Proposal"}
                sort={"dateCreated"}
                order={"desc"}
                search={ProposalAPI.search}
              />
            </Route>
            <Route
              path="/lansce/app/collaborations"
              roles={[UserAPI.ROLES.user]}
            >
              <SearchableList
                {...this.props}
                properties={[
                  {
                    label: "ID",
                    key: "id",
                    type: "string",
                  },
                  {
                    label: "Proposal Name",
                    key: "title",
                    url: (match, item) =>
                      `${match.path}/collaborator/${item.id}`,
                    type: "string",
                  },
                  {
                    label: "Status",
                    key: "status",
                    type: "status",
                    badges: {
                      Submitted: "badge badge-light",
                      "in-review": "badge badge-warning",
                      awarded: "badge badge-primay",
                      scheduled: "badge badge-success",
                    },
                  },
                  {
                    label: "Submitted",
                    key: "dateCreated",
                    type: "date",
                  },
                ]}
                sort={"title"}
                order={"asc"}
                search={(query) => {
                  if (user) {
                    query.collaborations = id;
                  }

                  return ProposalAPI.search(query);
                }}
              />
            </Route>
            <Route
              location={this.props.location}
              path="/lansce/app/add"
              roles={[UserAPI.ROLES.user]}
              component={AddProposalWizard}
            />
            <Route
              location={this.props.location}
              path="/lansce/app/collaborator/:id"
              roles={[UserAPI.ROLES.user]}
              component={Collaborator}
            />
          </Switch>
          <Route
            location={this.props.location}
            path="/lansce/app/edit/:id"
            roles={[UserAPI.ROLES.user]}
            component={Proposal}
          />
          <Route
            location={this.props.location}
            path="/lansce/app/account"
            roles={[UserAPI.ROLES.user]}
            component={Account}
          />
          <Route
            location={this.props.location}
            path="/lansce/app/account/reset"
            roles={[UserAPI.ROLES.user]}
            component={ResetPasswordModal}
          />
          <Route
            location={this.props.location}
            path="/lansce/app/account/details"
            roles={[UserAPI.ROLES.user]}
            component={UpdateAccountModal}
          />
          <Route
            location={this.props.location}
            path="/lansce/app/account/pii"
            roles={[UserAPI.ROLES.user]}
            component={UpdatePiiModal}
          />
          {/* <Route location={this.props.location} path="/lansce/manage/proposal" roles={[UserAPI.ROLES.user]} component={Proposal} /> */}
        </main>
      </div>
    );
  }
}

export default App;
