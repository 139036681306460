import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WithWizard } from 'react-albus';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import DatePicker from 'react-datepicker';
import Calendar from 'react-big-calendar';
import moment from "moment";
// import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import SanitizedHtml from '../utils/SanitizedHtml';
import AlertContainer from '../utils/AlertContainer';
import DateEventsAPI from '../../api/DateEventsAPI';
import Datetime from 'react-datetime';

// const DnDCalendar = withDragAndDrop(Calendar);
const localizer = Calendar.momentLocalizer(moment);

class FormCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.alertContainer = undefined; // using REF defined in the render function
    this.state = {
      dirty: false,
      editEvent: false,
      events: [],
    };

    this.getEvents      = this.getEvents.bind(this);
    this.submit         = this.submit.bind(this);
    this.remove         = this.remove.bind(this);
    this.isModalComplete= this.isModalComplete.bind(this);
    //~~~~~~~~~~~~~~~~~
    this.onEventResize  = this.onEventResize.bind(this);
    this.onEventDrop    = this.onEventDrop.bind(this);
    this.onSelectSlot   = this.onSelectSlot.bind(this);
    this.onSelectEvent  = this.onSelectEvent.bind(this);
  }

  componentWillMount() {
    this.getEvents();
  }

  getEvents() {
    const proposal    = this.props.proposal;
    const proposalId  = proposal.id;
    const schema      = this.props.schema;
    const formId      = schema.id;
    const dateEventType = schema.dateEventType;

    if (typeof proposalId !== 'undefined' && typeof formId !== 'undefined') {
      // Events are available with the 
      this.setState({
        events: proposal.dateEvents
          .filter(({ type }) => type === dateEventType)
          .map((event) => {
            return {
              ...event,
              start: moment(event.start),
              end: moment(event.end),
              title: event.title !== '' ? event.title : '*'
            }
          })
      });
    }
  }

  submit(event, next) {
    const {
      proposal: { id: proposalId},
      schema: { id: formId, dateEventType }
    } = this.props;

    const {
      id = null,
      ...data
    } = event;
    
    if(id !== null) {
      DateEventsAPI.put(
        id,
        {
          ...data,
          proposal: { id: proposalId },
          form: { id: formId },
          type: dateEventType,
        }
      )
        .then(({ data: newEvent }) => {
          this.setState({
            events: this.state.events.map((event) => {
              return event.id === newEvent.id ? { 
                ...newEvent,
                start: moment(newEvent.start),
                end: moment(newEvent.end),
                title: '*'
              } : event;
            })
          });
          next();
        })
        .catch(e => {
          this.alert('danger', e.message);
          next();
        });
    } else {
      // Append some other required data here
      DateEventsAPI.post({
        ...data,
        proposal: { id: proposalId },
        form: { id: formId },
        type: dateEventType,
      })
        .then(({ data: newEvent }) => {
          this.setState({
            events: [
              ...this.state.events,
              { 
                ...newEvent,
                start: moment(newEvent.start),
                end: moment(newEvent.end),
                title: '*'
              }
            ]
          });
          next();
        })
        .catch(e => {
          this.alert('danger', e.message);
          next();
        });
    }
  }

  remove(eventId) {
    DateEventsAPI.delete(eventId)
      .then((response) => {
        this.setState({
          events: this.state.events.filter(({ id }) => eventId !== id),
          confirmOnDelete: undefined,
          selectedEvent: undefined,
          editEvent: false
        });
      })
      .catch(e => {
        this.alert('danger', e.message);
        this.setState({
          dirty: false,
          confirmOnDelete: undefined,
          selectedEvent: undefined,
          editEvent: false
        });
      });
  }

  onEventResize({ event, start, end, allDay }) {
    this.setState((draft) => {
      return {
        events: draft.events.map((ev) => {
          if (event.id === ev.id) {
            return {
              ...ev,
              start,
              end
            }
          }

          return ev;
        })
      }
    });
  }

  onEventDrop({ event, start, end, allDay }) {
    this.setState((draft) => {
      return {
        events: draft.events.map((ev) => {
          if (event.id === ev.id) {
            return {
              ...ev,
              start,
              end
            }
          }

          return ev;
        })
      }
    });
  };

  onSelectSlot({ start, action }) {
    this.setState({
      editEvent: true,
      selectedEvent: {
        start: moment(start),
        end: moment(start)
      }
    });
  }

  onSelectEvent(event, action) {
    this.setState({ 
      editEvent: true,
      selectedEvent: event,
    });
  }

  isModalComplete() {
    return  this.state.selectedEvent &&
            moment.isMoment(this.state.selectedEvent.start) &&
            moment.isMoment(this.state.selectedEvent.end);
  }

  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  render() {
    const form      = this.props.schema;
    const schema    = form.schema;
    const { active = true } = this.props;

    /*
      Will get data as formElements linked by the groupId
      Need to process incoming data to group those values into objects for display
      on the calendar but this is only a display format
    */

    if (active) {
      return (
        <div>
          <h2>{form.title}</h2>
          {
            form.description ? (
              <div className="alert alert-info"><SanitizedHtml html={form.description} /></div>
            ) : null
          }
          <button
            className="btn btn-success"
            onClick={() => {
              this.setState({
                editEvent: true,
                selectedEvent: {
                  start: moment().startOf('day'), 
                  end: moment().endOf('day'),
                }
              })
            }}
          >
            Add Dates
          </button>
          <div className="my-4">
            <AlertContainer ref={(container) => { this.alertContainer = container; }} />
            <div className="row">
              <div className="col">
                <Calendar
                  selectable
                  resizable
                  localizer={localizer}
                  defaultDate={new Date()}
                  defaultView={Calendar.Views.MONTH}
                  events={this.state.events}
                  onEventDrop={false}
                  onEventResize={this.onEventResize}
                  onSelectSlot={this.onSelectSlot}
                  onSelectEvent={this.onSelectEvent}
                  dayPropGetter={(date) => {
                    if (moment(date).isSame(this.state.selectedDate)) {
                      return { className: 'rbc-slot-selected' };
                    }

                    return {};
                  }}
                  style={{ height: "60vh", maxHeight: "768px" }}
                />
              </div>
            </div>
          </div>
          <WithWizard
            render={({ next, previous, push, step, steps }) => (
              <div className="row">
                <div className="col">
                  {steps.indexOf(step) > 0 && (
                    <button className="btn btn-secondary btn-block" onClick={() => push(steps[steps.indexOf(step) - 1].id)}>
                      Back
                    </button>
                  )}
                </div>
                {
                  schema && schema.required ? this.state.events.length > 0 : true && (
                    <div className="col">
                      <button className="btn btn-primary btn-block" onClick={() => push(steps[steps.indexOf(step) + 1].id)}>
                        Next
                      </button>
                    </div>
                  )
                }
              </div>
            )}
          />

          <Modal
            isOpen={this.state.editEvent}
            shouldCloseOnOverlayClick={false}
            className={{
              base: 'modal-dialog',
              afterOpen: 'after-open',
              beforeClose: 'before-close'
            }}
            overlayClassName={{
              base: 'modal fade',
              afterOpen: 'show',
              beforeClose: ''
            }}
            >
            <div className="modal-content">
              <div className="modal-header text-left">
                <h5 className="modal-title">{ this.state.selectedEvent && this.state.selectedEvent.id ? 'Edit' : 'Add' } Date Range</h5>
              </div>
              {
                this.state.selectedEvent && (
                  <div className="modal-body">
                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="start">Start Date</label>
                      <Datetime
                        id="start"
                        name="start"
                        placeholder="Enter a start date..."
                        value={this.state.selectedEvent.start}
                        timeFormat={false}
                        onChange={(value) => {
                          const start = moment(value).startOf('day');
                          const { end } = this.state.selectedEvent;
                          this.setState({
                            dirty: true,
                            selectedEvent: {
                              ...this.state.selectedEvent,
                              start,
                              end: moment(end).isAfter(start) ? moment(end) : moment(start).endOf('day')
                            }
                          });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="end-date">End Date</label>
                      <Datetime
                        id="end"
                        name="end"
                        placeholder="Enter a end date..."
                        value={this.state.selectedEvent.end}
                        timeFormat={false}
                        onChange={(value) => {
                          const { start } = this.state.selectedEvent;
                          const end = moment(value).endOf('day');
                          this.setState({
                            dirty: true,
                            selectedEvent: {
                              ...this.state.selectedEvent,
                              start: moment(start).isBefore(end) ? moment(start) : moment(end).startOf('day'),
                              end
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                )
              }
              <div className="modal-footer">
                <button type="button" className="btn btn-default" onClick={(event) => {
                    this.setState({
                      dirty: false,
                      groupId: undefined,
                      editEvent: false,
                      selectedEvent: undefined
                    })
                  }}
                >
                  Cancel
                </button>
                {
                  this.state.selectedEvent && this.state.selectedEvent.id ? (
                    <button type="button" className="btn btn-danger" onClick={(event) => {
                        const { id } = this.state.selectedEvent;
                        this.setState({ confirmOnDelete: id });
                      }}
                    >
                      Delete
                    </button>
                  ) : null
                }
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={(event) => {
                    this.submit(
                      this.state.selectedEvent,
                      () => {
                        this.setState({
                          dirty: false,
                          selectedEvent: undefined,
                          editEvent: false
                        });
                      }
                    );
                  }}
                  disabled={!this.isModalComplete()}
                >
                  { this.state.selectedEvent && this.state.selectedEvent.id ? 'Edit' : 'Add' }
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={this.state.confirmOnDelete !== undefined}
            shouldCloseOnOverlayClick={false}
            className={{
              base: 'modal-dialog',
              afterOpen: 'after-open',
              beforeClose: 'before-close'
            }}
            overlayClassName={{
              base: 'modal fade',
              afterOpen: 'show',
              beforeClose: ''
            }}
            >
            <div className="modal-content">
              <div className="modal-header text-left">
                <h5 className="modal-title"><FontAwesomeIcon icon="exclamation-triangle" /> Are you sure?</h5>
              </div>
              <div className="modal-body">
                Are you sure you want to delete this item?
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" onClick={(event) => { this.remove(this.state.confirmOnDelete)}}>Delete</button>
                <button className="btn btn-primary" onClick={(event) => { this.setState({ confirmOnDelete: undefined }) }}>Cancel</button>
              </div>
            </div>
          </Modal>
        </div>
      );
    }

    return (
      <div>
        <h2>{form.title}</h2>
        <p>{form.description}</p>
        <div className="row">
          <div className="col">
            <Calendar
              selectable
              resizable
              localizer={localizer}
              defaultDate={new Date()}
              defaultView={Calendar.Views.MONTH}
              events={this.state.events}
              dayPropGetter={(date) => {
                if (moment(date).isSame(this.state.selectedDate)) {
                  return { className: 'rbc-slot-selected' };
                }

                return {};
              }}
              style={{ height: "60vh", maxHeight: "768px" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

FormCalendar.defaultProps = {
  active: true
}

FormCalendar.propTypes = {
  proposal: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  active: PropTypes.bool
};

export default FormCalendar;
