import * as log from 'loglevel';
import packageJson from '../package.json';
import remote from 'loglevel-plugin-remote';
import moment from 'moment';
import chalk from 'chalk';

const FLAG_DEVELOPING = false;
const FLAG_DEVELOPING_LOCAL = false;
const FLAG_PRODUCTION = !FLAG_DEVELOPING;
const FLAG_DEBUG = false;
const FLAG_VERBOSE = false;
const FLAG_REMOTE_LOGGING = false;

let API_URLS = [];
const API_BASE = 'api';
const API_VERSION = 'v1';
const API_PRODUCTION = [
  packageJson.config.production_url
    ? packageJson.config.production_url
    : 'https://lems.lanl.gov',
];
const API_DEVELOPMENT = ['https://experiments.lanl.gov'];
const API_LOCAL = ['http://localhost:8080'];
const API_OTHER = ['https://example.io/api', 'http://example.io/other'];

const LOG_COLORS = {
  TRACE: chalk.magenta,
  DEBUG: chalk.cyan,
  INFO: chalk.blue,
  WARN: chalk.yellow,
  ERROR: chalk.red,
};

if (FLAG_PRODUCTION) {
  API_URLS = [...API_PRODUCTION];
} else if (FLAG_DEVELOPING_LOCAL) {
  API_URLS = [...API_LOCAL, ...API_DEVELOPMENT, ...API_OTHER];
} else {
  API_URLS = [...API_DEVELOPMENT, ...API_LOCAL, ...API_OTHER];
}

const loglevelFormatJSON = (log) => ({
  level: log.level.label,
  message: log.message,
  stacktrace: log.stacktrace,
  timestamp: log.timestamp,
});

class Config {
  constructor() {}
  static get debug() {
    return FLAG_DEBUG;
  }
  static get verbose() {
    return FLAG_VERBOSE;
  }
  static get remoteLogging() {
    return FLAG_REMOTE_LOGGING;
  }
  static get urls() {
    return API_URLS;
  }
  static get logColors() {
    return LOG_COLORS;
  }
  static get utcOffset() {
    return -6;
  }
  static dateFormat(date) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
  }

  static get alertOptions() {
    return {
      position: 'bottom center', // you can also just use 'bottom center'
      transition: 'scale', // you can also just use 'scale'
      offset: '30px',
      timeout: 5000,
    };
  }

  static get url() {
    const token = localStorage.getItem('lansce-proposal-url');
    const host = token !== null ? token.replace(/"/g, '') : API_URLS[0];
    return host;
  }

  static updateUrl(value) {
    localStorage.setItem('lansce-proposal-url', value);
    return value;
  }

  static get loglevelOptions() {
    if (this.FLAG_DEBUG || this.FLAG_VERBOSE) {
      console.log('DEBUG||VERBOSE MODE: Configuring Logging.');
    }

    let bearerString =
      'Bearer ' + localStorage.getItem('lansce-proposal-token');
    let authString = bearerString.replace(/['"]+/g, '').replace(/[\n\r]+/g, '');

    return {
      url: this.api.logger,
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Headers': 'authorization, content-type',
        'Access-Control-Allow-Methods': 'POST',
        Vary: 'Access-Control-Request-Method',
        Vary: 'Access-Control-Request-Headers',
        Authorization: authString.replace(/['"]+/g, '').replace(/[\n\r]+/g, ''),
      },
      // token: localStorage.getItem('lansce-proposal-token'),
      // onUnauthorized: failedToken => {},
      timeout: 0,
      interval: 1000,
      level: 'trace',
      backoff: {
        multiplier: 2,
        jitter: 0.1,
        limit: 30000,
      },
      capacity: 500,
      stacktrace: {
        levels: ['trace', 'debug', 'warn', 'error'],
        depth: 1,
        excess: 0,
      },
      timestamp: () => new Date().toISOString(),
      format: loglevelFormatJSON,
    };
  }

  static get api() {
    const token = localStorage.getItem('lansce-proposal-url');
    const host = token !== null ? token.replace(/"/g, '') : this.urls[0];

    return {
      static: host,
      base: `${host}/${API_BASE}/`,
      auth: `${host}/${API_BASE}/login`,
      verify: `${host}/${API_BASE}/validate`,
      // User Management Enpoints
      changePassword: `${host}/register/changePassword`,
      forgotPassword: `${host}/register/forgotPassword`,
      positions: `${host}/register/positions`,
      register: `${host}/register/register`,
      resetPassword: `${host}/register/resetPassword`,
      usersPublic: `${host}/register/usersPublic`,
      // Data Endpoints
      committees: `${host}/${API_BASE}/${API_VERSION}/committees`,
      dateEvents: `${host}/${API_BASE}/${API_VERSION}/dateEvents`,
      facilities: `${host}/${API_BASE}/${API_VERSION}/facilities`,
      form: `${host}/${API_BASE}/${API_VERSION}/form`,
      formElementTypes: `${host}/${API_BASE}/${API_VERSION}/formElementTypes`,
      forms: `${host}/${API_BASE}/${API_VERSION}/forms`,
      formValues: `${host}/${API_BASE}/${API_VERSION}/formValues`,
      institutions: `${host}/${API_BASE}/${API_VERSION}/institutions`,
      instruments: `${host}/${API_BASE}/${API_VERSION}/instruments`,
      instrumentUsers: `${host}/${API_BASE}/${API_VERSION}/instrumentUsers`,
      logger: `${host}/${API_BASE}/${API_VERSION}/logger`,
      proposalCalls: `${host}/${API_BASE}/${API_VERSION}/proposalCalls`,
      proposalReviewPanels: `${host}/${API_BASE}/${API_VERSION}/proposalReviewPanels`,
      proposalReviews: `${host}/${API_BASE}/${API_VERSION}/proposalReviews`,
      proposals: `${host}/${API_BASE}/${API_VERSION}/proposals`,
      proposalUsers: `${host}/${API_BASE}/${API_VERSION}/proposalUsers`,
      scoreTypes: `${host}/${API_BASE}/${API_VERSION}/scoreTypes`,
      settings: `${host}/${API_BASE}/${API_VERSION}/settings`,
      userAgreements: `${host}/${API_BASE}/${API_VERSION}/userAgreements`,
      users: `${host}/${API_BASE}/${API_VERSION}/users`,
      values: `${host}/${API_BASE}/${API_VERSION}/proposals/values`,
      // 'instrument':           `${host}/${API_BASE}/${API_VERSION}/instrument/schema`,
      // 'schema':               `${host}/${API_BASE}/${API_VERSION}/schema`,
      // NIE Endpoints
      pii: `https://yellowcab.lanl.gov/send?28F4B63C`,
    };
  }

  static get validation() {
    return {
      email:
        /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
      password: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})/,
      phone:
        /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
      dob: /^.*$/,
      //ssn: /((?!000)(?!666)(?:[0-6]\d{2}|7[0-2][0-9]|73[0-3]|7[5-6][0-9]|77[0-2]))-((?!00)\d{2})-((?!0000)\d{4})$/,
      ssn: /^.*$/,
      pra: /^\d{3}-?\d{3}-?\d{3}$/,
      ppn: /^.*$/,
      pp_expiration: /^.*$/,
    };
  }
}

export default Config;

class Endpoint {
  constructor() {}
}
