import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import config from '../config';
import UserAPI from '../api/UserAPI';
import AlertContainer from '../components/utils/AlertContainer';

import * as log from 'loglevel';

const LoginInit = {
  email: '',
  password: ''
};

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Required.'),
  password: Yup.string()
    // .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm, 'Passwords must be at least 8 characters, contain 1 uppercase, 1 lowercase, and 1 number')
    .required('Required.')
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.debug = config.debug;
    this.alertContainer = undefined; // using REF defined in the render function
    this.history = props.history;
    this.state = {
      isLogginingIn: false,
      invalidCredentials: false,
      to: this.getNested(this.props.location, 'state') && typeof this.props.location.state.from.pathname !== 'undefined' ? this.props.location.state.from.pathname : '/lansce/app/manage'
    };

    this.handleLogin = this.handleLogin.bind(this);
  }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj)
  }

  handleLogin(values, actions) {
    UserAPI.login(values.email, values.password)
      .then(() => {
        actions.setSubmitting(false);
        const referrer = localStorage.getItem('lansce-proposal-referrer');
        if (this.debug) {
          log.debug("handleLogin [UserAPI.login] BEFORE | this %o",this);
          log.debug("handleLogin [UserAPI.login] BEFORE | localStorage %o",localStorage);
        }
        if (referrer === '/lansce/app/manage/login' || typeof referrer != "undefined" || referrer == "null") {
          if (this.debug) {
            log.debug("handleLogin [UserAPI.login] ref was login. setting to /lansce/app/manage");
          }
          this.history.push('/lansce/app/manage');
          localStorage.setItem('lansce-proposal-referrer', '/lansce/app/manage');
        }
        else {
          const newReferrer = UserAPI.referrer();
          if (this.debug) {
            log.debug("handleLogin [UserAPI.login] ref was login. setting to: %o",newReferrer);
          }
          this.history.push(newReferrer || '/lansce/app/manage');
          //localStorage.setItem('lansce-proposal-referrer', newReferrer);
        }
        if (this.debug) {
          log.debug("handleLogin [UserAPI.login] ref was login. setting to: %o",newReferrer);
          log.debug("handleLogin [UserAPI.login] AFTER | this %o",this);
          log.debug("handleLogin [UserAPI.login] AFTER | localStorage %o",localStorage);
        }
      }).catch((e) => {
        if (this.debug) {
          log.error("handleLogin [UserAPI.login] exception: %o",e);
          console.log(e);
        }
        actions.setSubmitting(false);
        this.alert('danger', e.message);
      });
  }


  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  render() {
    if (this.debug) {
      log.debug("render()");
    }
    return (
      <div className="container mt-5">
        <div className="row justify-content-center align-items-center vh-100">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <h3 className="text-center mb-4">LANSCE Proposal System</h3>
                <Formik
                  initialValues={LoginInit}
                  validationSchema={LoginSchema}
                  onSubmit={this.handleLogin}
                  render={({ errors, touched, isSubmitting }) => (
                    <Form>
                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="email">Email</label>
                        <Field name="email" disabled={isSubmitting} required autoComplete="email" className={`form-control${errors.email && touched.email ? ' is-invalid' : ''}`} />
                        <ErrorMessage name="email">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>

                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="password">Password</label>
                        <Field name="password" type="password" disabled={isSubmitting} required autoComplete="current-password" className={`form-control${errors.password && touched.password ? ' is-invalid' : ''}`} />
                        <ErrorMessage name="password">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>

                      <AlertContainer ref={(container) => { this.alertContainer = container; }} />

                      <hr />

                      <button type="submit" className="btn btn-block btn-primary mb-3" disabled={isSubmitting}><FontAwesomeIcon icon="sign-in-alt" /> Login</button>
                      
                      <div className="alert alert-info alert-dismissible fade show" role="alert">
                        <center>
                          Had an account in the old<br />
                          LANSCE Proposal Input System (LAPIS)?
                          <hr />
                          Click the <Link to="/lansce/forgot-password" disabled={isSubmitting} className="alert-link"><FontAwesomeIcon icon="unlock" /> Forgot your password?</Link> link<br />
                          to reset your password and access your account.
                        </center>
                      </div>

                      <ul className="list-unstyled text-center">
                        <li className="p-2"><Link to="/lansce/forgot-password" disabled={isSubmitting}><FontAwesomeIcon icon="unlock" /> Forgot your password?</Link></li>
                        <li className="p-2"><Link to="/lansce/signup" disabled={isSubmitting}><FontAwesomeIcon icon="user-plus" /> Need to create an account?</Link></li>
                      </ul>
                    </Form>
                  )}
                />
              </div>
            </div>

            <AlertContainer ref={(container) => { this.alertContainer = container; }} />
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
