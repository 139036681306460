import React, { Component } from 'react';
import * as Yup from 'yup';
import AlertContainer from '../../utils/AlertContainer';
import Modal from 'react-modal';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserAPI from '../../../api/UserAPI';
import PositionAPI from '../../../api/PositionAPI';
import config from '../../../config';

const UserAccountSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required.'),
	middleName: Yup.string()
    .required('Middle name is required.'),
  lastName: Yup.string()
    .required('Last name is required.'),
  zNumber: Yup.string().nullable(),
	phone: Yup.string()
		.matches(
			config.validation.phone,
			'Ex. 111-222-3333'
		)
    .required('You must enter a valid phone number.'),
  gender: Yup.string()
    .required('Gender is required.'),
  workPosition: Yup.object().shape({
    id: Yup.string().required('Position is required.'),
  }),
  homeAddress: Yup.object().shape({
    line1: Yup.string()
      .required('Address is required.'),
    city: Yup.string()
      .required('City is required.'),
    stateOrProvince: Yup.string()
      .required('State or Province is required.'),
    country: Yup.string()
      .required('Country is required.'),
    postalCode: Yup.string()
      .required('Zip Code is required.'),
  }),
  workInstitution: Yup.string()
    .required('Institution is required.'),
  workDepartment: Yup.string()
    .required('Department is required.'),
  workAreaOfExpertise: Yup.string()
    .required('Area of Expertise is required.'),
  workPhone: Yup.string()
    .required('Phone is required.'),
  workEmail: Yup.string()
    .required('Department is required.'),
  workAddress: Yup.object().shape({
    line1: Yup.string()
      .required('Address is required.'),
    city: Yup.string()
      .required('City is required.'),
    stateOrProvince: Yup.string()
      .required('State or Province is required.'),
    country: Yup.string()
      .required('Country is required.'),
    postalCode: Yup.string()
      .required('Zip Code is required.'),
  }),
  previousWorkInstitution:     Yup.string().nullable(),
  previousWorkDepartment:      Yup.string().nullable(),
  previousWorkAreaOfExpertise: Yup.string().nullable(),
  previousWorkPhone:           Yup.string().nullable(),
  previousWorkEmail:           Yup.string().nullable(),
  previousWorkAddress: Yup.object().shape({
    line1:           Yup.string().nullable(),
    line2:           Yup.string().nullable(),
    city:            Yup.string().nullable(),
    stateOrProvince: Yup.string().nullable(),
    country:         Yup.string().nullable(),
    postalCode:      Yup.string().nullable(),
  })
});

class UpdateAccountModal extends Component {
  constructor(props){
    super(props);
    this.alertContainer; //using REF defined in the render function
    this.state = {
      isOpen: true,
      success: false,
      positions: [],
      user: undefined,
      ...this.props.location.state //merge state if there is any
    }

    this.back = this.back.bind(this);
  }

  componentDidMount() {
    // Grab the user in case we dont have it from route state
    if (!this.state.user || !this.state.positions.length) {
      Promise.all([
        PositionAPI.get(),
        UserAPI.account()
      ]).then(([positionResponse, userResponse]) => {
        this.setState({
          positions: positionResponse.data,
          user: userResponse.data
        });
      }).catch(e => {
        this.alert('danger', e.message);
      });
    }
  }

  back() {
    this.setState(
      {
        isOpen: false
      },
      this.props.history.goBack
    );
  }

  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  render(){
    const { isOpen, user, success } = this.state;

    let body = null;

    if (user && !success) {
      body = (
        <Formik
          initialValues={user}
          validationSchema={UserAccountSchema}
          onSubmit={(values) => {
            UserAPI.updateAccount(values)
              .then((results) => {
                this.setState({
                  success: true
                });
              })
              .catch(e => {
                this.alert('danger', e.message);
              });
          }}
          render={({ values, actions, errors, touched, isSubmitting, setStatus, status }) => (
            <div className="modal-content">
              <div className="modal-header text-left">
                <h5 className="modal-title">
                  <FontAwesomeIcon icon="exclamation-triangle" /> Update your account information
                </h5>
              </div>
              <div className="alert alert-danger">
                <center>
                  <p>
                    Your name in this system MUST match your legal, documented name, exactly.
                  </p>
                </center>
              </div>
              <Form>
                <div className="modal-body">
                  <div className="form-row">
                    <div className="form-group col">
                      <label className="font-weight-bold" htmlFor="firstName">First Name <strong className="text-danger">*</strong></label>
                      <Field name="firstName" required autoComplete="given-name" className={`form-control${errors.firstName && touched.firstName ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="firstName">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>

                    <div className="form-group col">
                      <label className="font-weight-bold" htmlFor="middleName">Middle Name <strong className="text-danger">*</strong></label>
                      <Field name="middleName" required autoComplete="middle-name" className={`form-control${errors.middleName && touched.middleName ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="middleName">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>

                    <div className="form-group col">
                      <label className="font-weight-bold" htmlFor="lastName">Last Name <strong className="text-danger">*</strong></label>
                      <Field name="lastName" required autoComplete="family-name" className={`form-control${errors.lastName && touched.lastName ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="lastName">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-4 mb-0">
                      <label className="font-weight-bold" htmlFor="zNumber">Z Number</label>
                      <Field name="zNumber" disabled className={`form-control${errors.zNumber && touched.zNumber ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="zNumber">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>

                    <div className="form-group col-4 mb-0">
                      <label className="font-weight-bold" htmlFor="phone">Phone Number <strong className="text-danger">*</strong></label>
                      <Field name="phone" required className={`form-control${errors.phone && touched.phone ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="phone">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>

                    <div className="form-group col-4 mb-0">
                      <label className="font-weight-bold" htmlFor="gender">Gender <strong className="text-danger">*</strong></label>
                      <Field component="select" name="gender" required className={`form-control${errors.gender && touched.gender ? ' is-invalid' : ''}`}>
                        <option value=""></option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                      </Field>
                      <ErrorMessage name="gender">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col">
                      <label className="font-weight-bold" htmlFor="lastName">ORCiD</label>
                      <Field name="orcid" autoComplete="orcid" className={`form-control${errors.orcid && touched.orcid ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="orcid">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                  </div>

                  <hr className="my-4"/>
                  <center>
                    <h2><FontAwesomeIcon icon="university" /> Work / University Information</h2>
                  </center>

                  <div className="row">
                    <div className="form-group col-6">
                      <label className="font-weight-bold" htmlFor="workInstitution">Institution <strong className="text-danger">*</strong></label>
                      <Field name="workInstitution" required className={`form-control${errors.workInstitution && touched.workInstitution ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="workInstitution">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                    <div className="form-group col-6">
                      <label className="font-weight-bold" htmlFor="department">Department <strong className="text-danger">*</strong></label>
                      <Field name="workDepartment" required className={`form-control${errors.workDepartment && touched.workDepartment ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="workDepartment">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      <label className="font-weight-bold" htmlFor="workPosition.id">Position <strong className="text-danger">*</strong></label>
                      <Field name="workPosition.id" component="select" required className={`form-control${errors.workPosition && touched.workPosition && errors.workPosition.id && touched.workPosition.id ? ' is-invalid' : ''}`} >
                        <option value=""></option>
                        {
                          this.state.positions.map((position) => (
                            <option value={position.id}>{position.name}</option>
                          ))
                        }
                      </Field>
                      <ErrorMessage name="position.id">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                    <div className="form-group col-6">
                      <label className="font-weight-bold" htmlFor="workAreaOfExpertise">Area of Expertise <strong className="text-danger">*</strong></label>
                      <Field name="workAreaOfExpertise" required className={`form-control${errors.workAreaOfExpertise && touched.workAreaOfExpertise ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="workAreaOfExpertise">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6 mb-0">
                      <label className="font-weight-bold" htmlFor="workPhone">Phone <strong className="text-danger">*</strong></label>
                      <Field name="workPhone" required className={`form-control${errors.workPhone && touched.workPhone ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="workPhone">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                    <div className="form-group col-6 mb-0">
                      <label className="font-weight-bold" htmlFor="workEmail">Email <strong className="text-danger">*</strong></label>
                      <Field name="workEmail" required className={`form-control${errors.workEmail && touched.workEmail ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="workEmail">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                  </div>
                  <br />
                  <center>
                    <h3>Address</h3>
                  </center>
                  <div className="row">
                    <div className="form-group col">
                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="workAddress.line1">Address Line 1<strong className="text-danger">*</strong></label>
                        <Field name="workAddress.line1" required className={`form-control${errors.workAddress && touched.workAddress && errors.workAddress.line1 && touched.workAddress.line1 ? ' is-invalid' : ''}`} />
                        <ErrorMessage name="workAddress.line1">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="workAddress.line2">Address Line 2</label>
                        <Field name="workAddress.line2" className={`form-control${errors.workAddress && touched.workAddress && errors.workAddress.line2 && touched.workAddress.line2 ? ' is-invalid' : ''}`} />
                        <ErrorMessage name="workAddress.line2">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      <label className="font-weight-bold" htmlFor="workAddress.city">City <strong className="text-danger">*</strong></label>
                      <Field name="workAddress.city" required className={`form-control${errors.workAddress && touched.workAddress && errors.workAddress.city && touched.workAddress.city ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="workAddress.city">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                    <div className="form-group col-6">
                      <label className="font-weight-bold" htmlFor="workAddress.stateOrProvince">State <strong className="text-danger">*</strong></label>
                      <Field name="workAddress.stateOrProvince" required className={`form-control${errors.workAddress && touched.workAddress && errors.workAddress.stateOrProvince && touched.workAddress.stateOrProvince ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="workAddress.stateOrProvince">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-6 mb-0">
                      <label className="font-weight-bold" htmlFor="workAddress.country">Country <strong className="text-danger">*</strong></label>
                      <Field name="workAddress.country" required className={`form-control${errors.workAddress && touched.workAddress && errors.workAddress.country && touched.workAddress.country ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="workAddress.country">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                    <div className="form-group col-6">
                      <label className="font-weight-bold" htmlFor="workAddress.postalCode">Zip <strong className="text-danger">*</strong></label>
                      <Field name="workAddress.postalCode" required className={`form-control${errors.workAddress && touched.workAddress && errors.workAddress.postalCode && touched.workAddress.postalCode ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="workAddress.postalCode">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                  </div>

                  {
                    user.isForeign ? (
                      <div>
                        <hr className="my-4"/>
                        <center>
                          <h2><FontAwesomeIcon icon="university" /> Previous Home Country Affiliation</h2>
                        </center>

                        <div className="row">
                          <div className="form-group col-6">
                            <label className="font-weight-bold" htmlFor="previousWorkInstitution">Institution</label>
                            <Field name="previousWorkInstitution" className={`form-control${errors.previousWorkInstitution && touched.previousWorkInstitution ? ' is-invalid' : ''}`} />
                            <ErrorMessage name="previousWorkInstitution">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                          </div>
                          <div className="form-group col-6">
                            <label className="font-weight-bold" htmlFor="previousWorkDepartment">Department</label>
                            <Field name="previousWorkDepartment" className={`form-control${errors.previousWorkDepartment && touched.previousWorkDepartment ? ' is-invalid' : ''}`} />
                            <ErrorMessage name="previousWorkDepartment">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-6">
                            <label className="font-weight-bold" htmlFor="previousWorkPosition.id">Position</label>
                            <Field name="previousWorkPosition.id" component="select" className={`form-control${errors.previousWorkPosition && touched.previousWorkPosition && errors.previousWorkPosition.id && touched.previousWorkPosition.id ? ' is-invalid' : ''}`} >
                              <option value=""></option>
                              {
                                this.state.positions.map((position) => (
                                  <option value={position.id}>{position.name}</option>
                                ))
                              }
                            </Field>
                            <ErrorMessage name="position.id">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                          </div>
                          <div className="form-group col-6">
                            <label className="font-weight-bold" htmlFor="previousWorkAreaOfExpertise">Area of Expertise</label>
                            <Field name="previousWorkAreaOfExpertise" className={`form-control${errors.previousWorkAreaOfExpertise && touched.previousWorkAreaOfExpertise ? ' is-invalid' : ''}`} />
                            <ErrorMessage name="previousWorkAreaOfExpertise">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-6 mb-0">
                            <label className="font-weight-bold" htmlFor="previousWorkPhone">Phone (Including Country Code)</label>
                            <Field name="previousWorkPhone" className={`form-control${errors.previousWorkPhone && touched.previousWorkPhone ? ' is-invalid' : ''}`} />
                            <ErrorMessage name="previousWorkPhone">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                          </div>
                        </div>
                        <br />
                        <center>
                          <h3>Address</h3>
                        </center>
                        <div className="row">
                          <div className="form-group col">
                            <div className="form-group">
                              <label className="font-weight-bold" htmlFor="previousWorkAddress.line1">Address Line 1</label>
                              <Field name="previousWorkAddress.line1" className={`form-control${errors.previousWorkAddress && touched.previousWorkAddress && errors.previousWorkAddress.line1 && touched.previousWorkAddress.line1 ? ' is-invalid' : ''}`} />
                              <ErrorMessage name="previousWorkAddress.line1">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col">
                            <div className="form-group">
                              <label className="font-weight-bold" htmlFor="previousWorkAddress.line2">Address Line 2</label>
                              <Field name="previousWorkAddress.line2" className={`form-control${errors.previousWorkAddress && touched.previousWorkAddress && errors.previousWorkAddress.line2 && touched.previousWorkAddress.line2 ? ' is-invalid' : ''}`} />
                              <ErrorMessage name="previousWorkAddress.line2">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-6">
                            <label className="font-weight-bold" htmlFor="previousWorkAddress.city">City</label>
                            <Field name="previousWorkAddress.city" className={`form-control${errors.previousWorkAddress && touched.previousWorkAddress && errors.previousWorkAddress.city && touched.previousWorkAddress.city ? ' is-invalid' : ''}`} />
                            <ErrorMessage name="previousWorkAddress.city">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                          </div>
                          <div className="form-group col-6">
                            <label className="font-weight-bold" htmlFor="previousWorkAddress.stateOrProvince">State</label>
                            <Field name="previousWorkAddress.stateOrProvince" className={`form-control${errors.previousWorkAddress && touched.previousWorkAddress && errors.previousWorkAddress.stateOrProvince && touched.previousWorkAddress.stateOrProvince ? ' is-invalid' : ''}`} />
                            <ErrorMessage name="previousWorkAddress.stateOrProvince">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-6 mb-0">
                            <label className="font-weight-bold" htmlFor="previousWorkAddress.country">Country</label>
                            <Field name="previousWorkAddress.country" className={`form-control${errors.previousWorkAddress && touched.previousWorkAddress && errors.previousWorkAddress.country && touched.previousWorkAddress.country ? ' is-invalid' : ''}`} />
                            <ErrorMessage name="previousWorkAddress.country">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                          </div>
                          <div className="form-group col-6">
                            <label className="font-weight-bold" htmlFor="previousWorkAddress.postalCode">Zip</label>
                            <Field name="previousWorkAddress.postalCode" className={`form-control${errors.previousWorkAddress && touched.previousWorkAddress && errors.previousWorkAddress.postalCode && touched.previousWorkAddress.postalCode ? ' is-invalid' : ''}`} />
                            <ErrorMessage name="previousWorkAddress.postalCode">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }

                  <hr className="my-4"/>
                  <center>
                    <h2><FontAwesomeIcon icon="home" /> Home Address</h2>
                  </center>
                  <div className="row">
                    <div className="form-group col">
                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="homeAddress.line1">Address Line 1<strong className="text-danger">*</strong></label>
                        <Field name="homeAddress.line1" required className={`form-control${errors.homeAddress && touched.homeAddress && errors.homeAddress.line1 && touched.homeAddress.line1 ? ' is-invalid' : ''}`} />
                        <ErrorMessage name="homeAddress.line1">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="homeAddress.line2">Address Line 2</label>
                        <Field name="homeAddress.line2" className={`form-control${errors.homeAddress && touched.homeAddress && errors.homeAddress.line2 && touched.homeAddress.line2 ? ' is-invalid' : ''}`} />
                        <ErrorMessage name="homeAddress.line2">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      <label className="font-weight-bold" htmlFor="homeAddress.city">City <strong className="text-danger">*</strong></label>
                      <Field name="homeAddress.city" required className={`form-control${errors.homeAddress && touched.homeAddress && errors.homeAddress.city && touched.homeAddress.city ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="homeAddress.city">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                    <div className="form-group col-6">
                      <label className="font-weight-bold" htmlFor="homeAddress.stateOrProvince">State <strong className="text-danger">*</strong></label>
                      <Field name="homeAddress.stateOrProvince" required className={`form-control${errors.homeAddress && touched.homeAddress && errors.homeAddress.stateOrProvince && touched.homeAddress.stateOrProvince ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="homeAddress.stateOrProvince">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-6 mb-0">
                      <label className="font-weight-bold" htmlFor="homeAddress.country">Country <strong className="text-danger">*</strong></label>
                      <Field name="homeAddress.country" required className={`form-control${errors.homeAddress && touched.homeAddress && errors.homeAddress.country && touched.homeAddress.country ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="homeAddress.country">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                    <div className="form-group col-6">
                      <label className="font-weight-bold" htmlFor="homeAddress.postalCode">Zip <strong className="text-danger">*</strong></label>
                      <Field name="homeAddress.postalCode" required className={`form-control${errors.homeAddress && touched.homeAddress && errors.homeAddress.postalCode && touched.homeAddress.postalCode ? ' is-invalid' : ''}`} />
                      <ErrorMessage name="homeAddress.postalCode">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-default" type="submit">Update your information</button>
                  <button
                    className="btn btn-primary"
                    onClick={this.back}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          )}
        />
      );
    } else {
      body = (
        <div className="modal-content">
          <div className="modal-header text-left">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="exclamation-triangle" /> Update your account information
            </h5>
          </div>
          <div className="modal-body">
            <p className="text-center mb-0"><strong>Your account was successfully updated.</strong></p>
          </div>
          <div className="modal-footer">
            <button className="btn btn-success" onClick={this.back}>Ok</button>
          </div>
        </div>
      );
    }

    return (
      <Modal
        isOpen={this.state.isOpen}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal-dialog modal-lg',
          afterOpen: 'after-open',
          beforeClose: 'before-close'
        }}
        overlayClassName={{
          base: 'modal fade',
          afterOpen: 'show',
          beforeClose: ''
        }}
      >
        { body }
      </Modal>
    );
  }
}

export default UpdateAccountModal;