import axios from 'axios';

import history from './history';
import config from '../config';

import UserAPI from './UserAPI';

const apiPath = config.api.proposals;

function url(...rest) {
  return rest.map(val => `${val}`.replace(/\/$/, '')).join('/');
}

class ValuesAPI {
  static get(proposalId, formId) {
    return axios({
      method: 'get',
      url: url(apiPath, proposalId, 'values'),
      params: { formId },
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static post(proposalId, formId, groupId, data) {
    return axios({
      method: 'post',
      url: url(apiPath, proposalId, 'values'),
      params: { formId, groupId },
      data,
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static put(proposalId, formId, groupId, data) {
    return axios({
      method: 'put',
      url: url(apiPath, proposalId, 'values'),
      params: { formId, groupId },
      data,
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static delete(proposalId, formId, groupId) {
    return axios({
      method: 'delete',
      url: url(apiPath, proposalId, 'values'),
      params: { formId, groupId },
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static postGroup(data) {
    return axios({
      method: 'post',
      url: url(config.api.formValues),
      params: { group: true },
      data,
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static putGroup(data) {
    return axios({
      method: 'put',
      url: url(config.api.formValues),
      params: { group: true },
      data,
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }
}

export default ValuesAPI;
