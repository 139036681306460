import React, { Component } from 'react';
import * as Yup from 'yup';
import AlertContainer from '../../utils/AlertContainer';
import Modal from 'react-modal';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserAPI from '../../../api/UserAPI';
import config from '../../../config';

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().required('You must confirm your current password.'),
  newPassword: Yup.string()
  .matches(
      config.validation.password,
      'Passwords must be at least 8 characters and contain 4 character classes (uppercase, lowercase, number, and special characters).'
    )
    .required('You must enter a password.'),
  newPassword2: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], "Passwords don't match")
    .required('You must confirm your password.'),
});

class ResetPassword extends Component {
  constructor(props){
    super(props);
    this.alertContainer; //using REF defined in the render function
    this.state = {
      isOpen: true,
      success: false
    }

    this.back = this.back.bind(this);
  }

  back() {
    this.setState(
      {
        isOpen: false
      },
      this.props.history.goBack
    );
  }

  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  render(){
    const { isOpen } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal-dialog modal-lg',
          afterOpen: 'after-open',
          beforeClose: 'before-close'
        }}
        overlayClassName={{
          base: 'modal fade',
          afterOpen: 'show',
          beforeClose: ''
        }}
      >
        {
          !this.state.success ? (
            <Formik
              validationSchema={ResetPasswordSchema}
              initialValues={{}}
              onSubmit={(values) => {
                const { newPassword2, ...data } = values;

                UserAPI.resetPassword(data)
                  .then(() => {
                    this.setState({
                      success: true
                    })
                  })
                  .catch(e => {
                    this.alert('danger', e.message);
                  });
              }}
              render={({ values, actions, errors, touched, isSubmitting, setStatus, status }) => (
                <div className="modal-content">
                  <div className="modal-header text-left">
                    <h5 className="modal-title">
                      <FontAwesomeIcon icon="exclamation-triangle" /> Reset your password
                    </h5>
                  </div>
                  <Form disabled={isSubmitting}>
                    <div className="modal-body">
                      <AlertContainer ref={(container) => { this.alertContainer = container; }} />
                      <div className="row">
                        <div className="form-group col">
                          <label className="font-weight-bold" htmlFor="password">Current Password <strong className="text-danger">*</strong></label>
                          <Field name="password" type="password" required autoComplete="current-password" className={`form-control${errors.password && touched.password ? ' is-invalid' : ''}`} disabled={isSubmitting}/>
                          <ErrorMessage name="password">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col">
                          <label className="font-weight-bold" htmlFor="newPassword">New Password <strong className="text-danger">*</strong></label>
                          <Field name="newPassword" type="password" required autoComplete="current-password" className={`form-control${errors.newPassword && touched.newPassword ? ' is-invalid' : ''}`} disabled={isSubmitting}/>
                          <ErrorMessage name="newPassword">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col">
                          <label className="font-weight-bold" htmlFor="newPassword2">Confirm New Password <strong className="text-danger">*</strong></label>
                          <Field name="newPassword2" type="password" required autoComplete="current-password" className={`form-control${errors.newPassword2 && touched.newPassword2 ? ' is-invalid' : ''}`} disabled={isSubmitting}/>
                          <ErrorMessage name="newPassword2">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button className="btn btn-default" type="submit" disabled={isSubmitting}>Reset Password</button>
                      <button className="btn btn-primary" onClick={this.back}>Cancel</button>
                    </div>
                  </Form>
                </div>
              )}
            />
          ) : (
            <div className="modal-content">
              <div className="modal-header text-left">
                <h5 className="modal-title">
                  <FontAwesomeIcon icon="exclamation-triangle" /> Reset your password
                </h5>
              </div>
              <div className="modal-body">
                <p className="text-center mb-0"><strong>Your password was successfully reset</strong>, you will need to use your new password the next time you login.</p>
              </div>
              <div className="modal-footer">
                <button className="btn btn-success" onClick={this.back}>Ok</button>
              </div>
            </div>
          )
        }
        
      </Modal>
    );
  }
}

export default ResetPassword;