import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGroup from './FormGroup';
import _ from 'lodash';

import BooleanElement from './elements/BooleanElement';
import ChecklistElement from './elements/ChecklistElement';
import DateElement from './elements/DateElement';
import InputElement from './elements/InputElement';
import MultiselectElement from './elements/MultiselectElement';
import RadiolistElement from './elements/RadiolistElement';
import SelectElement from './elements/SelectElement';
import TextareaElement from './elements/TextareaElement';
import TypeaheadElement from './elements/TypeaheadElement';
import FileMultipleElement from './elements/FileMultipleElement';

class FormElement extends Component {
  constructor(props) {
    super(props);
    // this.update = this.props.update.bind(this);
  }

  componentWillMount() {
    this.id = _.uniqueId('dynamic-form-');
  }

  render() {
    const { 
      active = true,
      schema,
      data,
      update
    } = this.props;

    const element = schema;

    const components  = {
      boolean: BooleanElement,
      checkbox: BooleanElement,
      checklist: ChecklistElement,
      date: DateElement,
      'datetime-local': DateElement,
      input: InputElement,
      multiselect: MultiselectElement,
      radiolist: RadiolistElement,
      select: SelectElement,
      textarea: TextareaElement,
      typeahead: TypeaheadElement,
      'file-multiple': FileMultipleElement
    };

    if (components.hasOwnProperty(element.type)) {
      const Element = components[element.type];
      return <Element {...{element, data, update, active}} />;
    }

    return <div className="alert alert-danger"><p>ERROR: Unkown type ({element.type})</p><p>{JSON.stringify(element, null, '  ')}</p></div>;
  }
}

FormElement.defaultProps = {
  active: true
};

FormElement.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  active: PropTypes.bool
};

export default FormElement;
