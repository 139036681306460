import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import UserAPI from '../api/UserAPI';

const ForgotPasswordSchema = Yup.object().shape({
	email: Yup.string()
		.required('Email is required.')
    .email('Please enter a valid email.')
});

class Signup extends Component {
  constructor(props) {
    super(props);

		this.state = {
			email: '',
		};

    this.alertContainer = undefined; // using REF defined in the render function
    this.history = props.history;

    this.handleSignup  = this.handleSignup.bind(this);
  }

  handleSignup(values, actions) {
		UserAPI.forgotPassword(values)
			.then(() => {
				this.setState(values);
				this.history.push(`${this.props.match.url}/success`);
			})
			.catch((e) => {
				this.history.push(`${this.props.match.url}/failure`);
				this.setState({ err: e.message || ''})
			});
  }

  render() {
    return (
      <div className="container mt-5">
				<div className="row justify-content-center align-items-center vh-100">
					<div className="col-lg-6">
						<Switch>
							<Route path={`${this.props.match.path}/success`}>
								<div className="card">
									<div className="card-body">
										<p>Please check your email <strong>({this.state.email})</strong> for your password reset list.</p>
										<div className="row">
											<div className="col text-center">
												<Link to="/login" className="btn btn-block btn-default"><FontAwesomeIcon icon="arrow-left" /> Back</Link>
											</div>
										</div>
									</div>
								</div>
							</Route>
							<Route path={`${this.props.match.path}/failure`}>
								<div className="card">
									<div className="card-body text-center">
										<p>{this.state.err}</p>
										<div className="row">
											<div className="col text-center">
												<Link to="/login" className="btn btn-block btn-default"><FontAwesomeIcon icon="arrow-left" /> Back</Link>
											</div>
										</div>
									</div>
								</div>
							</Route>
							<Route path={`${this.props.match.path}`}>
								<div className="card">
		              <div className="card-body">
										<Formik
											initialValues={this.state}
											validationSchema={ForgotPasswordSchema}
											onSubmit={this.handleSignup}
											render={({ values, actions, errors, touched, isSubmitting }) => (
												<Form>
													<p>Please enter the email assoicated with your account and we will send a password reset link.</p>
													<div className="form-group">
														<label className="font-weight-bold" htmlFor="email">Email <strong className="text-danger">*</strong></label>
														<Field name="email" required autoComplete="email" className={`form-control${errors.email && touched.email ? ' is-invalid' : ''}`} />
														<ErrorMessage name="email">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
													</div>

													<div className="row mb-3">
														<div className="col">
															<button type="submit" className="btn btn-block btn-primary" disabled={isSubmitting}><FontAwesomeIcon icon="check" /> Submit</button>
														</div>
													</div>
												</Form>
											)}
										/>

										<div className="row">
											<div className="col text-center">
												<Link to="/login" className="btn btn-block btn-default"><FontAwesomeIcon icon="arrow-left" /> Back</Link>
											</div>
										</div>
									</div>
								</div>
							</Route>
						</Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
