import React from 'react';
import produce from 'immer';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Alert from './Alert';

const Fade = ({ children, ...props }) => (
  <CSSTransition
    {...props}
    timeout={1000}
    classNames="fade"
  >
    {children}
  </CSSTransition>
);

class AlertContainer extends React.Component {
  constructor(props) {
    super(props);
    this.count = 0;
    this.delay = this.props.delay || 3000;
    this.state = {
      visible: true,
      alerts: []
    };

    this.handleRemove = this.handleRemove.bind(this);
  }

  componentWillUnmount() {
    this.setState({ alerts: [] });
  }

  handleRemove(alert) {
    this.setState({
      alerts: this.state.alerts.filter(item => item.props.id !== alert.props.id)
    });
  }

  triggerAlert(type, message) {
    this.setState(
      produce(draft => {
        draft.alerts.push(
          <Alert
            key={this.count}
            id={this.count}
            type={type}
            message={message}
            delay={this.delay}
            onRemove={this.handleRemove}
          />
        );
      })
    );

    this.count += 1;
  }

  render() {
    return (
      <TransitionGroup className="alert-container">
        {
          this.state.alerts.map((alert, i) => (
            <Fade key={i}>
              {alert}
            </Fade>
          ))
        }
      </TransitionGroup>
    );
  }
}

export default AlertContainer;
