import axios from 'axios';

import history from './history';
import config from '../config';

import UserAPI from './UserAPI';

const apiPath = config.api.form;

class FormAPI {
  static create(proposalId, formId, form) {
    return axios({
      method: 'post',
      url: apiPath,
      params: { proposalId, formId },
      data: {
        form
      },
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static read(formId) {
    return axios({
      method: 'get',
      url: apiPath,
      params: { formId },
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static update(formId, form) {
    return axios({
      method: 'put',
      url: apiPath,
      params: { formId },
      data: {
        form
      },
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static destroy(formId) {
    return axios({
      method: 'delete',
      url: apiPath,
      params: { formId },
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }
}

export default FormAPI;
