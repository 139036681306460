import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import HeaderSort from './HeaderSort';

class Table extends Component {
  constructor(props) {
    super(props);
    this.match = this.props.match;
    this.state = {
      sort: props.sort || props.properties[0].key,
      order: props.order
    };
    this.handleSort = this.handleSort.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.rowTemplate = this.rowTemplate.bind(this);
  }

  handleSort(params) {
    this.setState(params, () => {
      this.props.onSort(this.state);
    });
  }

  formatDate(date) {
    const commentDate = moment(date);
    const daysPast = moment().diff(commentDate, 'days');
    return (daysPast < 1) ? commentDate.fromNow() : commentDate.format('MMM D, YYYY');
  }

  rowTemplate(item, settings) {
    let value = '';
    let content = null;
    
    if(Object.hasOwnProperty.call(settings, 'value')){
      try {
        value = settings.value(item);
      } catch (e) {
        value = '';
      }
    } else {
      value = Object.hasOwnProperty.call(item, settings.key) ? item[settings.key] : '';
    }

    if (settings.type === 'date') {
      value = this.formatDate(value);
    } else if (settings.type === 'status') {
      if (settings.badges && settings.badges[value]) {
        value = <span className={`${settings.badges[value] || ''} text-capitalize`}>{value}</span>;
      } else {
        value = <span className="badge badge-light text-capitalize">{value}</span>;
      }
    }

    if(Object.hasOwnProperty.call(settings, 'url')) {
      content = (
        <Link
          href
          to={{
            pathname: settings.url(this.match, item),
            state: item
          }}
        >
          { value ? value : 'Undefined' }
        </Link>
      );
    } else {
      content = value;
    }

    return (<td key={`${item.id}-${settings.key}`}>{content}</td>);
  }

  render() {
    /*
      this.props.properties
      this.props.list
      properties
        [
          {
            title: string
            key: string,
            value: function,
            url: function
            type: key || date || string
          }
        ]
    */
  
    const { properties } = this.props;

    return (
      <table className="table table-responsive">
        <thead>
          <tr>
            {
              properties.map(({key, label}) => (
                <HeaderSort key={key} dataKey={key} label={label} onSort={this.handleSort} />
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            (this.props.list || []).map((item) => {
              const active = Object.hasOwnProperty.call(item, 'active') && !item.active ? 'bg-deleted' : '';
              return (
                <tr key={item.id} className={active}>
                  {
                    properties.map(property => this.rowTemplate(item, property))
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    );
  }
}

Table.defaultProps = {
  list: [],
  sort: undefined,
  order: 'asc'
};

Table.propTypes = {
  properties: PropTypes.array.isRequired,
  list: PropTypes.array,
  sort: PropTypes.string,
  order: PropTypes.string
};

export default Table;
