import React, { Component } from 'react';
import axios from 'axios';
import UserAPI from '../../api/UserAPI';

class AuthLink extends Component {
  constructor(props) {
    super(props);
    this.trigger = this.trigger.bind(this);
  }

  trigger(event) {
    const { url } = this.props;
    event.preventDefault();
    axios({
      method: 'get',
      url: url,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    })
    .then((response) => {
      const url            = window.URL.createObjectURL(new Blob([response.data]));
      const link           = document.createElement('a');
      const fileNameHeader = response.headers["content-disposition"];
      const fileName       = fileNameHeader.substring(fileNameHeader.lastIndexOf("=")+1);

      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  }

  render() {
    const { url, target="_blank", children } = this.props;
    return (
      <a href="" target={target} onClick={this.trigger}>{children}</a>
    );
  }
}

export default AuthLink;
