import axios from 'axios';
import * as log from 'loglevel';

import history from './history';
import config from '../config';

const apiPath = config.api.auth;
const isDebug = config.debug;

function url(...rest) {
  return rest.map(val => `${val}`.replace(/\/$/, '')).join('/');
}

axios.interceptors.response.use(function (response) {
    // Do something with response data
    if (isDebug) {
      log.debug("axios.interceptors.response response %o",response);
    }
    return response;
  }, function (error) {
    // Set the referrer url
    const referrer = window.location.pathname;
    if (isDebug) {
      log.debug("axios.interceptors.response referrer %o",referrer);
      log.debug("axios.interceptors.response error %o",error);
    }
    // Do something with response error
    if(error.response && error.response.status === 401){
      UserAPI.logout(false).then(() => {
        // Save the referrer on auth denied or bounce
        if (isDebug) {
          log.debug("axios.interceptors.response 401 %o",referrer);
          log.debug("axios.interceptors.response 401 %o",localStorage);
        }
        if(referrer !== '/lansce/login'){
          localStorage.setItem('lansce-proposal-referrer', '/lansce/login');
          if (isDebug) {
            log.debug("axios.interceptors.response 401 [ referrer !== /lansce/login ] referrer %o",referrer);
            log.debug("axios.interceptors.response 401 [ referrer !== /lansce/login ] localStorage %o",localStorage);
          }
        }
      });
    }
    const defaultMessage = { data: { message: 'There was an issue communicating with the server.'} };
    const { data } = error.response || defaultMessage;
    if (isDebug) {
      log.debug("axios.interceptors.response [end] data %o",data);
      log.debug("axios.interceptors.response [end] error %o",error);
    }
    return Promise.reject({ ...data, status: error.response && error.response.status ? {response: error.response, status: error.response.status} : 400 });
  });

class UserAPI {
  static get ROLES() {
    return {
      user: 'ROLE_USER',
      manager: 'ROLE_MANAGER',
      userOffice: 'ROLE_USER_OFFICE',
      instrumentAssistant: 'ROLE_INSTRUMENT_ASSISTANT',
      instrumentScientist: 'ROLE_INSTRUMENT_SCIENTIST',
      admin: 'ROLE_ADMIN'
    };
  }

  static account() {
    const { id } = UserAPI.user();

    return axios({
      method: 'get',
      url: url(config.api.users, id+''),
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static get(searchParams = undefined) {
    return axios({
      method: 'get',
      url: config.api.users,
      params: searchParams,
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static getPublic(searchParams = undefined) {
    return axios({
      method: 'get',
      url: config.api.usersPublic,
      params: searchParams,
      responseType: 'json'
    });
  }

  static signup(data) {
    return axios({
      method: 'post',
      url: config.api.register,
      data,
      responseType: 'json'
    })
  }

  static updateAccount(data,piiUpdated) {
    const { id } = UserAPI.user();

    return axios({
      method: 'put',
      url: url(config.api.users, id+''),
      params: { piiUpdated: piiUpdated },
      data,
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static updatePii(data) {
    const { id, username } = UserAPI.user();
    // Yellow cab requires sending info as FormData, via multipart/form-data
    let formData = new FormData();

    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        formData.set(key, data[key]);
      }
    }

    formData.set('id', id);
    formData.set('email', username);

    return axios({
      method: 'post',
      url: config.api.pii,
      config: { headers: {'Content-Type': 'multipart/form-data' }},
      responseType: 'document',
      data: formData
    });
  }

  static forgotPassword(data) {
    return axios({
      method: 'post',
      url: config.api.forgotPassword,
      data,
      responseType: 'json'
    });
  }

  static resetPassword(data) {
    return axios({
      method: 'post',
      url: config.api.resetPassword,
      data,
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${UserAPI.token()}`
      }
    });
  }

  static changePassword(data, token) {
    return axios({
      method: 'post',
      params: { t: token },
      url: config.api.changePassword,
      data,
      responseType: 'json'
    });
  }

  static login(user, pass) {
    return axios({
      method: 'post',
      url: apiPath,
      responseType: 'json',
      data: {
        email: user,
        password: pass
      }
    }).then((response) => {
      const { access_token, refresh_token, ...user } = response.data;
      localStorage.setItem('lansce-proposal-user', JSON.stringify(user));
      localStorage.setItem('lansce-proposal-token', JSON.stringify(access_token));
    });
  }

  static logout(standard = true) {
    // Return logout success?
    history.push('/lansce/login');
    localStorage.setItem('lansce-proposal-referrer', '/lansce/login');
    if (isDebug) {
      log.debug("logout(): localStorage %o", localStorage);
      log.debug("logout(): history %o", history);
    }

    return new Promise((resolve, reject) => {
      if (isDebug) {
        log.debug("logout(): return Promise() localStorage before: %o", localStorage);
      }
      localStorage.removeItem('lansce-proposal-token');
      localStorage.removeItem('lansce-proposal-user');
      if(standard) {
        localStorage.removeItem('lansce-proposal-referrer');
        if (isDebug) {
          log.debug("logout(): return Promise() localStorage after: %o", localStorage);
        }
      }
      resolve();
    });
  }

  static hasRoles(roles) {
    const user = this.user();

    let userRoles = [];
    let result = false;

    if (user !== undefined && user !== null) {
      userRoles = roles.filter(role => user.roles.includes(role));
      result = userRoles.length === roles.length;
    }

    return result;
  }

  static hasAnyRole(roles) {
    const user = this.user();

    let userRoles = [];
    let result = false;

    if (user !== undefined && user !== null) {
      userRoles = roles.filter(role => user.roles.includes(role));
      result = userRoles.length > 0;
    }

    return result;
  }

  static verify() {
    if (isDebug) {
      log.debug("In verify()");
    }
    axios({
      method: 'post',
      url: config.api.verify,
      headers: {
        Authorization: `Bearer ${this.token()}`
      }
    }).then((response) => {
      const { access_token, refresh_token, ...user } = response.data;
      if (isDebug) {
        log.debug("verify(): response: %o",response);
      }
      localStorage.setItem('lansce-proposal-user', JSON.stringify(user));
    }).catch((e) => {
      console.log(e);
    }) 
  }

  static isAuthenticated() {
    return localStorage.getItem('lansce-proposal-token');
  }

  static user() {
    const user = localStorage.getItem('lansce-proposal-user');
    if (isDebug) {
      log.debug("user(): %o",user);
    }
    return user != null ? JSON.parse(user) : null;
  }

  static token() {
    const token = localStorage.getItem('lansce-proposal-token');
    if (isDebug) {
      log.debug("token(): %o",token);
    }
    return token !== null ? token.replace(/"/g, '') : null;
  }

  static referrer() {
    const referrer = localStorage.getItem('lansce-proposal-referrer');
    if (isDebug) {
      log.debug("referrer(): %o",referrer);
    }
    return referrer !== null ? referrer.replace(/"/g, '') : null;
  }
}

export default UserAPI;
