import React, { Component } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import * as Yup from 'yup';
import { WithWizard } from 'react-albus';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AlertContainer from '../utils/AlertContainer';
import Navigation from './Navigation';

import ProposalAPI from '../../api/ProposalAPI';
import ProposalCallAPI from '../../api/ProposalCallAPI';
import SchemaAPI from '../../api/SchemaAPI';
import InstrumentAPI from '../../api/InstrumentAPI';
import CommitteeAPI from '../../api/CommitteeAPI';

const ProposalInit = {
  title: '',
  briefDescription: '',
  instrument: { id: '' },
  committee: { id: '' },
  proprietary: ''
};

var ProposalSchemaCommittees = true;

const ProposalSchema = Yup.object().shape({
  title: Yup.string()
    .required('You must enter a title for your proposal.'),
  briefDescription: Yup.string()
    .required('You are required to enter a description.'),
  instrument: Yup.object().shape({
    id: Yup.string().required('You must choose an instrument.')
  }),
  committeeLength: Yup.number().default(0),
  committee: Yup.object().when('committeeLength', {
    is: (val) => val > 0,
    then: Yup.object({
      id: Yup.string().required('You must choose a committee.')
    })
  }),
  proprietary: Yup.string().required('You must indicate if your proposal is proprietary.')
});

class Create extends Component {
  constructor(props) {
    super(props);
    this.alertContainer = undefined; // using REF defined in the render function
    this.state = {
      debug: true,
      dirty: false,
      instruments: [],
      committees: [],
      callCommittees: [],
      hide: [],
    };

    /*
      Types of things!
      input
      textarea
      checklist
      radiolist
      select
      multiselect
    */

    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    const proposal = this.props.proposal;
    const proposalCallId = proposal.proposalCall.id;

    // Whoo, this is a weird one. We are trying to get the final list of instruments here.
    // So we grab the proposal, since it has the proposal call ID 
    Promise.all([
      ProposalCallAPI.get(proposalCallId),
      InstrumentAPI.get(),
      CommitteeAPI.get()
    ])
      .then(([propCalls, instrumentsResult, committeesResult]) => {
        const proposalCall = propCalls.data;
        const instruments = instrumentsResult.data;
        const committees = committeesResult.data;

        // Flatten the list of instrument id's
        const callInstruments = proposalCall && proposalCall.instruments ? proposalCall.instruments.map((i) => i.id) : [];

        // Filter the instruments by which are active in the proposal call instrument list
        const filteredInstruments = instruments.filter((instrument) => {
          return callInstruments.includes(instrument.id);
        })
        // And sort alphabetically
        .sort((a, b) => {
          if (a.abbreviation > b.abbreviation) {
            return 1;
          }
          if (a.abbreviation < b.abbreviation) {
            return -1;
          }
          return 0;
        });

        // Flatten the list of instrument id's
        const callCommittees = proposalCall && proposalCall.committees ? proposalCall.committees.map((i) => i.id) : [];
        const filteredCommittees = committees.filter((com) => {
          return callCommittees.includes(com.id);
        })
        // And sort alphabetically
        .sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });

        // Finally! we have the correct list of instruments! Huzza!
        this.setState({
          instruments: filteredInstruments,
          committees: filteredCommittees,
          callCommittees
        });
      })
      .catch(e => {
        this.alert('danger', e.message);
      });
  }

  submit(values, next) {
    const proposalId = this.props.proposal.id;
    //Send data to the server then goto Next
    const { forms, ...proposal } = values;
    const update = this.props.update;

    ProposalAPI.put(proposalId, values)
      .then(({ data }) => {
        update(data);
        next();
      })
      .catch(e => {
        this.alert('danger', e.message);
      });
  }

  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  isEqual(a, b) {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    if (aProps.length != bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];
      if (a[propName] !== b[propName]) {
          return false;
      }
    }

    return true;
  }

  render() {
    const { active, proposal } = this.props;
    const { callCommittees } = this.state;

    const committeeLength = callCommittees.length || 0;
    
    const formInit = {
      title: proposal.title || '',
      briefDescription: proposal.briefDescription || '',
      instrument: { 
        id: (proposal.instrument && proposal.instrument.id) ? proposal.instrument.id : ''
      },
      committee: {
        id: (proposal.committee && proposal.committee.id) ? proposal.committee.id : ''
      },
      proprietary: typeof proposal.proprietary !== undefined ? proposal.proprietary + '' : '',
      committeeLength
    };

    return (
      <WithWizard
        render={({ next, previous, step, steps }) => (
          <Formik
            initialValues={formInit}
            validationSchema={ProposalSchema}
            onSubmit={(values, actions) => {
              if(proposal.hasOwnProperty('id') && typeof proposal.id !== 'undefined'){
                this.submit(values, next);
              }
            }}
            render={({ errors, touched, values, isSubmitting }) => (
              <div>
                <div className="row">
                  <div className="col">
                    {
                      active ? (
                        <p className="text-danger font-weight-bold">(* indicates a required field)</p>
                      ) : null
                    }
                    <AlertContainer ref={(container) => { this.alertContainer = container; }} />
                  </div>
                </div>
                <Form>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="title">Proposal Title <span className="text-danger">*</span></label>
                        <Field
                          name="title"
                          placeholder="Enter a title for your proposal..."
                          disabled={!active || isSubmitting}
                          required
                          autoComplete="off"
                          className={`form-control${errors.title && touched.title ? ' is-invalid' : ''}`}
                        />
                        <ErrorMessage name="title">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>

                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="briefDescription">Brief Description <span className="text-danger">*</span></label>
                        <Field
                          name="briefDescription"
                          component="textarea"
                          placeholder="Enter a brief description for your proposal..."
                          disabled={!active || isSubmitting}
                          required
                          autoComplete="off"
                          rows="3"
                          className={`form-control${errors.briefDescription && touched.briefDescription ? ' is-invalid' : ''}`}
                        />
                        <ErrorMessage name="briefDescription">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>

                      <div className="form-group">
                        <label className="font-weight-bold" htmlFor="instrument.id">Instrument <span className="text-danger">*</span></label>
                        <Field
                          name="instrument.id"
                          component="select"
                          disabled={!active || (isSubmitting || this.state.instruments.length === 1)}
                          required
                          autoComplete="off"
                          className={`form-control${errors.instrument && touched.instrument && errors.instrument.id && touched.instrument.id ? ' is-invalid' : ''}`}
                        >
                          <option value="" label="" />
                          {
                            this.state.instruments.map((option, index) => (
                              <option key={`instrument-${index}`} value={option.id}>
                                {
                                  option.abbreviation === option.name ? option.name : `${option.abbreviation} - ${option.name}`
                                }
                              </option>
                            ))
                          }
                        </Field>
                        <ErrorMessage name="instrument.id">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                      </div>

                      {
                        committeeLength > 0 ? (
                          <div className="form-group">
                            <label className="font-weight-bold" htmlFor="committee.id">Committee <span className="text-danger">*</span></label>
                            <Field
                              name="committee.id"
                              component="select"
                              disabled={!active || (isSubmitting || committeeLength === 1)}
                              required
                              autoComplete="off"
                              className={`form-control${errors.committee && touched.committee && errors.committee.id && touched.committee.id ? ' is-invalid' : ''}`}
                            >
                              <option value="" label="" />
                              {
                                this.state.committees.map((option, index) => (
                                  <option key={`committee-${index}`} value={option.id}>
                                    { option.name }
                                  </option>
                                ))
                              }
                            </Field>
                            <ErrorMessage name="committee.id">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                          </div>
                        ) : null
                      }

                      <div className="form-group">
                        <div className="form-group">
                          <label className="font-weight-bold" htmlFor="proprietary">Is your proposal proprietary? <strong className="text-danger">*</strong></label>
                          <Field name="proprietary" component="select" required className={`form-control${errors.proprietary ? ' is-invalid' : ''}`}>
                            <option value=""></option>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                          </Field>
                          <ErrorMessage name="proprietary">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      {steps.indexOf(step) > 0 && (
                        <button className="btn btn-secondary btn-block" onClick={previous}>
                          Back
                        </button>
                      )}
                    </div>
                    <div className="col">
                      {
                        typeof proposal.id === 'undefined' ? (
                          <button className="btn btn-success btn-block" type="submit" disabled={isSubmitting}>
                            Save &amp; Continue
                          </button>
                        ) : (
                          ProposalSchema.isValidSync(values) && this.isEqual(proposal, values) ? (
                            <button className="btn btn-primary btn-block" type="button" onClick={next}>
                              Next
                            </button>
                          ) : (
                            <button className="btn btn-success btn-block" type="submit" disabled={isSubmitting}>
                              Save &amp; Continue
                            </button>
                          )
                        )
                      }
                    </div>
                  </div>
                </Form>
              </div>
            )}
          />
        )}
      />
    );
  }
}

Create.defaultProps = {
  active: true,
};

Create.propTypes = {
  proposal: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  active: PropTypes.bool
};

export default Create;
