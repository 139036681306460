import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SanitizedHtml from '../../utils/SanitizedHtml';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilesAPI from '../../../api/FilesAPI';

import Dropzone from 'react-dropzone';
import Loading from '../../utils/Loading.js';
import AuthLink from '../../utils/AuthLink';
import AlertContainer from '../../utils/AlertContainer';

//Note: this is just prefilter, the server will bounce files based on their types
const validFiles = [
  // { extension: '.doc', type: 'application/msword' },
  // { extension: '.dot', type: 'application/msword' },

  // { extension: '.docx', type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  // { extension: '.dotx', type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' },
  // { extension: '.docm', type: 'application/vnd.ms-word.document.macroEnabled.12' },
  // { extension: '.dotm', type: 'application/vnd.ms-word.template.macroEnabled.12' },

  // { extension: '.xls', type: 'application/vnd.ms-excel' },
  // { extension: '.xlt', type: 'application/vnd.ms-excel' },
  // { extension: '.xla', type: 'application/vnd.ms-excel' },

  // { extension: '.xlsx', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  // { extension: '.xltx', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template' },
  // { extension: '.xlsm', type: 'application/vnd.ms-excel.sheet.macroEnabled.12' },
  // { extension: '.xltm', type: 'application/vnd.ms-excel.template.macroEnabled.12' },
  // { extension: '.xlam', type: 'application/vnd.ms-excel.addin.macroEnabled.12' },
  // { extension: '.xlsb', type: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12' },

  // { extension: '.ppt', type: 'application/vnd.ms-powerpoint' },
  // { extension: '.pot', type: 'application/vnd.ms-powerpoint' },
  // { extension: '.pps', type: 'application/vnd.ms-powerpoint' },
  // { extension: '.ppa', type: 'application/vnd.ms-powerpoint' },

  // { extension: '.pptx', type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
  // { extension: '.potx', type: 'application/vnd.openxmlformats-officedocument.presentationml.template' },
  // { extension: '.ppsx', type: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' },
  // { extension: '.ppam', type: 'application/vnd.ms-powerpoint.addin.macroEnabled.12' },
  // { extension: '.pptm', type: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12' },
  // { extension: '.potm', type: 'application/vnd.ms-powerpoint.template.macroEnabled.12' },
  // { extension: '.ppsm', type: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12' },

  // { extension: '.mdb', type: 'application/vnd.ms-access' },
  { extension: '.pdf', type: 'application/pdf' },

  // { extension: '.odp', type: 'application/vnd.oasis.opendocument.presentation' },
  // { extension: '.ods', type: 'application/vnd.oasis.opendocument.spreadsheet' },
  // { extension: '.odt', type: 'application/vnd.oasis.opendocument.text' },

  // { extension: '.csv', type: 'text/csv' },
  // { extension: '.jpg', type: 'image/jpeg' },
  // { extension: '.png', type: 'image/png' }
];

const maxSize = 20 * 1024 * 1024; // 20mb

class FileMulipleElement extends Component {
  constructor(props) {
    super(props);

    this.alertContainer = undefined;

    this.state = {
      dropState: 'waiting',
      attachments: [],
      accepted: [],
      rejected: []
    }

    this.onDrop = this.onDrop.bind(this);
    this.upload = this.upload.bind(this);
    this.removeAcceptedFile = this.removeAcceptedFile.bind(this);
    this.removeUploadedFile = this.removeUploadedFile.bind(this);
    this.onDropAccepted = this.onDropAccepted.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    this.alert = this.alert.bind(this);
  }

  componentDidMount(){
    // Get list of files
    const { element } = this.props;

    FilesAPI.get(element.proposalId, element.formId)
      .then(result => {
        this.setState({
          attachments: result.data.results || [],
          accepted: [],
          rejected: [],
          dropState: 'waiting'
        });
      })
      .catch((error) => {
        this.setState({dropState: 'waiting'});
        console.log(error);
      });
  }

  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  upload(){
    const { element } = this.props;

    this.setState({dropState: 'uploading'}, () => {
      FilesAPI.post(element.proposalId, element.formId, this.state.accepted, (event)=>{
        console.log(event.lengthComputable, event.loaded / event.total);
      })
      .then(() => {
        return FilesAPI.get(element.proposalId, element.formId)
      })
      .then(result => {
        this.cleanFiles();
        this.setState({
          attachments: result.data.results || [],
          accepted: [],
          rejected: [],
          dropState: 'waiting'
        });
        // this.props.onChange();
      })
      .catch((error) => {
        this.setState({dropState: 'waiting'});
        this.alert('danger', error.message || error.status);
      });
    });
  }

  onDrop(accepted, rejected) {
    // Read file and get the page length, wont work on IE due to lack of support for readAsBinaryString
    accepted.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
          const fileAsBinaryString = reader.result;
          var count = fileAsBinaryString.match(/\/Type[\s]*\/Page[^s]/g).length;
          console.log('Number of Pages:', count);
          // Accept if the page count is <= 5
      };
      // reader.onabort = () => console.log('file reading was aborted');
      // reader.onerror = () => console.log('file reading has failed');

      reader.readAsBinaryString(file);
    });
    this.setState({ accepted: [...this.state.accepted, ...accepted], rejected });
  }

  onDropAccepted() {
    this.setState({dropState: 'preview-temp'});
  }

  onDropRejected(event) {
    this.setState({
      dropState: 'rejected',
      rejected: event
    });
  }

  removeAcceptedFile(file) {
    var updateAccepted = this.state.accepted.filter((f) => {
      return f.name !== file.name;
    });

    this.setState({
      accepted: updateAccepted,
      dropState: updateAccepted.length >= 1 ? this.state.dropState : 'waiting'
    });

    window.URL.revokeObjectURL(file.preview);
  }

  removeUploadedFile(file) {
    // Get list of files
    const { element } = this.props;

    FilesAPI.delete(element.proposalId, element.formId, file.name)
      .then(() => {
        return FilesAPI.get(element.proposalId, element.formId)
      })
      .then(result => {
        this.setState({
          attachments: result.data.results || [],
          accepted: [],
          rejected: [],
          dropState: 'waiting'
        });
      })
      .catch((error) => {
        this.setState({dropState: 'waiting'});
        console.log(error);
      });
  }

  componentWillUnmount() {
    this.cleanFiles();
  }

  cleanFiles() {
    //Clean up preview urls
    this.state.accepted.map((file) => {
      window.URL.revokeObjectURL(file.preview);
      return false;
    });
  }

  getFileIcon(filename){
    if (/\.do(cx?m?|tx?m?)$/.test(filename)) {
      return 'fa fa-file-word-o';
    }

    if (/\.xl(s|t|a)x?m?b?$/.test(filename)) {
      return 'fa fa-file-excel-o';
    }

    if (/\.pdf$/.test(filename)) {
      return 'fa fa-file-pdf-o';
    }

    if (/\.jpe?g|\.png$/.test(filename)) {
      return 'fa fa-file-image-o';
    }

    if (/\.p(p|o)(tx?m?|sx?m?|am?)$/.test(filename)) {
      return 'fa fa-file-powerpoint-o';
    }

    if (/\.zip$/.test(filename)) {
      return 'fa fa-file-archive-o';
    }

    return 'fa fa-file-o';
  }

  render() {
    const { active, element } = this.props;

    if (active) {
      return (
        <div className="mb-3">
          {
            this.state.attachments.length <= 0 ? (
              <div className={this.state.dropState !== 'rejected' ? 'card bg-light' : 'card border border-danger'}>
                <div className="card-body">
                  <Dropzone
                    accept={validFiles.map(file => file.type).join(',')}
                    maxSize={maxSize}
                    multiple={false}
                    style={{display: 'block'}}
                    // disableClick={this.state.dropState === 'preview' || this.state.dropState === 'preview-temp'}
                    onDrop={this.onDrop}
                    onDropAccepted={this.onDropAccepted}
                    onDropRejected={this.onDropRejected}>
                    <div>
                      { this.state.dropState === 'rejected' ? (<p className="text-center text-danger"><strong>File was rejected, check the file format!</strong></p>) : null}
                      <p className="text-center">Drop files here, or click to browse your files.</p>
                      {
                        element.help ? (
                          <p className="text-center mb-0">
                            <small><SanitizedHtml html={element.help} /></small>
                          </p>
                        ) : null
                      }
                    </div>
                  </Dropzone>

                  <AlertContainer ref={(container) => { this.alertContainer = container; }} />
      
                  <ul className="list-group">
                    {
                      this.state.accepted.map((f,index) => (
                        <li key={`${f.name}_${index}`} className="list-group-item">
                          <div  className="row align-items-center">
                            <div className="col-auto">
                              <button className="btn btn-danger btn-sm" onClick={event => this.removeAcceptedFile(f)}><FontAwesomeIcon icon="trash-alt"/> <span className="sr-only">Remove</span></button>
                            </div>
                            <div className="col">
                              <span className={this.getFileIcon(f.name) + ' fa-fw'}></span> {f.name} - <small><strong>{(f.size / 1024 / 1024).toFixed(2)}</strong> <em>Mb</em></small>
                            </div>
                          </div>
                        </li>
                      ))
                    }
                  </ul>
      
                  {
                    this.state.accepted.length > 0 ? (
                      <button className="btn btn-success btn-block mt-3" onClick={(event) => { this.upload(this.state.accepted) }}>
                        <FontAwesomeIcon icon="upload"/> Upload {this.state.accepted.length > 1 ? 'files' : 'file'}
                      </button>
                    ) : null
                  }
                </div>
              </div>
            ) : null
          }
  
          <ul className="list-group mt-3">
            {
              this.state.attachments.map((f,index) => (
                <li key={`${f._id}_${index}`} className="list-group-item">
                  <div  className="row align-items-center">
                    <div className="col-auto">
                      <button className="btn btn-danger btn-sm" disabled={this.state.dropState === 'uploading'} onClick={event => this.removeUploadedFile(f)}><FontAwesomeIcon icon="trash-alt"/> <span className="sr-only">Remove</span></button>
                    </div>
                    <div className="col">
                      <AuthLink url={f.url}>
                        <span className={this.getFileIcon(f.name) + ' fa-fw'}></span> {f.name} - <small><strong>{(f.size / 1024 / 1024).toFixed(2)}</strong> <em>Mb</em></small>
                      </AuthLink>
                    </div>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      );
    }

    return (
      <div className="mb-3">
        <ul className="list-group mt-3">
          {
            this.state.attachments.map((f,index) => (
              <li key={`${f._id}_${index}`} className="list-group-item">
                <div  className="row align-items-center">
                  <div className="col">
                    <AuthLink url={f.url}>
                      <span className={this.getFileIcon(f.name) + ' fa-fw'}></span> {f.name} - <small><strong>{(f.size / 1024 / 1024).toFixed(2)}</strong> <em>Mb</em></small>
                    </AuthLink>
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    )
  }
}

FileMulipleElement.defaultPrps = {
  active: true
}

FileMulipleElement.propTypes = {
  element: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  active: PropTypes.bool
};

export default FileMulipleElement;
