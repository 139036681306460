import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import config from '../../config';
import * as log from 'loglevel';

import Table from './Table';
import AlertContainer from '../utils/AlertContainer';

class SearchableList extends Component {
  constructor(props) {
    /*
      Requires props:
        addLabel
        search
          Reference to the API search provider
        properties
          [
            {
              title: string
              key: string,
              value: function,
              url: function
              type: key || date || string
            }
          ]
    */

    super(props);

    this.match = props.match;
    this.alertContainer = undefined; // using REF defined in the render function
    this.debug = config.debug;
    this.state = {
      list: [],
      dropdownOpen: false,
      active: true,
      q: '',
      sort: props.sort || props.properties[0].key,
      filterBy: undefined,
      order: props.order || 'asc',
      count: 0,
      offset: 0,
      max: 25,
    };

    this.toggle       = this.toggle.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.queryUpdate  = this.queryUpdate.bind(this);
    this.addFilter    = this.addFilter.bind(this);
  }

  componentDidMount() {
    if (this.debug) {
      log.debug("componentDidMount() localStorage: %o",localStorage);
      log.debug("componentDidMount() this: %o",this);
    }
    this.queryUpdate();
  }

  componentWillReceiveProps() {
    if (/tickets\/(edit|add)/.test(this.props.location.pathname)) {
      this.queryUpdate();
    }
  }

  // Returns a properly formatted search query
  getQuery() {
    let findBy = {}; // { active: this.state.active };

    if (this.props.filter && this.state.filterBy) {
      findBy[this.props.filter.by] = this.state.filterBy;
    }

    return Object.assign(
      {},
      this.state.q !== '' ? { q: this.state.q } : {},
      {
        findBy,
        sort: this.state.sort,
        order: this.state.order,
        offset: this.state.offset,
        max: this.state.max
      }
    );
  }

  toggleActive(event) {
    event.preventDefault();
    this.queryUpdate({ active: !this.state.active });
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  queryUpdate(queryChange = {}) {
    this.setState(queryChange, () => {
      this.props.search(this.getQuery())
        .then((results) => {
          this.setState({
            list: results.data.results ? results.data.results : results.data,
            count: results.data.count || 0
          });
        })
        .catch((error) => {
          this.alert('danger', error.message || 'There was an issue querying the server.');
        });
    });
  }

  addFilter(filterUpdate) {
    this.setState(filterUpdate, () => {
      this.queryUpdate();
    });
  }

  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  render() {
    const { properties, addLabel = undefined } =  this.props;

    return (
      <div className="container">
        <div className="row mb-3 mt-5">
          <div className="col">
            <div className="input-group">
              <DebounceInput
                type="text"
                className="form-control"
                placeholder="Search for..."
                aria-label="Search for..."
                minLength={0}
                debounceTimeout={500}
                onChange={event => this.queryUpdate({ q: event.target.value })}
              />
              <div className="input-group-append">
                <button className="btn btn-secondary input-group-btn" type="button"><FontAwesomeIcon icon="search"/></button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          {
            addLabel ? (
              <div className="col-auto mr-auto">
                <Link
                  href=""
                  to={{
                    pathname: `${this.match.path}/add`
                  }}
                  className="btn btn-success"
                >
                  <FontAwesomeIcon icon="plus"/> { addLabel }
                </Link>
              </div>
            ) : null
          }
          {/* <div className="col-auto">
            <button className="btn btn-secondary" onClick={this.toggleActive}>
              {
                this.state.active ? (
                  <span><span className="fa fa-eye" /> Show Deleted</span>
                ) : (
                  <span><span className="fa fa-eye-slash" /> Hide Deleted</span>
                )
              }
            </button>
          </div> */}
          {
            this.props.filter ? (
              <div className="col-auto">
                <Dropdown isOpen={this.state.filterOpen} toggle={() => { this.setState({ filterOpen: !this.state.filterOpen }); }}>
                  <DropdownToggle caret className="btn-block text-capitalize">
                    Filter By: {this.state.filterBy || 'Any'}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={ (event) => { this.addFilter({ filterBy: undefined }); }}>Any</DropdownItem>
                    {
                      this.props.filter.options.map((option) => (
                        <DropdownItem key={option} onClick={ (event) => { this.addFilter({ filterBy: option }) } } className="text-capitalize">{option}</DropdownItem>
                      ))
                    }
                  </DropdownMenu>
                </Dropdown>
              </div>
            ) : null
          }
        </div>
        <div className="row mb-5">
          <div className="col">
            <AlertContainer ref={(container) => { this.alertContainer = container; }} />
            <Table
              {...this.props}
              list={this.state.list}
              match={this.match}
              onSort={this.queryUpdate}
            />
            {
              this.state.count > 1 ? (
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  breakLabel={<span role="button" className="page-link text-muted" aria-label="Break">...</span>}
                  breakClassName="page-item"
                  pageCount={this.state.count}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(data) => {
                    this.queryUpdate({
                      offset: data.selected
                    });
                  }}
                  containerClassName="pagination justify-content-center"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  activeClassName="active"
                  disabledClassName="disabled"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                />
              ) : null
            }
          </div>
        </div>
      </div>
    );
  }
}

// SearchableList.defaultProps = {
//   addLabel: 'Add a New Item'
// };

SearchableList.propTypes = {
  addLabel: PropTypes.string,
  search: PropTypes.func.isRequired,
  properties: PropTypes.array.isRequired
};

export default SearchableList;


