import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Loading extends Component {
  render() {
    return this.props.watch ? this.props.children : (
      <div className="d-flex justify-content-center my-4">
        <div className="text-center text-muted">
          <p><FontAwesomeIcon icon="cog" spin size="5x" /></p>
          <p>Loading...</p>
        </div>
      </div>
    );
  }
}

Loading.propTypes = {
  watch: PropTypes.object.isRequired
};

export default Loading;
