import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Err extends Component {
  constructor(props) {
    super(props);
    this.match = this.props.match;
    this.message = props.location.message;
  }

  render() {
    return (
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-4 align-self-center">
            <h3 className="text-center">Error</h3>
            <div className="card">
              <div className="card-body">
                <p>{this.message}</p>
                <Link href to={{ pathname: '/login', state: { isOpen: true } }}>Back to the app</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Err;
