import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WithWizard } from 'react-albus';
import produce from 'immer';
import SanitizedHtml from '../utils/SanitizedHtml';

import AlertContainer from '../utils/AlertContainer';
import FormGroup from './FormGroup';
import FormElement from './FormElement';
import ValuesAPI from '../../api/ValuesAPI';

import Loading from '../utils/Loading';
import config from '../../config';

class Form extends Component {
  constructor(props) {
    super(props);
    this.alertContainer = undefined; // using REF defined in the render function
    this.state = {
      dirty: false,
      id: undefined,
      title: '',
      description: '',
      schema: [],
      data: {}
    };

    /*
      Types of things!
      input
      textarea
      checklist
      radiolist
      select
      multiselect
    */

    this.onFormChange = this.onFormChange.bind(this);
    this.isComplete   = this.isComplete.bind(this);
    this.submit       = this.submit.bind(this);
  }

  componentWillMount() {
    const proposal    = this.props.proposal;
    const proposalId  = proposal.id;
    const schema      = this.props.schema;
    const formId      = schema.id;

    if (typeof proposalId !== 'undefined' && typeof formId !== 'undefined') {
      ValuesAPI.get(proposalId, formId)
        .then((response) => {
          this.setState(
            produce(draft => {
              const typeaheads = this.searchSchema(schema.elements, 'type', 'typeahead');
              const typeIds = typeaheads.map((t) => t.id);

              // Convert array of objects to an object
              let data = response.data.reduce((obj, item) => {
                // Get the full schema and combine it with the response
                const element = this.getSchemaByProperty(schema, 'name', item.element.name);
                // This is a special case for typeaheads
                let merge = { ...obj };
                // is this a member of a typeahead object?
                if(element && typeIds.includes(element.parentId)) {
                  // get the parent
                  let parent = typeaheads.find(t => t.id === element.parentId);
                  // If does not already appeal in the obj
                  if(item.value === true){
                    if(!merge[parent.name]){
                      merge[parent.name] = {
                        id: null,
                        groupId: null,
                        value: [element],
                        element: parent
                      };
                    }else{
                      // If it does, append this item to the array
                      merge[parent.name].value = [...merge[parent.name].value, element];
                    }
                  }
                }
                return {...merge, [item.element.name]: { ...item, element } }
              }, {});

              // Set the data for this form
              draft.data = data;
            })
          )
        })
        .catch(e => {
          this.alert('danger', e.message);
        });
    }
  }

  // Search schema recursively
  searchSchema(schema, key, value) {
    var result = [];

    // For each parent element in the schema
    schema.forEach(function iter(element) {
      // Convert to [key]: value object
      if(element.hasOwnProperty(key) && element[key] === value) {
        result.push(element);
      }

      // If this has children, call iter to loop through and flatten those too
      if(element.hasOwnProperty('children') && Array.isArray(element.children)) {
        element.children.forEach(iter);
      }
    });

    return result;
  }

  onFormChange(event) {
    const schema  = this.props.schema;
    const name    = event.target.name;
    let value     = event.target.value;

    this.setState(
      produce(this.state, draft => {
        // If we don't already have data for this FormElement, create it.
        const elementSchema = this.getSchemaByProperty(schema, 'name', name);
        if (!draft.data[name]) {
          draft.data[name] = {
            // Combine the element with the full object schema
            element: elementSchema,
            value: null
          };
        }

        // There is a special case for radio based boolean nonsense

        // If this is FormElement is a checkbox, flip the value
        if (event.target.type === 'checkbox') {
          value = !draft.data[name].value;
        }

        if (event.target.type === 'typeahead') {
          const oldValue = draft.data[name].value;
          // set everything old to false first
          (oldValue || []).forEach((v) => {
            if(draft.data[v.name]) {
              draft.data[v.name].value = false;
            } else {
              draft.data[v.name] = {
                // Combine the element with the full object schema
                element: { ...v, parentId: elementSchema.id },
                value: false
              };
            }
          })
          // then set everything to false first
          value.forEach((v) => {
            if(draft.data[v.name]) {
              draft.data[v.name].value = true;
            } else {
              draft.data[v.name] = {
                // Combine the element with the full object schema
                element: { ...v, parentId: elementSchema.id },
                value: true
              };
            }
          })
        }

        // Grab the element schema, contined in element
        const { element } = draft.data[name];

        // If this element has children
        if (Array.isArray(element.children) && event.target.type !== 'typeahead') {
          // Loop through children if there are any
          element.children.forEach((child) => {
            // If the value was flipped to false or the value is not affirmative
            if (element.value === false || child.name !== value) {
              this.nullTree(child, draft);
            }
          });
        }

        // State is dirty now
        draft.dirty = true;
        // Finally set the value
        draft.data[name].value = value;
      }),
      this.isComplete
    );
  }

  // Traverse a child tree and null the values
  nullTree(element, draft) {
    if (draft.data[element.name]) {
      draft.data[element.name].value = null;
    }

    if(Array.isArray(element.children)) {
      element.children.forEach(function iter(child) {
        if (draft.data[child.name]) {
          draft.data[child.name].value = null;
        }
        return (Array.isArray(child.children) && child.children.forEach(iter));
      });
    }
  }

  // Has the user filled in all required fields
  isComplete() {
    const { elements = [] } = this.props.schema;
    const { data } = this.state;

    if (!data) {
      return false;
    }

    const valid = elements.map((element) => {
      if (!element.required || !element.visible) {
        return true;
      }

      const name = element.name || undefined;
      const type = element.type || undefined;

      if (type === 'checklist') {
        return element.options.map((option) => {
          return data[option.name];
        }).includes(true);
      }

      return !(typeof data[name] === 'undefined' || data[name] === '' || data[name] === null)
    });

    return !valid.includes(false);
  }

  // Find a FormElement schema by a property of the object
  getSchemaByProperty(schema, key, value) {
    var result;
    schema.elements.some(function iter(element) {
        if (element[key] === value) {
            result = element;
            return true;
        }
        return (Array.isArray(element.children) && element.children.some(iter));
    });
    return result;
  }

  submit(next = undefined) {
    const proposal      = this.props.proposal;
    const proposalId    = proposal.id;
    const proposalTitle = proposal.title;
    const schema        = this.props.schema;
    const formId        = schema.id;
    const groupId       = null;

    // Clean up data, include only the element id, value, and name
    const data = Object.keys(this.state.data).map((key) => {
      const element = this.state.data[key];
      return Object.assign({}, {
        element: {
          // This is the FormElement ID
          id: element.element.id,
          name: element.element.name,
          system: element.element.system,
          parentId: element.element.parentId || null
        },
        value: element.value
      },
      // Merge with the value ID if it exists
      element.id ? { id: element.id } : null);
    }).filter((d) => !Array.isArray(d.value));

    // Filter by newly created data, and existing data by ID
    const newData = data.filter((item) => item && !item.id);
    const existingData = data.filter((item) => item && item.id);

    let calls = [];
    
    if(data.length <= 0 && next){
      console.log(data);
      
      return next();
    }

    if (newData.length > 0) {
      calls.push(
        ValuesAPI.post(
          proposalId,
          formId,
          groupId,
          {
            id: proposalId,
            title: proposalTitle,
            formValues: newData
          }
        )
      );
    }

    if (existingData.length > 0) {
      calls.push(
        ValuesAPI.put(
          proposalId,
          formId,
          groupId,
          {
            id: proposalId,
            title: proposalTitle,
            formValues: existingData
          }
        )
      );
    }

    Promise.all(calls)
      .then((response) => {
        next && next();
      })
      .catch(e => {
        this.alert('danger', e.message);
      });
  }

  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  render() {
    const { schema, active = true } = this.props;

    return (
      <div>
        <h2>{schema.title}</h2>
        <AlertContainer ref={(container) => { this.alertContainer = container; }} />
        {
          active ? (
            <p className="text-danger font-weight-bold">(* indicates a required field)</p>
          ) : null
        }
        {
          schema.description ? (
            <div className="alert alert-info"><SanitizedHtml html={schema.description} /></div>
          ) : null
        }
        <Loading watch={this.state.data}>
          <FormGroup schema={schema} data={this.state.data} update={this.onFormChange} active={active} debug={config.debug} />
        </Loading>
        {
          active ? (
            <WithWizard
              render={({next, previous, push, step, steps }) => (
                <div className="row">
                  <div className="col">
                    {steps.indexOf(step) > 0 && (
                      <button className="btn btn-secondary btn-block" onClick={() => push(steps[steps.indexOf(step) - 1].id)}>
                        Back
                      </button>
                    )}
                  </div>

                  {/*
                    !schema.required && !this.state.dirty && (
                      <div className="col">
                        <button className="btn btn-primary btn-block" onClick={next}>
                          Next
                        </button>
                      </div>
                    )
                  */}

                  {
                    !this.state.dirty && this.isComplete() ? (
                      <div className="col">
                        <button className="btn btn-primary btn-block" onClick={() => push(steps[steps.indexOf(step) + 1].id)}>
                          Next
                        </button>
                      </div>
                    ) : null
                  }

                  {
                    this.state.dirty ? (
                      <div className="col">
                        <button className="btn btn-primary btn-block" onClick={(event) => { this.submit() }} disabled={!this.isComplete()}>
                          Save
                        </button>
                      </div>
                    ) : null
                  }

                  {
                    this.state.dirty ? (
                      <div className="col">
                        <button className="btn btn-success btn-block" onClick={(event) => { this.submit(() => push(steps[steps.indexOf(step) + 1].id)) }} disabled={!this.isComplete()}>
                          Save &amp; Continue
                        </button>
                      </div>
                    ) : null
                  }
                </div>
              )}
            />
          ) : null
        }
      </div>
    );
  }
}

Form.defaultProps = {
  active: true
};

Form.propTypes = {
  proposal: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  active: PropTypes.bool,
};

export default Form;
