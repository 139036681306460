import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../FormGroup';
import SanitizedHtml from '../../utils/SanitizedHtml';

class SelectElement extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      element,
      data,
      update,
      active = true
    } = this.props;
    const children  = element.children || [];

    return (
      <div className="form-group">
        <label className="font-weight-bold" htmlFor={element.id}><SanitizedHtml html={element.label} /> { element.required ? <span className="text-danger">*</span> : null}</label>
        {
          active ? (
            <select
              className="form-control"
              htmlFor={element.id}
              name={element.name}
              onChange={update}
              value={data[element.name] ? data[element.name].value : undefined}
            >
              <option value=""></option>
              {
                children.map((option, index) => (
                  <option key={`${element.id}-${index}`} value={option.name}>{option.label}</option>
                ))
              }
            </select>
          ) : (
            <p className="form-ontrol h-100">{data[element.name] ? data[element.name].value : 'N/A'}}</p>
          )
        }
        {
          element.help ? (
            <small id={`${element.id}-help`} className="form-text text-muted"><SanitizedHtml html={element.help} /></small>
          ) : null
        }
        {
          children.map((schema, index) => {
            const subChildren = schema.children || [];
            return subChildren.length > 0 && data[element.name] && data[element.name].value === schema.name ? (
              <div className="mt-4">
                <FormGroup {...{schema, data, update}} />
              </div>
            ) : null;
          })
        }
      </div>
    );
  }
}

SelectElement.defaultProps = {
  active: true
}

SelectElement.propTypes = {
  element: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  active: PropTypes.bool
};

export default SelectElement;
