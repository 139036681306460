import React, { Component } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

import SchemaAPI from '../../api/SchemaAPI';
import ProposalUserAPI from '../../api/ProposalUserAPI';

import history from '../../api/history';

import DatePicker from 'react-datepicker';
import Calendar from 'react-big-calendar';
import Modal from 'react-modal';
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import UserAPI from '../../api/UserAPI';
import ProposalAPI from '../../api/ProposalAPI';
import ScheduleAPI from '../../api/ScheduleAPI';
import ValuesAPI from '../../api/ValuesAPI';
import ProposalCallAPI from '../../api/ProposalCallAPI';
import produce from 'immer';
import EditCollaboratorEventModal from './EditCollaboratorEventModal';
import AlertContainer from '../utils/AlertContainer';
import config from '../../config';
import DateEventsAPI from '../../api/DateEventsAPI';

const localizer = Calendar.momentLocalizer(moment);

const DnDCalendar = withDragAndDrop(Calendar);

const CollaboratorSchema = Yup.object().shape({
	// accepted: Yup.string()
  //   .required('You must indicate if you are participating in this proposal.'),
  visit: Yup.string().required('You must indicate if you plan to visit the facility.')
    // .when("accepted", {
    //   is: 'yes',
    //   then: Yup.string().required('You must indicate if you plan to visit the facility.')
    // })
});

const utcOffset = -6;

class Collaborator extends React.Component {
  constructor(props) {
    super(props);
    this.alertContainer = undefined;
    this.mode = undefined;
    this.state = {
      loading: true,
      access: true,
      addEvent: false,
      proposalUser: undefined,
      editEvent: false,
      start: undefined,
      end: undefined,
      selectedDate: undefined,
      selectedEvent: undefined,
      visitForm: undefined,
      events: [],
      proposal: undefined,
      currentDate: new Date(),
    }

    this.onEventResize      = this.onEventResize.bind(this);
    this.onEventDrop        = this.onEventDrop.bind(this);
    this.onSelectSlot       = this.onSelectSlot.bind(this);
    this.onDoubleClickEvent = this.onDoubleClickEvent.bind(this);
    this.onSubmit           = this.onSubmit.bind(this);
    this.formatDates        = this.formatDates.bind(this);
    this.customPropGetter   = this.customPropGetter.bind(this);
    this.dateLabels         = this.dateLabels.bind(this);
  }

  componentWillMount() {
    const id = this.props.match.params.id;
    const user = UserAPI.user();

    if (typeof id !== 'undefined') {
      Promise.all([
        ProposalAPI.get(id),
        SchemaAPI.visitSchema(id),
      ])
        .then(([proposalResults, schemaResults]) => {
          const proposal = proposalResults.data;
          const proposalUser = proposal.collaborators.find((collaborator) => collaborator.user.id === user.id);        
          console.log("Proposal");
          console.log(proposal);
          console.log("Proposal User");
          console.log(proposalUser);
          const scheduleDates = this.formatDates(proposal.scheduledDates, proposal, proposal.instrumentId, 'scheduled');

          let currentDate = new Date();

          if(scheduleDates.length >= 1) {
            currentDate = moment(scheduleDates[0].start).isValid() ? scheduleDates[0].start : currentDate;
          }

          this.setState({
            loading: false,
            currentDate,
            proposalUser,
            proposal: {
              ...this.state.proposal,
              ...proposal,
              scheduleDates,
            },
            visitForm: schemaResults.data.form
          });

          return proposal;
        })
        .then((proposal) => {
          return Promise.all([ScheduleAPI.collaboratorEvents( id, user.id ), proposal]);
        })
        .then(([eventResults, proposal]) => {
          const events = this.formatDates(eventResults.data.results, proposal, proposal.instrumentId, null);
          
          this.setState({
            loading: false,
            events,
          });
        })
        .catch((e) => {
          if(e.status === 400) {
            this.setState({
              loading: false,
              access: false,
            });
          }
        });
    }
  }

  formatDates(events, proposal, instrumentId, type = null) {
    return events.map((event) => {
      const start = moment(event.start).toDate();
      const end = moment(event.end).toDate();

      return {
        proposal,
        instrumentId,
        ...event,
        start,
        end,
        canResize: true,
        canMove: true,
        visible: true,
        active: true,
      }
    });
  }

  addEvent(event, callback) {
    const user = UserAPI.user();
    const currentProposal = this.state.proposal;

    if(currentProposal) {
      // Set dates with utc offset
      const start = moment(event.start).startOf('day').utcOffset(config.utcOffset);
      const end   = moment(event.end).endOf('day').utcOffset(config.utcOffset);
      const formId = this.state.visitForm.id;

      DateEventsAPI.post({
          label: 'Visit',
          user: { id: user.id },
          proposal: { id: currentProposal.id },
          form: { id: formId },
          start,
          end,
          type: 'visit'
        })
        .then(({data: event}) => {
          const newEvent = this.formatDates([event], currentProposal, null, 'visit');
          this.setState({
            events: [...this.state.events, ...newEvent]
          }, () => {
            callback();
            this.alert('success', 'Your visit schedule was added.');
          });
        })
        .catch(e => {
          callback();
          this.alert('danger', e.message)
        });
    } else {
      this.alert('warning', 'Proposal information was not found.');
      callback();
    }
  }

  editEvent(event, callback) {
    const { events } = this.state;
    const index = events.findIndex(({ id }) => id === event.id);

    this.setState(
      produce(draft => {
        draft.events.splice(index, 1, event);
        this.updateEvent(event);
      }),
      callback
    );
  }

  removeEvent(event, callback) {
    const { events } = this.state;
    const index = events.findIndex(({ id }) => id === event.id);

    this.setState(
      produce(draft => {
        draft.events.splice(index, 1);
        this.updateEvent(event, true);
      }),
      callback
    );
  }

  updateEvent(event, remove = false) {
    const { alert } = this.props;

    if(!remove) {
      DateEventsAPI.put(event.id, event)
        .then((result) => {
          this.alert('success', 'The event was updated.');
        })
        // no need to do anything with the result
        .catch(e => this.alert('danger', e.message));
    } else {
      DateEventsAPI.delete(event.id)
        .then((result) => {
          this.alert('success', 'The event was removed.');
        })
        .catch(e => this.alert('danger', e.message));
    }
  }

  onSubmit(values) {
    const { proposalUser } = this.state;

    if (typeof proposalUser !== 'undefined') {
      ProposalUserAPI.updateCollaborator(proposalUser.id, values)
        .then((results) => {
          this.alert('success', 'Your visit status was updated.');
          this.setState({
            fields: results.data
          });
        })
        .catch((e) => {
          this.alert('danger', e.message);
        });
    }
  }

  onEventResize({ event, start, end, allDay }) {
    const { events } = this.state;
    const index = events.findIndex(({ id }) => {
      return id === event.id;
    });

    const newEvent = {
      ...event,
      start: moment(start).toDate(),
      end: moment(end).toDate()
    };

    this.setState(
      produce(draft => {
        draft.events.splice(index, 1, newEvent);
        this.updateEvent(newEvent);
      })
    );
  }

  onEventDrop({ event, start, end, allDay }) {
    const { events } = this.state;
    const index = events.findIndex(({ id }) => {
      return id === event.id;
    });

    const newEvent = {
      ...event,
      start: moment(start).toDate(),
      end: moment(end).toDate()
    };

    this.setState(
      produce(draft => {
        draft.events.splice(index, 1, newEvent);
        this.updateEvent(newEvent);
      })
    );
  };

  onSelectSlot({ start, action }) {
    this.setState({
      addEvent: true,
      selectedEvent: {
        label: 'Visit',
        start: start,
        end: start
      }
    });
  }

  onDoubleClickEvent(event, action) {
    this.setState({ 
      editEvent: true,
      selectedEvent: event
    });
  }

  customPropGetter(date) {
    const { proposal } = this.state;
    const d = moment(date);
    const index = proposal.scheduleDates.findIndex(({ start, end }) => d.isSameOrAfter(moment(start).startOf('day')) && d.isSameOrBefore(moment(end).endOf('day')));
    return index >= 0 ? { className: 'scheduled-day' } : {}
  }

  dateLabels(theme) {
    const { proposal: { scheduleDates }} = this.state;
    let seen = [];

    return scheduleDates.sort((a,b) => moment(a).isBefore(moment(b))).map(({ start, end }) => {
      const combined = `${moment(start).format('ll')} - ${moment(end).format('ll')}`;

      if(!seen.includes(combined)) {
        seen.push(combined);
  
        return <span 
            className={`badge badge-${theme} badge-pill mr-2`}
            onClick={() => this.setState({ currentDate: start })}
          >
            {combined}
          </span>;
      }

      return null;
    });
  }

  alert(type, message) {
    if (this.alertContainer) {
      this.alertContainer.triggerAlert(type, message);
    }
  }

  closeModal = () => {
    this.setState({ selectedEvent: undefined })
  }

  render() {
    const {
      loading,
      currentDate,
      access,
      forms,
      proposal,
      proposalUser,
      selectedEvent,
    } = this.state;

    return (
      <div className="container">
        {
          !loading && (
            <div className="row my-5">
              {
                proposalUser && proposal ? (
                  <div className="col">
                    <h1>{proposal.title}</h1>
                    <p>{proposal.briefDescription}</p>
                    <AlertContainer ref={(container) => { this.alertContainer = container; }} />
                    <Formik
                      initialValues={{ visit: `${proposalUser.visit}` }}
                      validationSchema={CollaboratorSchema}
                      // onSubmit={this.onSubmit}
                      render={({ values, actions, errors, touched, isSubmitting, handleChange }) => (
                        <React.Fragment>
                          <Form>
                            {/* <button type="submit" className="btn btn-success" disabled={isSubmitting}>Submit</button> */}
                            {/* <div className="form-group">
                              <label className="font-weight-bold" htmlFor="accepted">Are you participating in this proposal? <strong className="text-danger">*</strong></label>
                              <Field
                                name="accepted"
                                className={`col-sm-4 col-md-3 form-control${errors.accepted ? ' is-invalid' : ''}`}
                                component="select"
                                disabled={isSubmitting}
                                required
                              >
                                <option value=""></option>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                              </Field>
                              <ErrorMessage name="accepted">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                            </div> */}
                            <div className="form-group">
                              <label className="font-weight-bold" htmlFor="visit">Will you visit LANSCE for this proposal? <strong className="text-danger">*</strong></label>
                              <Field
                                name="visit"
                                className={`col-sm-4 col-md-3 form-control${errors.visit ? ' is-invalid' : ''}`}
                                component="select"
                                disabled={isSubmitting}
                                required
                                onChange={async e => {
                                  await handleChange(e);
                                  const newValues = { visit: e.target.options[e.target.selectedIndex].value };
                                  this.onSubmit(newValues);
                                }}
                              >
                                <option value=""></option>
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </Field>
                              <ErrorMessage name="visit">{msg => <p className="invalid-feedback">{msg}</p>}</ErrorMessage>
                            </div>
                          </Form>
                          {
                            values.visit === 'true' ? (
                              <div className="row mb-3">                            
                                {
                                  proposal.scheduleDates.length >= 0 ? (
                                    <div className="col">
                                      <p>If you are going to visit or be present during the experiment, please indicate the dates on the calendar below.</p>
                                      <div className="alert alert-success" role="alert">
                                        Scheduled dates are marked in green on the calendar: {this.dateLabels('success')}
                                      </div>
                                      <DnDCalendar
                                        selectable
                                        resizable
                                        titleAccessor="label"
                                        startAccessor="start"
                                        endAccessor="end"
                                        localizer={localizer}
                                        defaultDate={new Date()}
                                        defaultView={Calendar.Views.MONTH}
                                        events={this.state.events}
                                        onEventDrop={this.onEventDrop}
                                        onEventResize={this.onEventResize}
                                        onSelectSlot={this.onSelectSlot}
                                        onDoubleClickEvent={this.onDoubleClickEvent}
                                        slotPropGetter={this.customPropGetter}
                                        dayPropGetter={this.customPropGetter}
                                        onNavigate={(date) => { this.setState({ currentDate: date }) }}
                                        date={currentDate}
                                        style={{ height: "60vh", maxHeight: "768px" }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="col">
                                      <div className="alert alert-info text-center" role="alert">
                                        Your proposal is not scheduled, return here when your schedule has been defined to add visit dates.
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            ) : null
                          }
                        </React.Fragment>
                      )}
                    />
                  </div>
                ) : (
                  <div className="col">
                    <div className="alert alert-danger text-center" role="alert">
                      You aren't a collaborator on this proposal.
                    </div>
                  </div>
                )
              }
            </div>
          )
        }

        <EditCollaboratorEventModal 
          open={typeof selectedEvent !== 'undefined'}
          event={selectedEvent}
          onClose={this.closeModal}
          onDelete={(event) => { this.removeEvent(event, this.closeModal) }}
          onSubmit={(values) => {
            if(values.id){
              this.editEvent(values, this.closeModal);
            } else {
              this.addEvent(values, this.closeModal);
            }
          }}
        />
      </div>
    );
  }
}

export default Collaborator;
