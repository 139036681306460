import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import SanitizedHtml from '../../utils/SanitizedHtml';

class TypeaheadElement extends Component {
  constructor(props) {
    super(props);
  }

  onKeyDown = (e) => {
    const BACKSPACE = 8;

    if (e.keyCode === BACKSPACE &&
        e.target.value === "") {
      // IE/F Compatibility, don't trigger a "back" in the browser on backspace
      e.returnValue = false;
      e.preventDefault();
    }
    if (this.props.hasOwnProperty("onKeyDown")) {
      this.props.onKeyDown(e);
    }
  }

  render() {
    const {
      element,
      data,
      update,
      active = true
    } = this.props;

    let selected;

    // Just in case its coming back as a string
    if(data[element.name] && data[element.name].value){
      selected = Array.isArray(data[element.name].value) ? data[element.name].value : JSON.parse(data[element.name].value);
    }

    selected = selected ? selected : [];

    // let sortedChildren = unsortedChildren?.sort((a,b) => {
    //   // Sort by system created first
    //   if (a?.system && !b?.system) return -1;
    //   if (b?.system && !a?.system) return 1;
    //   // Then alphabetize
    //   if (a?.label < b?.label) return -1;
    //   if (a?.label > b?.label) return 1;
    //   return 0;
    // });


    return (
      <div className="form-group">
        <label className="font-weight-bold" htmlFor={element.id}><SanitizedHtml html={element.label} /> { element.required ? <span className="text-danger">*</span> : null}</label>
        {
          active ? (
            <Typeahead
              id={element.id}
              name={element.name}
              aria-describedby={element.help ? `${element.id}-help` : false}
              placeholder={element.placeholder || '...'}
              multiple
              // Prevent issue with backspace
              onKeyDown={this.onKeyDown}
              onChange={(values) => {
                const value = values.map((value) => {
                  if(!value.system) {
                    const { id, system, ...rest } = value;
                    // convert to LEMS syntax, system false if custom
                    return { system: !system, name: value.label, ...rest };
                  }

                  // Add system prop just in case?
                  return { system: true, ...value };
                });
                update({ target: { name: element.name, value, type: 'typeahead' } });
              }}
              selectHintOnEnter
              allowNew={(options, element) => {
                const { text, selected } = element;
                const exists = selected.some((item) => item.label.toLowerCase() === text.toLowerCase());
                return options.length === 0 && !exists;
              }}
              options={element.children}
              selected={selected}
            />
          ) : (
            <p className="form-control">
              {
                selected.length ? selected.map((item)=> (
                  <div className="rbt-token">{item.label}</div>
                )) : 'N/A'
              }
            </p>
          )
        }
        {
          element.help ? (
            <small id={`${element.id}-help`} className="form-text text-muted"><SanitizedHtml html={element.help} /></small>
          ) : null
        }
      </div>
    );
  }
}

TypeaheadElement.defaultProps = {
  active: true
}

TypeaheadElement.propTypes = {
  element: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  active: PropTypes.bool
};

export default TypeaheadElement;
