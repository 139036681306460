import React, { Fragment, useEffect, useState } from "react";

import StepWizard from "react-step-wizard";
import BeatLoader from "react-spinners/BeatLoader";
import { css }    from "@emotion/react";

// Configuration Imports
import config from '../../../config';
import * as log from 'loglevel';

// useHooks later
//import AlertContainer from '../utils/AlertContainer';

// Component Imports
import AddProposalWizardFirstPage from "./AddProposalWizardFirstPage";
import AddProposalWizardSecondPage from "./AddProposalWizardSecondPage";

// API Imports
import InstrumentAPI   from '../../../api/InstrumentAPI';
import ProposalAPI     from '../../../api/ProposalAPI';
import ProposalCallAPI from '../../../api/ProposalCallAPI';
import CommitteeAPI    from '../../../api/CommitteeAPI';

/** 
 * AddProposalWizard Component - to illustrate the possible functions
 * Could be used for nav buttons or overview
 */
const AddProposalWizard = (props) => {
  log.debug("Loading AddProposalWizard.jsx... [props]: %j", props);
  // if (props.currentStep !== 2) { // Prop: The current step
  //   return null
  // }

  let history = props.history;
  let MODES = {
    edit: 'edit',
    new: 'new',
    add: 'add'
  };
  
  const [debug,    setDebug]    = useState(config.debug);
  const [mode,     setMode]     = useState(undefined);
  const [loading,  setLoading]  = useState(true);
  const [override, setOverride] = useState([]);

  const [callCommittees,       setCallCommittees]       = useState([]);
  const [callInstruments,      setCallInstruments]      = useState([]);
  const [committeeLength,      setCommitteeLength]      = useState([]);
  const [selectedProposalCall, setSelectedProposalCall] = useState({});

  const [wizardState, setWizardState] = useState({
      form: {
        title: '',
        briefDescription: '',
        instrument: {
          id: ''
        },
        committee: {
          id: ''
        },
        proposalCall: {},
      },
      autoFilledForm: {
        title: '',
        briefDescription: '',
        instrument: {
          id: ''
        },
        committee: {
          id: ''
        },
        proposalCall: {},
      },
      selectedProposalCall: {},
      filteredInstruments: {},
      filteredCommittees: {},
      instruments: {},
      committees: {},
      proposalCalls: {},
      committeeLength: 0,
      demo: true, // uncomment to see more
  });

  // const updateForm = (key, value) => {
  //   const { form } = wizardState;

  //   form[key] = value;

  //   updateWizardState({
  //     ...wizardState,
  //     form,
  //   });
  // };
  // const updateProposalCalls = proposalCalls => updateWizardState({ ...wizardState, proposalCalls });
  // const updateCommittees    = committees    => updateWizardState({ ...wizardState, committees  });
  // const updateInstruments   = instruments   => updateWizardState({ ...wizardState, instruments });

  const setInstance = SW => updateWizardState({
    ...wizardState,
    SW,
  });

  const updateWizardState = (newData) => {
    log.debug("IN NEW UPDATER");
    log.debug(" NEW DATA NEW DATA NEW DATA NEW DATA NEW DATA NEW DATA NEW DATA");
    log.debug(newData);
    setWizardState({ ...wizardState, ...newData });
  };

  if (wizardState.selectedProposalCall && !_.isEmpty(wizardState.selectedProposalCall)) { // Prop: The current step
    log.debug("I HAVE EVERYTHING I NEED SO WHY AM I STILL HERE?!?!?!?!?!");
    log.debug("I HAVE EVERYTHING I NEED SO WHY AM I STILL HERE?!?!?!?!?!");
    log.debug("I HAVE EVERYTHING I NEED SO WHY AM I STILL HERE?!?!?!?!?!");
    log.debug("I HAVE EVERYTHING I NEED SO WHY AM I STILL HERE?!?!?!?!?!");
  }

  useEffect(() => {
    log.debug("AddProposalWizard.jsx: UPDATING WIZARD STATE:");
    log.debug("AddProposalWizard.jsx: UPDATING WIZARD STATE:");
    log.debug(wizardState);
    log.debug("AddProposalWizard.jsx: UPDATING WIZARD STATE: END:");
    log.debug("AddProposalWizard.jsx: UPDATING WIZARD STATE: END:");
    log.debug("AddProposalWizard.jsx: UPDATING WIZARD STATE PROPS:");
    log.debug("AddProposalWizard.jsx: UPDATING WIZARD STATE PROPS:");
    log.debug(props);
    log.debug("AddProposalWizard.jsx: UPDATING WIZARD STATE: PROPS END:");
    log.debug("AddProposalWizard.jsx: UPDATING WIZARD STATE: PROPS END:");
  }, [wizardState])

  /**
   * Load AddProposalWizard and request data from APIs
   */
   useEffect(() => {
    log.debug("AddProposalWizard.jsx: componentDidMount(): component mounted.");
    /**
      * @returns {Promise<Object,Object,Object>} Promise with some data.
      */
     const fetchData = async () => {

      setLoading(true);

      let proposalCallRequest = await ProposalCallAPI.search();
      let proposalCallResult  = await proposalCallRequest.data.results;
      log.debug(proposalCallResult);
      
      setLoading(false);

      log.debug("AddProposalWizard.jsx: useEffect(): Promise.all(): proposalCallResult: %o", proposalCallResult);

      updateWizardState({
          proposalCalls: proposalCallResult
      });
      log.debug(wizardState);
      log.debug("DONE Load");
     };

     fetchData().catch(e => {
      alert('danger', e.message);
      log.error("AddProposalWizard.jsx: useEffect(): Promise.all(): error: %j", e);
    });
    
  },[]);
  
  /**
   * Button handler that allows the user to select a Proposal Call to submit to.
   * @param {Object} call ProposalCall to process and send to AddProposalWizardPageTwo
   */
  const handleButtonCreateProposal = (call) => {
    log.debug("AddProposalWizard.jsx: handleButtonCreateProposal()");
    setForm(prevForm => ({
      ...prevForm,
      form: { ...prevForm.form, proposalCall: call}
    }), () => {
      log.debug("AddProposalWizard.jsx: handleButtonCreateProposal(): setForm(): %j", form);
    });
    
    
    setOverride(css`
      display: block;
      margin: 0 auto;
      border-color: red;
      color: red;
    `);
  }
  
  const alert = (type, message) => {
    log.debug("AddProposalWizard.jsx: alert(): %j %j", type, message);
  }
  
  const proposalCallSubmit = React.useCallback((values, actions) => {
    log.debug("AddProposalWizard.jsx: alert(): %j %j", values, actions);
    log.debug("Selected Proposal Call: %j", wizardState.selectedProposalCall);
    const data = { ...values, ...{ proposalCall: { id: wizardState.selectedProposalCall.id }}};
    ProposalAPI.post(data)
    .then((response) => {
      log.debug("AddProposalWizard.jsx: POST Success: props before history change:")
      log.debug(props);
      history.push(`/edit/${response.data.id}`);
      log.debug("AddProposalWizard.jsx: POST Success: props after history change:")
      log.debug(props);
    })
    .catch(e => {
      alert('danger', e.message);
    });
  });

  // // Do something on step change
  // const onStepChange = (stats) => {
  //   log.debug("AddProposalWizard.jsx: onStepChange: stats: %j", stats);
  // };

  const updateSelectedProposalCall = React.useCallback((selectedCall) => {
    log.debug("Updating 'selectedCall': %j", selectedCall);
    updateWizardState({
      selectedProposalCall: selectedCall ? selectedCall : {}
    });
    log.debug("Updating 'selectedProposalCall': %j", selectedProposalCall);
  });
  
  // This is where we process what proposal call the user wants to submit to!
  // const selectedProposalCallFunction = React.useCallback((selectedCall) => {
  //   const call = selectedCall;
  //   log.debug("AddProposalWizard.jsx: selectProposalCallFunction: Processing (call): %j", call);
  //   //updateForm("proposalCall",call);

  //   // const selectedCallInstruments = call && call.instruments ? call.instruments.map((i) => i.id) : []
  //   // const filteredCallInstruments = wizardState.instruments.filter((instrument) => {
  //   //   return selectedCallInstruments.includes(instrument.id)
  //   // }).sort((a, b) => {
  //   //   if (a.abbreviation > b.abbreviation) {
  //   //     return 1;
  //   //   }
  //   //   if (a.abbreviation < b.abbreviation) {
  //   //     return -1;
  //   //   }
  //   //   return 0;
  //   // });
    
  //   // const selectedCallCommittees = wizardState.form.proposalCall && wizardState.form.proposalCall.committees ? wizardState.form.proposalCall.committees.map((i) => i.id) : []
  //   // const filteredCallCommittees = wizardState.committees.filter((com) => {
  //   //   return selectedCallCommittees.includes(com.id)
  //   // }).sort((a, b) => {
  //   //   if (a.name > b.name) {
  //   //     return 1;
  //   //   }
  //   //   if (a.name < b.name) {
  //   //     return -1;
  //   //   }
  //   //   return 0;
  //   // });

  //   let singleInstrumentId = null
  //   let singleCommiteeId = null
    
  //   if(call.instruments && call.instruments.length === 1) {
  //     singleInstrumentId = call.instruments[0].id
  //   }
  //   if(call.committees && call.committees.length === 1) {
  //     singleCommiteeId = call.committees[0].id
  //   }

  //   updateWizardState(prevState => ({
  //     ...prevState,
  //     form: {
  //       proposalCall: call ? call : {}
  //     },
  //     filteredInstruments: call.instruments ? call.instruments : {},
  //     filteredCommittees: call.committees ? call.committees : {},
  //     committeeLength: (call && call.committees) ? call.committees.length : 0,
  //     autoFilledForm: {
  //       instrument: {
  //         id: singleInstrumentId ? singleInstrumentId : null
  //       },
  //       committee: {
  //         id: singleCommiteeId ? singleCommiteeId : null
  //       }
  //     }
  //   }));

  //   log.debug("DONE WITH AFTER BUTTON CLICK UPDATES");
  //   log.debug("You're a Wizard State now Harray!");
  // },[]);

  if(loading) return (<center><BeatLoader color={"blue"} css={override} loading={loading} size={20} /></center>);
  if (wizardState.instruments && wizardState.committees && wizardState.proposalCalls) {
    return (
      <div className="container">
        <div className="row my-5">
          <div className="col">
            <StepWizard
              isHashEnabled={false}
              //transitions={wizardState.transitions} // comment out for default transitions
              //nav={<Nav />}
              instance={setInstance}
            >
              <AddProposalWizardFirstPage
                wizardState={wizardState}
                stepName={"call"}
                updateSelectedProposalCall={updateSelectedProposalCall}
              />
              <AddProposalWizardSecondPage
                wizardState={wizardState}
                stepName={"details"}
                submit={proposalCallSubmit}
              />
            </StepWizard>
          </div>
        </div>
      </div>
    );
  }
  return (<p>No data yet</p>);
}

export default AddProposalWizard;
